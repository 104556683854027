import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiConfig from "../../../api/ApiConfig";
import {
  postMultipartWithAuthCall,
  simplePostCall,
} from "../../../api/ApiServices";
import SpinnerCmp from "../../../components/SpinnerCmp";
import Params from "../../../config/Params";
import { AppContext } from "../../../context/AppContext";
import AssessmentStepOne from "./MultiStep/AssessmentStepOne";
import AssessmentStepTwo from "./MultiStep/AssessmentStepTwo";
import { useTranslation } from "react-i18next";

export default function CreateAssessment(props) {
  const { state, setState } = useContext(AppContext);
  const { t } = useTranslation();

  const { id } = useParams();
  const [assessmentDetailsState, setAssessmentDetailsState] = useState({
    isloading: false,
    assessment_id: "",
    assessment_title: "",
    assessment_description: "",
    curriculum: "",
    selectedCurriculum: [],
    class_division: [],
    selectedGrade: [],
    subject: "",
    selectedSubject: [],
    lessons: [],
    selectedLessons: [],
    topics: [],
    selectedTopics: [],
    assessment_type: "",
    selectedAssessmentType: [],
    assessment_duration: "",
    selectedAssessmentDuration: [],
    assessment_improvement: "",
    selectedAssessmentImprovement: [],
    negative_score: "no",
    assessment_start_date: "",
    assessment_start_time: "",
    assessment_end_date: "",
    assessment_end_time: "",
  });

  // console.log('assessmentDetailsState', assessmentDetailsState);
  useEffect(() => {
    getAssessmentsDetails();
  }, []);

  function handleNext() {
    if (state !== 2) {
      setState(state + 1);
    } else {
      return;
    }
  }
  function handlePrevious() {
    if (state !== 0) {
      setState(state - 1);
    } else {
      return;
    }
  }

  // function saveAssessmentInfo(stateData) {
  //   setAssessmentDetailsState({
  //     ...assessmentDetailsState,
  //     isloading: false,
  //     assessment_id: 206,
  //   })
  // }
  function convertTimeToMinutes(timeFormat) {
    const [hours, minutes, seconds] = timeFormat.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return totalMinutes;
  }
  function saveAssessmentInfo(stateData) {
    // console.log('statedata', stateData);
    setAssessmentDetailsState({
      ...assessmentDetailsState,
      isloading: true,
    });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: JSON.stringify(Params.teacher_id),
      title: stateData.assessment_title,
      description: stateData.assessment_description,
      class_division_id:
        stateData.class_division || stateData.class_division_id,
      curriculum: stateData.curriculum,
      subject: stateData.subject,
      type: stateData.assessment_type,
      duration: JSON.stringify(stateData.assessment_duration),
      improvement: stateData.assessment_improvement,
      start_date: stateData.assessment_start_date,
      start_time: stateData.assessment_start_time,
      end_date: stateData.assessment_end_date,
      end_time: stateData.assessment_end_time,
      negative_mark: stateData.negative_score,
      lesson: stateData.lessons,
      topic: stateData.topics,
    });
    console.log(
      "new create request body ",
      newRequestBody,
      "stateData",
      stateData
    );
    simplePostCall(ApiConfig.ASSESSMENT_CREATE_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          // console.log('idddd', data);
          setAssessmentDetailsState({
            ...assessmentDetailsState,
            isloading: false,
            assessment_id: data.assesment_id,
          });
          toast.success(data.message);
          handleNext();
        } else {
          setAssessmentDetailsState({
            ...assessmentDetailsState,
            isloading: false,
          });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setAssessmentDetailsState({
          ...assessmentDetailsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }
  function publishAssessmentInfo(stateData) {
    console.log("statedata", stateData);
    setAssessmentDetailsState({
      ...assessmentDetailsState,
      isloading: true,
    });

    let newRequestBody = JSON.stringify({
      customer_id: JSON.stringify(Params.customer_id),
      api_key: Params.api_key,
      teacher_id: JSON.stringify(Params.teacher_id),
      assessment_id: id,
      title: stateData.assessment_title,
      curriculum: stateData.curriculum
        ? stateData.curriculum_id
        : stateData.curriculum_id,
      description: stateData.assessment_description,
      class_division_id: stateData.class_division_id,
      subject: stateData.subject_id,
      type:
        stateData.selectedAssessmentType?.value || stateData.assessment_type,
      // stateData.assessment_type === "Yes" ||
      // stateData.assessment_type === "No"
      //   ? stateData.selectedAssessmentType?.label
      //   : stateData.assessment_type,
      improvement: stateData.assessment_improvement,
      start_date: stateData.assessment_start_date,
      start_time: stateData.assessment_start_time,
      end_date: stateData.assessment_end_date,
      end_time: stateData.assessment_end_time,
      negative_mark:
        stateData.negative_score || stateData.assessment_negative_marks,
      lesson: stateData.lessons || stateData?.lesson.map((item) => item.value),
      topic: stateData.topics || stateData.topic.map((item) => item.value),
      duration: JSON.stringify(stateData.assessment_duration),
    });
    console.log("new request body ", newRequestBody);
    simplePostCall(ApiConfig.ASSESSMENT_UPDATE_API, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          setAssessmentDetailsState({
            ...assessmentDetailsState,
            isloading: false,
            assessment_id: data.assessment_id,
          });
          toast.success(data.message);
          handleNext();
        } else {
          // console.log('false');
          setAssessmentDetailsState({
            ...assessmentDetailsState,
            isloading: false,
          });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setAssessmentDetailsState({
          ...assessmentDetailsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }

  function getAssessmentsDetails() {
    // setAssignmentDetailsState.isloading (true)
    simplePostCall(
      ApiConfig.ASSESSMENT_DETAILS_API,
      JSON.stringify({ ...Params, assessment_id: id })
    )
      .then((data) => {
        let user_profile = data.assessment_details;
        // console.log('userProfilessss', user_profile);
        setAssessmentDetailsState({
          curriculum: user_profile.curriculum,
          assessment_title: user_profile.assessment_title,
          assessment_description: user_profile.assessment_description,
          assessment_grade: user_profile.assessment_grade,
          subject: user_profile.assessment_subject,
          assessment_type: user_profile.assessment_type,
          assessment_duration: convertTimeToMinutes(
            user_profile.assessment_duration
          ),
          assessment_improvement: user_profile.assessment_improvement,
          assessment_start_date: user_profile.assessment_start_date,
          assessment_start_time: user_profile.assessment_start_time,
          assessment_end_date: user_profile.assessment_end_date,
          assessment_end_time: user_profile.assessment_end_time,
          assessment_negative_marks: user_profile.assessment_negative_marks,
          class_division_id: user_profile.class_division_id,
          curriculum_id: user_profile.curriculum_id,
          lesson: user_profile.lesson,
        });
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  return (
    <>
      {assessmentDetailsState.isloading ? (
        <SpinnerCmp loading={assessmentDetailsState.isloading} />
      ) : (
        <>
          <div className="row assignment-sec">
            <div className="col-sm-12">
              {/*<MultiStep activeStep={0} showNavigation={true} steps={this.state.steps} />*/}

              <div class="justify-content-center">
                <div class="">
                  <div class="card progress_bar_card px-0 pt-4 pb-0 mt-3 mb-3">
                    <div id="msform">
                      <ul id="progressbar">
                        <li
                          class={"active"}
                          id="account"
                          style={{ width: "50%" }}
                        >
                          <strong>
                            {t(
                              assessmentDetailsState.assessment_title
                                ? "Update Assessment"
                                : "Create Assessment"
                            )}
                          </strong>
                        </li>
                        <li
                          class={state > 0 ? "active" : ""}
                          id="personal"
                          style={{ width: "50%" }}
                        >
                          <strong>{t("Add Questions")}</strong>
                        </li>
                      </ul>
                      <div class="progress">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: 50 * (state + 1) + "%" }}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {state === 0 ? (
                <AssessmentStepOne
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  saveAssessmentInfo={saveAssessmentInfo}
                  publishAssessmentInfo={publishAssessmentInfo}
                  assessmentDetails={assessmentDetailsState}
                  id={id}
                />
              ) : state === 1 ? (
                <AssessmentStepTwo
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  assessmentDetails={assessmentDetailsState}
                  state={state}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}

{
  /* sudfunskfvdv
 const handleFile = (e) => {
    setFiles([
      ...files,
      e.target.files[0],
      e.target.files[1],
      e.target.files[2],
    ]);
    if (e.target.files[1] == null) {
      setFiles([...files, e.target.files[0]]);
    }
    if (e.target.files[1] && e.target.files[2] == null) {
      setFiles([...files, e.target.files[0], e.target.files[1]]);
    }
  };
  const handleDelete = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
   <label className="contentHeader">
                  Attachments only (4 Files are allowed)
                </label>
                <div className="col-md-12" onChange={handleFile}>
                  <input
                    class="form-control"
                    type="file"
                    multiple
                    id="formFileDisabled"
                  />
                  {files.map((file, index) => (
                    <div key={index}>
                      <p> {file.name} </p>
                      <button onChange={handleDelete(index)}> Delete </button>
                    </div>
                  ))}
                </div> */
}
