import React, { useEffect, useState } from "react";
import Select from "react-select";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Promotion = () => {
  const [selectedClass, setSelectedClass] = useState([]);
  const [ProposedClass, setProposedClass] = useState([]);
  const [classSelect, setClassSelect] = useState("");
  const [classProposed, setClassProposed] = useState("");
  const [classess, setClassess] = useState("");
  const [proposed, setProposed] = useState("");
  const [classid, setClassId] = useState("");
  const [proposedId, setProposedId] = useState("");
  const [studen, setStuden] = useState([]);
  const [comm, setComm] = useState("true");
  const [selectedStud, setSelectedStud] = useState([]);
  const [mode, setMode] = useState("promotion");
  useEffect(() => {
    getGradeList();
  }, []);
  useEffect(() => {
    getStudents();
  }, [classess]);
  function ProposalClass(selectedClass) {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      division_id: selectedClass,
    });
    console.log(newRequestBody, "newReques");
    simplePostCall(ApiConfig.PROMOTION_PROPOSAL_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name,
                value: grade.division_id,
                class_id: grade.class_id,
              });
            });
          }
          setProposedClass({ ...ProposedClass, grades: grades });
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  function getGradeList() {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.PROMOTION_CLASS_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name,
                value: grade.division_id,
                class_id: grade.class_id,
              });
            });
          }
          setSelectedClass({ ...selectedClass, grades: grades });
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const onGradeChange = (e) => {
    console.log(e, "elements");
    setClassSelect(e.label);
    setClassess(e.value);
    setClassId(e.class_id);
    ProposalClass(e.value);
  };
  const onPropsedChange = (e) => {
    console.log(e, "elements");
    setClassProposed(e.label);
    setProposedId(e.class_id);
    setProposed(e.value);
  };
  function getStudents() {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      class_id: classid,
      division_id: classess,
    });

    simplePostCall(ApiConfig.PROMOTION_STUDENT_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          var students = [];
          if (data.list?.length > 0) {
            data.list.map((student, index) => {
              console.log(student, "studentssss");
              students.push({
                student_profilepicture: student.student_profilepicture,
                student_mifare_card_id: student.student_mifare_card_id,
                student_admission_num: student.student_admission_num,
                label: student.student_name,
                value: student.student_id,
              });
            });
          }
          // studen([]);
          setStuden({ ...studen, students: students });
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const OnStudentChange = (e) => {
    setSelectedStud((prev) => [...prev, e]);
  };
  console.log(selectedStud, "selectedStudedddd");
  const commChange = (e, label) => {
    console.log(e.target.value, label, "e.target.value");
    setMode(label);
    setComm(e.target.value);
  };

  function PromotionSubmit() {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      class_id: proposedId,
      student_id: selectedStud,
      division_id: proposed,
      promote: mode == "promotion" ? true : false,
      passout: mode == "passout" ? true : false,
      tripchange: "no",
    });

    simplePostCall(ApiConfig.PROMOTION_SUBMIT, newRequestBody)
      .then((data) => {
        if (data.result) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  //----------------------------Translation --------------------------//
  const { t } = useTranslation();
  return (
    <div className="StudentList">
      {/* {loading ? (
      <StudentDetskeleto />
    ) : ( */}
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="labelCreate">{t("Promotion")}</label>

              <div className="">
                <div className="">
                  <button type="submit" class=" CreateBB">
                  {t("Read instructions carefully before proceed")}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label>{t("Existing Grade")}</label>
              <Select
                class="js-example-basic-single form-select"
                options={selectedClass.grades}
                onChange={(e) => onGradeChange(e)}
                placeholder={classSelect ? classSelect : "Select Class"}
              />
              {/* <select class="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> */}
            </div>
            <div className="radios">
              <input
                type="radio"
                className="pro"
                value="true"
                id="specifyColor"
                checked={comm === "true"}
                onChange={(e) => commChange(e, "promotion")}
              />
              {t("Promotion")}
              <input
                type="radio"
                className="pas"
                id="specifyColor"
                value="false"
                checked={comm === "false"}
                onChange={(e) => commChange(e, "passout")}
              />
              {t("Passout")}
            </div>
            <div className="searchButton">
              <button className="searchStud">{t("Search Students")}</button>
            </div>
            {mode == "passout" ? (
              <div />
            ) : (
              <div className="promotionClass">
                <label>{t("Proposed Class")}</label>
                <Select
                  class="js-example-basic-single form-select"
                  options={ProposedClass.grades}
                  onChange={(e) => onPropsedChange(e)}
                  placeholder={classProposed ? classProposed : "Select Class"}
                />
                {/* <label>Proposed Class</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected></option>
                  <option value="1">6th A</option>
                  <option value="2">12th A</option>
                  <option value="3">GRADE 12 A</option>
                </select> */}
              </div>
            )}
          </div>
          <div className="tableCreate">
            <div class="table-responsive">
              <table class="table table-dark table-borderless newTable ">
                <thead className="tableHeadd">
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th>{t("Image")}</th>
                  <th>{t("Student Name")}</th>
                  <th>{t("Admission Number")}</th>
                  <th>{t("Mifare Card ID")}</th>
                </thead>

                {studen?.students?.length > 0 ? (
                  <tbody>
                    {studen.students.map((el, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <input
                              type="checkbox"
                              onClick={() => OnStudentChange(el.value)}
                            />
                          </div>
                        </td>
                        <td>
                          <img
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "100px",
                              marginRight: "10px",
                            }}
                            src={ApiConfig.NODE_URL + el.student_profilepicture}
                            onError={(e) => {
                              e.target.src = dashboardAvatar;
                            }}
                          />
                        </td>
                        <td>{el.label}</td>
                        <td>{el.student_admission_num}</td>
                        <td>{el.student_mifare_card_id}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div>{t("No students available")}</div>
                )}
              </table>
            </div>
          </div>
          <div className="promotestu">
            <button
              type="submit"
              className="promButt"
              onClick={() => PromotionSubmit()}
            >
              {t("Promote Student")}
            </button>
          </div>
          <div class=" alertstyle" role="alert">
            <div className="tableCreatee">
              <div class="table-responsive">
                <h5 className="labelCreate">{t("Promotion Instruction")}</h5>
                <table class="table table-dark table-borderless newTable ">
                  <tr>
                    <th>1</th>
                    <td>
                    {t("Promotion features only applicable during an academic year end or a new academic year starting time")}
                    </td>
                  </tr>
                  <tr>
                    <th>2</th>
                    <td>
                    {t("Consider classes in descending order based on grade priority Qualification")}
                    </td>
                  </tr>
                  <tr>
                    <th>3</th>
                    <td>
                    {t("Took the highest grade first whose student leaving school due to qualifying the highest available grade in school Select pass out option for these student Then select qualified student or unselect not qualified students then press move to alumini This action will move entire selected students to school alumini and those are inactive from student section")}
                    </td>
                  </tr>
                  <tr>
                    <th>4</th>
                    <td>
                    {t("Then select next successive grade and select promotion option and select proposed grade for those students You can select qualified students for proposed class Then press promote student button This action will move selected students to newly selected class")}
                    </td>
                  </tr>
                  <tr>
                    <th>5</th>
                    <td>
                    {t("Continue 4th step with next successive classes until the last one")}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
