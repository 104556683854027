import React, { useEffect, useState } from "react";
import "../../assets/css/studentlist.css";

import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
function ViewGrades() {
  const [grade, setGrade] = useState("");
  const [showData, setShowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState("");
  const [showAfterDelete, setShowAfterDelete] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showId, setShowId] = useState("");
  const { t } = useTranslation();
  const clearFields = () => {
    setGrade("");
  };

  const handlePageChange = () => {
    if (nextPage != false) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (nextPage !== false) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    gradelist();
  }, [currentPage, grade, showAfterDelete]);
  const gradelist = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade: grade,
      limit: 20,
      page: Number(currentPage),
    });
    simplePostCall(ApiConfig.CLASSLISTCREATE, requestBody)
      .then((data) => {
        setShowData(data?.data);
        setNextPage(data?.next_page);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const deleteid = (id) => {
    setShowId(id);
  };
  const deletedata = (id) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      class_id: id,
    });
    simplePostCall(ApiConfig.CLASS_DELETE, requestBody)
      .then((data) => {
        setShowAfterDelete(data);
        console.log(data, "deletesuccess");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const studentexcel = (studown) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      format: studown,
    });
    simplePostCall(ApiConfig.CLASS_EXPORT, requestBody)
      .then((data) => {
        if (data) {
          FileSaver.saveAs(data?.file_path);
        } else {
          console.log("not working");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">{t("Grades")}</h5>
            <div className="tablebuttonview">
              <a
                href="/PriorityGrades"
                type="button"
                className="btn timetablebutton "
              >
                <span>{t("Prioritise Grade")}</span>
              </a>
              <a
                href="/CreateGrade"
                type="button"
                className="btn timetablebutton "
              >
                <span>{t("New Grade")}</span>
              </a>
              <a
                href="/ImportSubjects"
                type="button"
                className="btn timetablebutton"
              >
                <span>{t("Import")}</span>
              </a>
              <a
                className="btn timetablebutton dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              {t("Export")}  
              </a>

              <span class="visually-hidden">{t("Toggle Dropdown")}</span>

              <ul class="dropdown-menu">
                <li
                  onClick={() => {
                    studentexcel("pdf");
                  }}
                >
                  <a class="dropdown-item  datasize" href="#">
                  {t("Save as PDF")}
                  </a>
                </li>
                <li
                  onClick={() => {
                    studentexcel("excel");
                  }}
                >
                  <a class="dropdown-item datasize" href="#">
                  {t("Save as PNG")} 
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("Grade Name")}</th>
                    <th scope="col">{t("School Hour")}</th>
                    <th scope="col">{t("Number Of Divisions")}</th>
                    <th scope="col">{t("Number Of Students")}</th>
                    <th scope="col">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={grade}
                        onChange={(e) => setGrade(e.target.value)}
                      ></input>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                        {t("Clear")}  
                        </button>
                      </div>
                    </th>
                  </tr>
                  {showData.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          {index + 1 + 20 * (currentPage - 1)}
                        </th>
                        <td>{item.class_name}</td>
                        <td>{item.shift_name}</td>
                        <td>{item.number_of_divisions}</td>
                        <td>{item.number_of_students}</td>
                        <td style={{ alignItems: "center" }}>
                          <div className="buttons">
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                 {t("Edit")} 
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/UpdateGrade?message=${encodeURIComponent(
                                  item.class_id
                                )}`}
                              >
                                <div className="view">
                                  <AiTwotoneEdit className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                 {t("Delete")} 
                                </Tooltip>
                              }
                            >
                              <Link to="">
                                <div
                                  className="view"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    deleteid(item.class_id);
                                  }}
                                >
                                  <RiDeleteBin6Fill className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="showingnumber">
                <div></div>
                <nav aria-label="Pagination">
                  <ul className="pagination">
                    <li
                      className={`page - item${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        &laquo;
                      </button>
                    </li>
                    <button
                      className="page-link"
                      style={{ backgroundColor: "#adc927" }}
                      onClick={() => handlePageChange()}
                    >
                      {currentPage}
                    </button>

                    <li
                      className={`page-item ${
                        currentPage === currentPage - 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handleNextPage}
                        disabled={currentPage === currentPage - 1}
                        aria-label="next"
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal Start */}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        dialogClassName=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
          {t("Delete Confirmation")}  
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
          {t("Are you sure you want to delete ?"
)} 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="dlt-btn-1"
            >
            {t("Cancel")}  
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(false);
                deletedata(showId);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
             {t("Delete")} 
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal End */}
    </>
  );
}

export default ViewGrades;
