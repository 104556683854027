import React, { Component } from "react";
// import { Modal } from 'react-bootstrap'

import LeavesModal from "./LeavesModal";
import user from "../../assets/images/gif/user.gif";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { toast } from "react-toastify";
import book_1 from "../../assets/images/book_1.svg";
import SpinnerCmp from "../../components/SpinnerCmp";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import filter from "../../assets/images/filter.svg";
import { Table } from "react-bootstrap";
import TablesSkeleton from "../../components/skeletons/TablesSkeleton";
import { TabPanel, TabView } from "primereact/tabview";
export class ApproveLeaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      isloading: true,
      isTodoModelShow: false,
      leaveList: [],
      leave_data: [],
    };
    console.log(this.state.status, "working s");
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | Approve Leaves";
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      ...this.state,
      isTodoModelShow: false,
    });
  };
  openColseModal = (leave_data) => {
    // this.setInitialState();
    this.setState({
      ...this.state,
      isTodoModelShow: !this.state.isTodoModelShow,
      leave_data: leave_data,
    });
  };
  //#endregion
  dataList = [
    {
      id: 1,
      name: "Rinku",
      UserPic: book_1,
      days: "1 day",
    },
    {
      id: 2,
      name: "Rinku",
      UserPic: book_1,
      days: "2 days",
    },
    {
      id: 3,
      name: "Rinku",
      UserPic: book_1,
      days: "1 day",
    },
    {
      id: 4,
      name: "Rinku",
      UserPic: book_1,
      days: "1 day",
    },
    {
      id: 5,
      name: "Rinku",
      UserPic: book_1,
      days: "2 days",
    },
    {
      id: 6,
      name: "Rinku",
      UserPic: book_1,
      days: "1 day",
    },
  ];

  //#region Render
  getLeaveListForApprove = () => {
    const newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      employee_id: Params.teacher_id,
      academic_year_id: "",
      status: this.state.status == 0 ? "pending" : "history",
    };
    console.log(newRequestBody, ApiConfig.EMPLOYEE_LEAVE_LIST_API, "working");
    simplePostCall(
      ApiConfig.EMPLOYEE_LEAVE_LIST_API,
      JSON.stringify(newRequestBody),
    )
      .then((data) => {
        if (data.result) {
          console.log("working", data);
          this.setState({
            ...this.state,
            leaveList: data.list,
            isloading: false,
          });
        } else {
          this.setState({ ...this.state, isloading: false });
          // toast.error(data.message);
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, isloading: false });
        console.log("api response", error);
      });
  };
  componentDidMount() {
    this.getLeaveListForApprove();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.status !== prevState.status) {
      this.getLeaveListForApprove();
      this.setState({ leaveList: [] });
    }
  }
  category = [
    { value: "Planned", label: "Planned" },
    { value: "Unplanned", label: "Unplanned" },
  ];
  // status = [
  //   { value: "Approved", label: "Approved" },
  //   { value: "Pending", label: "Pending" },
  //   { value: "Cancelled", label: "Cancelled" },
  // ];

  render() {
    const { t } = this.props;
    return (
      <>
        <div class="Forum library">
          <div className=" approve-leave">
            {/* <!-- Container-fluid starts--> */}
            <div className="row">
              <div className="col-12">
                <div className="card br-15">
                  <div className="card-body">
                    <div className="n-attend-total d-flex">
                      <div className="flex-grow-1 n-attend-title">
                        <h3>{t("Subordinate Leaves")}</h3>
                      </div>
                      <div className="h-i-m-g">
                        <img src={user} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="books-main-wrapper">
                        <TabView
                          activeIndex={this.state.status}
                          onTabChange={(e) =>
                            this.setState({ status: e.index })
                          }
                        >
                          <TabPanel header={t("Pending")}>
                            {this.state.isloading ? (
                              <TablesSkeleton />
                            ) : (
                              <div className="my-books p-4">
                                <div className="row">
                                  {this.state.leaveList &&
                                  this.state.leaveList.length > 0 ? (
                                    this.state.leaveList.map((leave, index) => (
                                      <div
                                        className="col-md-6 col-lg-4 col-sm-12"
                                        key={index}
                                        onClick={() =>
                                          this.openColseModal(leave)
                                        }
                                      >
                                        <div
                                          className="book-card"
                                          style={{
                                            display: "flex",
                                            backgroundColor: "#772569",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            padding: "18px", // Added padding
                                            borderRadius: "8px", // Added border radius
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={
                                                leave.employee_profile_pic
                                                  ? leave.employee_profile_pic
                                                  : dashboardAvatar
                                              }
                                              alt="..."
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                borderRadius: "50%",
                                                marginRight: "5px",
                                              }}
                                            />{" "}
                                            {/* Reduced image size and added border radius */}
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                color: "white",
                                                margin: "0",
                                              }}
                                            >
                                              {leave.employee_name +
                                                " " +
                                                leave.employee_last_name}
                                            </p>{" "}
                                            {/* Reduced font size */}
                                          </div>
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              color: "#72A44B",
                                              margin: "0",
                                            }}
                                          >
                                            {leave.employee_leave_no_of_days}{" "}
                                            {leave.employee_leave_no_of_days > 1
                                              ? "Days"
                                              : "Day"}
                                          </p>{" "}
                                          {/* Reduced font size */}
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      className="student-rank-list"
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          justifyContent: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        <p>{t("No records found")}..!</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel header={t("History")}>
                            {this.state.isloading ? (
                              <TablesSkeleton />
                            ) : (
                              <div className="my-books p-4">
                                <div className="row">
                                  {this.state.leaveList &&
                                  this.state.leaveList.length > 0 ? (
                                    this.state.leaveList.map((leave, index) => (
                                      <div
                                        className="col-md-6 col-lg-4 col-sm-12"
                                        key={index}
                                        onClick={() =>
                                          this.openColseModal(leave)
                                        }
                                      >
                                        <div
                                          className="book-card"
                                          style={{
                                            display: "flex",
                                            backgroundColor: "#772569",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            padding: "18px", // Added padding
                                            borderRadius: "8px", // Added border radius
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={
                                                leave.employee_profile_pic
                                                  ? leave.employee_profile_pic
                                                  : dashboardAvatar
                                              }
                                              alt="..."
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                borderRadius: "50%",
                                                marginRight: "5px",
                                              }}
                                            />{" "}
                                            {/* Reduced image size and added border radius */}
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                color: "white",
                                                margin: "0",
                                              }}
                                            >
                                              {leave.employee_name +
                                                " " +
                                                leave.employee_last_name}
                                            </p>{" "}
                                            {/* Reduced font size */}
                                          </div>
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              color: "#72A44B",
                                              margin: "0",
                                            }}
                                          >
                                            {leave.employee_leave_no_of_days}{" "}
                                            {leave.employee_leave_no_of_days > 1
                                              ? "Days"
                                              : "Day"}
                                          </p>{" "}
                                          {/* Reduced font size */}
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      className="student-rank-list"
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          justifyContent: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        <p>{t("No records found")}..!</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                        </TabView>
                      </div>
                    </div>
                    {/* Modal start */}
                    {this.state.isTodoModelShow ? (
                      <LeavesModal
                        onClose={this.openColseModal}
                        leave_data={this.state.leave_data}
                        getLeaveListForApprove={this.getLeaveListForApprove}
                      />
                    ) : null}
                    {/* modal End */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ApproveLeaves);
