import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { StudentsViewskeleton } from "./StudentsViewskeleton";
import StudentViewAcademicCalendar from "./StudentViewCalender";
import { useTranslation } from "react-i18next";

export const StudentDetails = () => {
  const location = useLocation();
  const getData = location.state;
  const [viewdata, setViewdata] = useState("");
  const [loading, setLoading] = useState(true);

  const StudentsListView = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      student_id: getData,
    });
    simplePostCall(ApiConfig.STUDENT_LIST_VIEW, RequestBody)
      .then((data) => {
        if (data.result) {
          setLoading(false);
          console.log(data.studentDetails, "updateddetails");
          setViewdata(data.studentDetails[0]);
          console.log(viewdata, "stored data");
        } else {
          setLoading(false);
          console.log("no data to view");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("api rres failed", err);
      });
  };
  useEffect(() => {
    StudentsListView();
  }, []);
  //----------------------------Translation --------------------------//
  const { t } = useTranslation();
  return (
    <div>
      {loading ? (
        <StudentsViewskeleton />
      ) : (
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="labelCreate">{t("Student Details")}</label>
              </div>
            </div>

            <div className="tablesecc">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="studDet">{t("Student Details")}</h4>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th scope="col-md-6">{t("Student Name")}</th>
                          <td scope="col-md-6" className="textcol">
                            {viewdata.student_name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Student Image")}</th>
                          <td scope="col">
                            <img src={viewdata.student_profilepicture} />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Admission Number")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_admission_num}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Grade")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.class_name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Division Name")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.class_division_name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Address")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_address}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Mobile Number")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_mobile}
                          </td>
                        </tr>

                        <tr>
                          <th scope="col">{t("Email ID")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_email}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Mifare Card ID")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_mifare_card_id}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("School Hours")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_session}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Student Landmark")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_landmark}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Disability")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_disability}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Blood Group")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_blood_group}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Date of Birth")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_dob}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Gender")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_gender}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Student Nationality")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_nationality}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Status")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_status}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">{t("Student User Id")}</th>
                          <td scope="col" className="textcol">
                            {viewdata.student_user_id}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <div className="tablesec">
                      <h4 className="studDet">{t("Father Details")}</h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th scope="col">{t("First Name")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_father_name}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">{t("Email")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_father_email}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">{t("Contact Number")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_father_mobile}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tablesec">
                      <h4 className="studDet">{t("Mother Details")}</h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th scope="col">{t("First Name")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_mother_name}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">{t("Email")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_mother_email}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col">{t("Contact Number")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.student_mother_mobile}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tablesec">
                      <h4 className="studDet">{t("Wallet Details")}</h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th scope="col">{t("Wallet Amount")}</th>
                            <td scope="col" className="textcol">
                              {viewdata.wallet_amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="calenderMain">
            <div className="col-md-12">
              <StudentViewAcademicCalendar />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
