import React, { useEffect, useState } from "react";
import {
  Video,
  FileText,
  Image,
  File,
  Headphones,
  Trash2,
  Edit3,
  Eye,
  PlusCircle,
} from "react-feather";
import { useLocation, Link } from "react-router-dom";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next"; 

function ViewAnnouncement() {
  const { t } = useTranslation();

  const [announcement_data, setAnnouncement_data] = useState(
    useLocation().state
  );
  console.log("state :: ", announcement_data);
  const [isloading, setIsloading] = useState(false);
  const loadContentImage = (type) => {
    if (type === "text") return <FileText />;
    else if (type === "image") return <Image />;
    else if (type === "pdf") return <File />;
    else if (type === "audio") return <Headphones />;
  };
  useEffect(() => {
    document.title = "Edsys | Announcement ";
  }, []);
  return (
    <>
      {/* Container-fluid starts */}
      <div className="row announcement assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1"> {t('View Announcement')}</h5>
                <Link to="/Announcement" className="btn btn-primary">
                  {t('back')}
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {isloading ? (
                  <SpinnerCmp loading={isloading} />
                ) : (
                  <div className="row ">
                    <div className="col-12">
                      <div className="card br-15">
                        <div className="card-body">
                            <div className="details-banner">
                              <h6>{announcement_data && announcement_data.announcement_type}</h6>
                              <p>{announcement_data && announcement_data.announcement_content}</p>
                             
                              <div className="d-flex">
                                <div className="sub-date">
                                  {announcement_data && announcement_data.announcement_date}
                                </div>
                              </div>
                            </div>
                          <div className="desc mt-3">
                            {announcement_data && announcement_data.announcement_content_type !==
                            "text" ? (
                              <h5>{t('Announcement File')}</h5>
                            ) : null}




                            {announcement_data && announcement_data.announcement_content_type ===
                            "image" && announcement_data.announcement_image? (
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={announcement_data.announcement_image}
                              ></img>
                            ) : announcement_data.announcement_content_type ===
                              "pdf" ? (
                              <iframe
                                style={{ width: "100%", height: "800px" }}
                                src={announcement_data.announcement_image}
                              ></iframe>
                            ) : announcement_data.announcement_content_type ===
                              "audio" ? (
                              <audio
                                width={"100%"}
                                controls
                                src={announcement_data.announcement_image}
                              />
                            ) : <p>{t('File is not available')}</p>}
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-fluid Ends */}
    </>
  );
}

export default ViewAnnouncement;
