import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
import Params from "../../config/Params";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

export const CreateFeed = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigation = useNavigate();
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission
    if (title === "" || description === "") {
      alert("Title and Description are required!"); // Replace with toast if needed
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("teacher_id", Params.teacher_id);
    formData.append("feed_title", title);
    formData.append("feed_description", description);

    selectedFiles.forEach((file, i) => {
      formData.append(`feed_attachment[${i}]`, file);
    });

    try {
      const response = await axios.post(`${ApiConfig.FEED_ADD}`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
          customer_id: Params.customer_id,
          api_key: Params.api_key,
        },
      });

      if (response.data.result) {
        toast.success(response.data.message); // Replace with toast if needed
        navigation("/Feed");
      } else {
        setIsLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Submission Error:", error);
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 4) {
      toast.error("You can only upload up to 4 files.");
      return;
    }
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleDelete = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="CreateDiary">
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreate">
              <label className="labelCreate">{t("Create Feed")}</label>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="contentHeader">{t("Title")}</label>
                  <textarea
                    className="createInput form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="contentHeader">{t("Description")}</label>
                  <textarea
                    className="form-control createInputt"
                    rows="4"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div
                  className="form-group"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label className="contentHeader">{t("Attachment")}</label>

                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{
                      background: "transparent",
                      border: "1px solid #cfd2d4",
                      padding: "10px",
                    }}
                  />
                  <div className="form-group">
                    <label className="contentHeader">
                    {t("Attachments only 4 Files are allowed")}
                    </label>

                    {errors.attach && (
                      <span className="text-danger">{errors.attach}</span>
                    )}
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="deletebutt">
                        <p>{file.name}</p>
                        <button
                          onClick={() => handleDelete(index)}
                          className="closebtt"
                        >
                          <IoIosClose />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group SubmitDiv">
                  <button
                    type="submit"
                    className="submitBut"
                    disabled={isLoading}
                  >
                  {isLoading ? t("Creating") : t("Create")}
               
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
