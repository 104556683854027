import React, { useState, useEffect } from "react";
import { ChatList, MessageBox, Input, Button } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "../../../assets/css/ChatComponent.css";
import axios from "axios";
import { FaPlus, FaPaperclip, FaPaperPlane } from "react-icons/fa";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";


const Chat = () => {
  const [chatList, setChatList] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [page, setPage] = useState(1); // Current page number
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // Check if there's more data
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [conLoad, setconLoad] = useState(false);
  const [newChatList, setNewChatList] = useState([]);
  const pageLimit = 10;
  const toggleModal = () => setShowModal(!showModal);
  // Initialize socket connection
  const socket = React.useRef(null);
  useEffect(() => {
    // Connect to the socket server
    socket.current = io("https://app.edsys.in:3000", {
      query: {
        customerId: 1071,
        user_type: "teacher",
        userId: 39165,
      },
    });

    // Join the room when a chat is selected
    if (activeChat) {
      socket.current.emit("room", {
        chatRoomId: activeChat?.id,
      });
    }

    // Handle incoming messages
    socket.current.on("message", (data) => {
      console.log(data, "response data");
      const attachments = data.list[0]?.message_attachments || [];
      const formattedAttachments = attachments.map((attachment) => ({
        url: `https://app.edsys.in:3000/${attachment.attachment_url}`,
        type: attachment.attachment_type,
        name: attachment.attachment_name,
      }));
      const newMessage = {
        position: data.list[0]?.message_from_id === 39165 ? "right" : "left",
        name:
          data.list[0]?.message_from_id === 39165
            ? "Teacher"
            : activeChat.title,
        avatar:
          data.list[0]?.message_from_id === 39165
            ? "Teacher"
            : activeChat.avatar,
        type: "text", // Default type
        text: data.list[0]?.message_text || "",
        date: new Date(data.list[0]?.message_date_time || Date.now()),
        attachments: formattedAttachments, // Add attachments to the message object
      };

      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    });

    // Cleanup socket on unmount
    return () => {
      socket.current.disconnect();
    };
  }, [activeChat]);
  // Fetch API Data
  const fetchChatList = async (pageNo) => {
    if (isLoading || !hasMore) return; // Prevent duplicate calls

    setIsLoading(true);

    axios("https://app.edsys.in:3000/api/teacher/chat/list", {
      method: "GET",
      params: {
        teacher_id: 39165,
        page_no: pageNo,
        page_limit: 20,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: "fd3d3375a2658b9177cfe0958446ea4f",
        customer_id: 1071,
      },
    })
      .then((response) => {
        if (response.data.result && response.data.list) {
          const formattedChatList = response.data.list.map((item) => ({
            id: item.chat_thread_id,
            title: item.receptient_name,
            receiverId:
              item.chat_thread_from_id !== 39165
                ? item.chat_thread_from_id
                : item.chat_thread_to_id,

            subtitle:
              item.message_details.length > 0
                ? item.message_details[0].message_text || "No message"
                : "No message",
            date: new Date(
              item.message_details[0]?.message_date_time || Date.now()
            ),
            unread: parseInt(item.unread_message_count, 10),
            avatar: item.receptient_user_profile_pic
              ? `https://app.edsys.in:3000/${item.receptient_user_profile_pic}`
              : "https://via.placeholder.com/150",
          }));

          setChatList((prevChatList) => [
            ...prevChatList,
            ...formattedChatList,
          ]);

          // Check if more data is available
          if (response.data.list.length < 8) {
            setHasMore(false); // No more pages to fetch
          }
        }
      })
      .catch((err) => {
        console.log("Error fetching chat list: ", err);
      })
      .finally(() => setIsLoading(false));
  };
  // Fetch Chat History Data based on the active chat
  const fetchChatHistoryApi = (threadId) => {
    axios("https://app.edsys.in:3000/api/teacher/chat/message/list", {
      method: "GET",
      params: {
        thread_id: threadId,
        page_no: 1,
        page_limit: 10,
        teacher_id: 39165,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: "fd3d3375a2658b9177cfe0958446ea4f",
        customer_id: 1071,
      },
    })
      .then((response) => {
        if (response.data.result && response.data.data) {
          const formattedMessages = response.data.data.map((msg) => ({
            position: msg.message_from_id === 39165 ? "right" : "left",
            name: msg.message_from_id === 39165 ? "Teacher" : activeChat.title,
            avatar:
              msg.message_from_id === 39165 ? "Teacher" : activeChat.avatar,
            type: "text", // Default to text
            text: msg.message_text || "",
            date: new Date(msg.message_date_time),
            attachments: (msg.message_attachments || []).map((attachment) => ({
              url: `https://app.edsys.in:3000/${attachment.attachment_url}`,
              type: attachment.attachment_type,
              name: attachment.attachment_name,
            })),
          }));
          setMessages(formattedMessages);
        }
      })
      .catch((err) => {
        console.log("Error fetching chat history: ", err);
      });
  };
  const fetchContactList = async () => {
    axios("https://app.edsys.in:3000/api/teacher/chat/userlist?", {
      method: "GET",
      params: {
        teacher_id: 39165,
        search: searchQuery,
        page_no: pageNo,
        page_limit: pageLimit,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: "fd3d3375a2658b9177cfe0958446ea4f",
        customer_id: 1071,
      },
    })
      .then((response) => {
        if (response.data.result && response.data.data) {
          // Check if more data is available
          console.log(response.data, "contact listsss");
          setNewChatList(response.data.data || []);
          setTotalPages(response.data.total_pages || 1);
          setTotalPages(Math.ceil(response.data.total_count / pageLimit));
        }
      })
      .catch((err) => {
        console.log("Error fetching chat list: ", err);
      })
      .finally(() => setIsLoading(false));
  };
  // new chat api
  const newChatApi = async (id) => {
    console.log("response.data)");
    try {
      const requestBody = {
        teacher_id: 39165,
        recepient: id,
      };
      const response = await axios.post(
        "https://app.edsys.in:3000/api/teacher/new/chat",
        requestBody,
        {
          headers: {
            api_key: "fd3d3375a2658b9177cfe0958446ea4f",
            customer_id: 1071,
          },
        }
      );

      if (response.data) {
        console.log("response.data)", response.data);
        setActiveChat({
          id: response.data.thread_id,
          title: selectedContact.user_name,
          receiverId: selectedContact.user_id,
          subtitle: "new message",
          date: new Date(Date.now()),
          unread: parseInt(0, 10),
          avatar: selectedContact.user_profile_pic
            ? `https://app.edsys.in:3000/${selectedContact.user_profile_pic}`
            : "https://via.placeholder.com/150",
        });
        setMessages([]);
      }
    } catch (error) {
      console.log("api response", error);
    }
  };

  // Load the first page initially
  useEffect(() => {
    fetchChatList(page);
  }, [page]);
  // Load messages when an active chat is selected
  useEffect(() => {
    if (activeChat) {
      fetchChatHistoryApi(activeChat.id);
    }
  }, [activeChat]);
  // Fetch on modal open
  useEffect(() => {
    if (showModal) fetchContactList();
  }, [showModal, searchQuery, pageNo]);
  // Load messages for the active chat
  useEffect(() => {
    if (activeChat && messages.length === 0) {
      const newMessages = [
        {
          position: "left",
          type: "text",
          text: `Chat with ${activeChat.title} started.`,
          date: new Date(),
        },
      ];
      setMessages(newMessages);
    }
  }, [activeChat]);

  const handleSendMessage = () => {
    if (inputText.trim() !== "" || attachment) {
      const messageData = {
        customer_id: "1071",
        chatRoomId: activeChat.id,
        receiver_role: "parent",
        senderID: "39165",
        receiverId: activeChat.receiverId,
        message: inputText,
        file: [], // Initialize empty attachments array
      };

      if (attachment) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64File = reader.result.split(",")[1]; // Extract base64 content

          // Create the attachment object
          const attachmentData = {
            file_attach: base64File, // Use the Base64 content here
            file_type: attachment.type.startsWith("image/")
              ? "image"
              : attachment.type.startsWith("video/")
              ? "video"
              : attachment.type.startsWith("audio/")
              ? "audio"
              : "file",
            file_name: attachment.name,
          };
          console.log(attachmentData, "base64dataaaa");
          // Add the attachment to the message_attachments array
          messageData.file.push(attachmentData);

          // Emit message with attachment
          socket.current.emit("message_sent", messageData);

          // Clear input and preview after sending
          setInputText("");
          setAttachment(null);
          setAttachmentPreview(null);
        };
        reader.readAsDataURL(attachment);
      } else {
        // Emit message without attachment
        socket.current.emit("message_sent", messageData);

        // Clear input
        setInputText("");
      }
    }
  };

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);

      // Create a preview URL for the file
      const previewURL = URL.createObjectURL(file);
      setAttachmentPreview({
        name: file.name,
        type: file.type,
        url: previewURL,
      });
    }
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPageNo(1); // Reset to first page on new search
  };
  const handleContactSelect = (contact) => {
    setSelectedContact(contact);
  };
  console.log("this is id ", selectedContact);
  console.log("this is messages ", messages);

  //Translation
  const { t } = useTranslation();

  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="chat-sidebar">
        <h2>{t("Chats")}</h2>
        <ChatList
          className="chat-list"
          dataSource={chatList}
          onClick={(chat) => setActiveChat(chat)}
        />

        {/* Load More Button */}
        {/* {hasMore && (
          <div className="load-more-container">
            <button
              onClick={() => setPage((prevPage) => prevPage + 1)}
              disabled={isLoading}
              className="load-more-button"
            >
              {isLoading ? (
                <div className="loading-spinner">
                  <div className="spinner"></div>
                  Loading...
                </div>
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )} */}
      </div>

      {/* Chat Window */}
      <div className="chat-window">
        {activeChat ? (
          <>
            {/* Header */}
            <div className="chat-header">
              <h3>{activeChat.title}</h3>
            </div>
            {/* Messages */}
            <div className="chat-messages">
              {messages
                .slice(0)
                .reverse()
                .map((msg, index) => (
                  <div key={index}>
                    {/* Handle attachments */}
                    {msg.attachments && msg.attachments.length > 0 ? (
                      msg.attachments.map((attachment, idx) => (
                        <MessageBox
                          key={`${index}-${idx}`}
                          position={msg.position}
                          avatar={msg.avatar} // Add avatar here
                          title={msg.name}
                          type={
                            attachment.type === "image"
                              ? "photo"
                              : attachment.type === "video"
                              ? "video"
                              : attachment.type === "audio"
                              ? "audio"
                              : "file"
                          }
                          data={{
                            ...(attachment.type === "video" && {
                              videoURL: attachment.url, // Use videoURL for videos
                              status: {
                                click: true,
                                loading: 0, // Adjust loading state as needed
                                download: true, // Enable download if desired
                              },
                            }),
                            ...(attachment.type === "file" && {
                              fileName: attachment.name, // Handle file downloads
                            }),
                            ...(attachment.type !== "video" && {
                              uri: attachment.url,
                            }), // Use uri for other types
                          }}
                          date={msg.date}
                          styles={{
                            photo: {
                              maxWidth: "200px",
                              maxHeight: "200px",
                              borderRadius: "8px",
                              cursor: "pointer", // Add pointer cursor to indicate clickability
                            },
                          }}
                          onClick={() => window.open(attachment.url, "_blank")} // Open image in new tab
                        />
                      ))
                    ) : (
                      // Render text messages
                      <MessageBox
                        position={msg.position}
                        type="text"
                        title={msg.name}
                        avatar={msg.avatar} // Add avatar here
                        text={msg.text}
                        date={msg.date}
                      />
                    )}
                    {/* Seen/Unseen Marker
                    {msg.position === "right" && (
                      <p className="seen-marker">
                        {msg.seen ? "Seen" : "Unseen"}
                      </p>
                    )} */}
                  </div>
                ))}
            </div>

            {/* Input Field */}
            <div className="chat-input" 
            // style={{position:"relative"}}
            >
              {attachmentPreview && (
                <div className="attachment-preview-small">
                  {attachmentPreview.type.startsWith("image/") ? (
                    <img
                      src={attachmentPreview.url}
                      alt={attachmentPreview.name}
                      className="attachment-image-small"
                    />
                  ) : (
                    <p className="attachment-file-name">
                      {attachmentPreview.name}
                    </p>
                  )}
                  <button
                    className="remove-preview-button"
                    onClick={() => setAttachmentPreview(null)}
                  >
                    X
                  </button>
                </div>
              )}
              <input
                type="file"
                id="file-input"
                style={{ display: "none" }}
                onChange={handleAttachmentChange}
              />
              <div className="input-buttons-container">
                <div className="input-wrapper">
                  <Input
                    placeholder="Type a message..."
                    multiline={false}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Prevents a new line from being added
                        handleSendMessage();
                      }
                    }}
                  />
                </div>
                <div className="buttons-wrapper">
                  <button
                    className="icon-button"
                    onClick={() =>
                      document.getElementById("file-input").click()
                    }
                  >
                    <FaPaperclip size={20} />
                  </button>
                  <button className="icon-button" onClick={handleSendMessage}>
                    <FaPaperPlane size={20} />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="chat-placeholder">
            <h3>{t("Select a chat to start messaging")}</h3>
          </div>
        )}
      </div>
      {/* <button className="floating-add-btn" onClick={() => setShowModal(true)}>
        <FaPlus size={24} />
      </button> */}
      {/* <!-- Button trigger modal --> */}
      <button
        type="button"
        className="floating-add-btn"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        onClick={toggleModal}
      >
        <FaPlus size={24} />
      </button>

      {/* <!-- Modal --> */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
              {t("Contact List")} 
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              ></button>
            </div>
            <div
              className="modal-body overflow-auto"
              style={{ maxHeight: "400px" }}
            >
              {/* Search Input */}
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Search contacts..."
                value={searchQuery}
                onChange={handleSearch}
              />

              {/* Contact List */}
              {isLoading ? (
                <p>{t("Loading...")}</p>
              ) : (
                <ul className="list-group">
                  {newChatList.map((contact, index) => (
                    <li
                      key={index}
                      className={`list-group-item d-flex align-items-center ${
                        selectedContact === contact
                          ? "bg-primary text-white"
                          : ""
                      }`}
                      onClick={() => handleContactSelect(contact)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={contact.profilePic}
                        alt={contact.name}
                        className="rounded-circle me-3"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <div>
                        <strong>{contact.user_name}</strong> <br />
                        <small>{contact.user_role}</small> <br />
                        <small>{contact.phone}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between p-3">
              <button
                className="btn btn-secondary"
                onClick={() => setPageNo((prev) => Math.max(prev - 1, 1))}
                disabled={pageNo === 1}
              >
               {t("Previous")} 
              </button>
              <span>
              {t("Page")}   {pageNo} of {totalPages}
              </span>
              <button
                className="btn btn-secondary"
                onClick={() =>
                  setPageNo((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={pageNo >= totalPages}
              >
               {t("Next")} 
              </button>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={toggleModal}
              >
             {t("Close")}  
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  toggleModal();
                  newChatApi(selectedContact?.user_id);
                }}
                disabled={!selectedContact}
              >
              {t("New Chat")} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
