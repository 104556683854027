import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import bg_1 from "../../../assets/images/assignment/bg_1.svg";
import b1 from "../../../assets/images/user/b1.png";
import b2 from "../../../assets/images/user/b2.png";
import b3 from "../../../assets/images/user/b3.png";
import b4 from "../../../assets/images/user/b4.png";
import b5 from "../../../assets/images/user/b5.png";
import b6 from "../../../assets/images/user/b6.png";
import b7 from "../../../assets/images/user/b7.png";
import b8 from "../../../assets/images/user/b8.png";
import { Check } from "react-feather";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";
import AssignmentDetailsSkeleton from "../../../components/skeletons/AssignmentDetailsSkeleton";

function AssignmentDetails() {
  const { t } = useTranslation();
  const nav = useNavigate();

  const { id } = useParams();
  console.log(id, "idzzzzzz");
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    assignment_students: [],
    filteredData: [],
    activeTab: "all",
  });
  const base_url = "https://app.edsys.in:3000/";
  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
    getAssignments();
  }, [id]);

  function getAssignments() {
    setLoading(true);
    simplePostCall(
      ApiConfig.ASSIGNMENT_DETAILS_API,
      JSON.stringify({ ...Params, assignment_id: id })
    )
      .then((data) => {
        // if (data.result) {
        setLoading(false);

        setState({ ...data, filteredData: data.assignment_students });
        // }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const Data = { id };

  //............formatdate.............//

  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }

  const assignmentCreatedDate =
    state.assignment_details &&
    state.assignment_details.assignment_created_date;
  const formattedDate =
    assignmentCreatedDate && formatDate(assignmentCreatedDate);
  console.log(formattedDate, "formattedDate");

  const studentIds = state.assignment_students.map(
    (student) => student.student_id
  );

  function filterData(status) {
    var result;
    if (status === "all") {
      result = state.assignment_students;
    } else {
      result = state.assignment_students.filter(
        (stud) => stud.assignment_student_status === status
      );
    }

    setState({ ...state, filteredData: result, activeTab: status });
  }

  return (
    <>
      {/* Container-fluid starts */}
      {/* {!state.assignment_details ? (
        <SpinnerCmp loading={true} />
      ) : ( */}
      {loading ? (
        <AssignmentDetailsSkeleton />
      ) : (
        <div className="row assignment-details">
          <div className="col-12">
            <div
              className="knowledgebase-bg b-center bg-size"
              style={{
                backgroundImage: `url(${bg_1})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                display: "block",
              }}
            >
              <img
                className="bg-img-cover bg-center"
                src={bg_1}
                alt="looginpage"
                style={{ display: "none" }}
              />
            </div>
            <div className="knowledgebase-search">
              <div className="details-banner">
                <h3>
                  {state.assignment_details &&
                    state.assignment_details.assignment_title}
                </h3>
                <h5>
                  {state.assignment_details &&
                    state.assignment_details.assignment_subject}
                </h5>
                <h6>
                  {t("Assigned by")}
                  <span>
                    {state.assignment_details &&
                      state.assignment_details.assignment_assigned_by}
                  </span>
                </h6>
                <div className="sub-date">{formattedDate}</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card br-15">
              <div className="card-header pb-0 br-15">
                <h5 className="pull-left">{t("Students")}</h5>
              </div>
              <div className="card-body">
                <div className="tabbed-card">
                  <ul
                    className="pull-right nav nav-pills nav-primary"
                    id="pills-clrtab1"
                    role="tablist"
                  >
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      className="nav-item"
                    >
                      <a
                        className={`nav-link ${
                          state.activeTab === "all" ? "active" : ""
                        }`}
                        id="pills-all-tab1"
                        data-bs-toggle="pill"
                        // href="#pills-all"
                        role="tab"
                        aria-controls="pills-all"
                        aria-selected="true"
                        onClick={() => {
                          filterData("all");
                        }}
                      >
                        {t("ALL")}
                      </a>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      className="nav-item"
                    >
                      <a
                        className={`nav-link ${
                          state.activeTab === "reviewed" ? "active" : ""
                        }`}
                        id="pills-reviewed-tab1"
                        data-bs-toggle="pill"
                        // href="#pills-reviewed"
                        role="tab"
                        aria-controls="pills-reviewed"
                        aria-selected="true"
                        onClick={() => {
                          filterData("reviewed");
                        }}
                      >
                        {t("REVIEWED")}
                      </a>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      className="nav-item"
                    >
                      <a
                        className={`nav-link ${
                          state.activeTab === "submitted" ? "active" : ""
                        }`}
                        id="pills-submitted-tab1"
                        data-bs-toggle="pill"
                        //href="#pills-submitted"
                        role="tab"
                        aria-controls="pills-submitted"
                        aria-selected="false"
                        onClick={() => {
                          filterData("submitted");
                        }}
                      >
                        {t("SUBMITTED")}
                      </a>
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                      }}
                      className="nav-item"
                    >
                      <a
                        className={`nav-link ${
                          state.activeTab === "pending" ? "active" : ""
                        }`}
                        id="pills-pending-tab1"
                        data-bs-toggle="pill"
                        //href="#pills-pending"
                        role="tab"
                        aria-controls="pills-pending"
                        aria-selected="false"
                        onClick={() => {
                          filterData("pending");
                        }}
                      >
                        {t("PENDING")}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-clrtabContent1">
                    <div
                      className="tab-pane fade show active"
                      id="pills-all"
                      role="tabpanel"
                      aria-labelledby="pills-all-tab1"
                    >
                      <div className="assignment-status">
                        {state.filteredData && state.filteredData.length > 0 ? (
                          state.filteredData.map((stud, index) => (
                            <div key={index} className="student">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 user-image">
                                  <img
                                    src={base_url + stud.profile_pic}
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="full-name">
                                    {stud.student_name}
                                  </div>
                                </div>

                                <div>
                                  {stud.assignment_student_status ===
                                  "reviewed" ? (
                                    <div
                                      onClick={() => {
                                        const url = `/ViewReviewedAssignment/${stud.student_id}/${id}`;
                                        nav(url);
                                      }}
                                      className={
                                        "status " +
                                        stud.assignment_student_status
                                      }
                                    >
                                      <Check
                                        className="me-2"
                                        data-feather="check"
                                      />
                                      {stud.assignment_student_status}
                                    </div>
                                  ) : stud.assignment_student_status ===
                                    "submitted" ? (
                                    <div
                                      onClick={() => {
                                        const url = `/ViewReviewedAssignment/${stud.student_id}/${id}`;
                                        nav(url);
                                      }}
                                      className={
                                        "status " +
                                        stud.assignment_student_status
                                      }
                                    >
                                      <Check
                                        className="me-2"
                                        data-feather="check"
                                      />
                                      {stud.assignment_student_status}
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        "status " +
                                        stud.assignment_student_status
                                      }
                                    >
                                      <Check
                                        className="me-2"
                                        data-feather="check"
                                      />
                                      {stud.assignment_student_status}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="student">
                            <div className="d-flex align-items-center">
                              <p>{t("No records found")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-reviewed"
                      role="tabpanel"
                      aria-labelledby="pills-reviewed-tab1"
                    >
                      <div className="assignment-status">
                        {/* {studData.map((studData) => (
                        <div className="student">
                          <div
                            className="d-flex align-items-center"
                            key={studData}
                          >
                            <div className="flex-shrink-0 user-image">
                              <img src={studData.icon_set} alt="..." />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="full-name">{studData.name}</div>
                            </div>
                            <Link to="/ViewReviewedAssignment/1">
                              <div className="status reviewed">
                                <Check className="me-2" />
                                reviewed
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))} */}
                        {state.filteredData && state.filteredData.length > 0 ? (
                          state.filteredData.map((stud, index) => (
                            <div key={index} className="student">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 user-image">
                                  <img
                                    src={base_url + stud.profile_pic}
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="full-name">
                                    {stud.student_name}
                                  </div>
                                </div>

                                <div
                                  onclick="location.href='view-assignment.html';"
                                  className={
                                    "status " + stud.assignment_student_status
                                  }
                                >
                                  <Check
                                    className="me-2"
                                    data-feather="check"
                                  />
                                  {stud.assignment_student_status}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="student">
                            <div className="d-flex align-items-center">
                              <p>{t("No records found")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-submitted"
                      role="tabpanel"
                      aria-labelledby="pills-submitted-tab1"
                    >
                      <div className="assignment-status">
                        <div className="assignment-status">
                          {/* {studData.map((studData) => (
                          <div className="student">
                            <div
                              className="d-flex align-items-center"
                              key={studData}
                            >
                              <div className="flex-shrink-0 user-image">
                                <img src={studData.icon_set} alt="..." />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <div className="full-name">{studData.name}</div>
                              </div>
                              <Link to="/ViewReviewedAssignment/">
                                <div
                                  onclick="location.href='view-assignment.html';"
                                  className="status submitted"
                                >
                                  <Check
                                    className="me-2"
                                    data-feather="check"
                                  />
                                  Submitted
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))} */}
                          {state.filteredData &&
                          state.filteredData.length > 0 ? (
                            state.filteredData.map((stud, index) => (
                              <div key={index} className="student">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 user-image">
                                    <img
                                      src={base_url + stud.profile_pic}
                                      alt="..."
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="full-name">
                                      {stud.student_name}
                                    </div>
                                  </div>

                                  <div
                                    onclick="location.href='view-assignment.html';"
                                    className={
                                      "status " + stud.assignment_student_status
                                    }
                                  >
                                    <Check
                                      className="me-2"
                                      data-feather="check"
                                    />
                                    {stud.assignment_student_status}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="student">
                              <div className="d-flex align-items-center">
                                <p>{t("No records found")}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-pending"
                      role="tabpanel"
                      aria-labelledby="pills-pending-tab1"
                    >
                      <div className="assignment-status">
                        {/* {studData.map((studData) => (
                        <div className="student">
                          <div
                            className="d-flex align-items-center"
                            key={studData}
                          >
                            <div className="flex-shrink-0 user-image">
                              <img src={studData.icon_set} alt="..." />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="full-name">{studData.name}</div>
                            </div>
                            <div
                              onclick="location.href='view-assignment.html';"
                              className="status pending"
                            >
                              <Check className="me-2" />
                              Pending
                            </div>
                          </div>
                        </div>
                      ))} */}
                        {state.filteredData && state.filteredData.length > 0 ? (
                          state.filteredData.map((stud, index) => (
                            <div key={index} className="student">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 user-image">
                                  <img
                                    src={base_url + stud.profile_pic}
                                    alt="..."
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="full-name">
                                    {stud.student_name}
                                  </div>
                                </div>

                                <div
                                  onclick="location.href='view-assignment.html';"
                                  className={
                                    "status " + stud.assignment_student_status
                                  }
                                >
                                  <Check
                                    className="me-2"
                                    data-feather="check"
                                  />
                                  {stud.assignment_student_status}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="student">
                            <div className="d-flex align-items-center">
                              <p>{t("No records found")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Container-fluid Ends */}
    </>
  );
}

export default AssignmentDetails;
