import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Eye, PlusSquare } from "react-feather";
import Params from "../../../../config/Params";
import { simplePostCall } from "../../../../api/ApiServices";
import ApiConfig from "../../../../api/ApiConfig";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import { toast } from "react-toastify";
import MCQSingleAnswer from "../Questions/Views/MCQSingleAnswer";
import CreateQuestion from "../../Assignment/CreateQuestion";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";

export default function AssessmentStepTwo(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [Qustionid, setID] = useState("");
  console.log("Question id", Qustionid, props);

  const { AssessmentIdQustion } = useContext(AppContext);
  console.log("AssessmentIdQustion", AssessmentIdQustion);
  useEffect(() => {
    const id = props.assessmentDetails.assessment_id
      ? props.assessmentDetails.assessment_id
      : AssessmentIdQustion;
    setID(id);
  }, [AssessmentIdQustion]);
  useEffect(() => {
    if (Qustionid === Qustionid) {
      getAssessmentDetails();
      getAvailableQuestions();
    } else {
      getAssessmentDetails();
      getAvailableQuestions();
    }
  }, [Qustionid]);

  const [availableQuestionsState, setAvailableQuestionsState] = useState({
    isloading: false,
    availableQuestions: [],
  });
  const [assessmentState, setAssessmentState] = useState({
    isloading: false,
    assessment: [],
    questions: [],
  });

  const [state, setState] = useState({
    isloading: false,
    assessment_id: props.assessmentDetails.assessment_id
      ? props.assessmentDetails.assessment_id
      : Qustionid,
    isQuestionModalShow: false,
    clickedQuestionID: "",
    clickedQuestionType: "",
  });
  function getAssessmentDetails(_id) {
    setAssessmentState({ ...assessmentState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assessment_id: props.assessmentDetails.assessment_id
        ? props.assessmentDetails.assessment_id
        : Qustionid,
    });
    simplePostCall(ApiConfig.ASSESSMENT_DETAILS_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          setAssessmentState({
            ...assessmentState,
            isloading: false,
            assessment: data.assessment_details,
            questions: data.questions,
          });
        } else {
          setAssessmentState({ ...assessmentState, isloading: false });
        }
      })
      .catch((error) => {
        setAssessmentState({ ...assessmentState, isloading: false });
        console.log("api response", error);
      });
  }

  function getAvailableQuestions() {
    setAvailableQuestionsState({ ...availableQuestionsState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_id: props.assessmentDetails.assessment_id
        ? props.assessmentDetails.assessment_id
        : Qustionid,
      page_limit: 400,
    });
    simplePostCall(
      ApiConfig.AVAILABLE_QUESTIONS_FOR_ASSESSMENT_API,
      newRequestBody
    )
      .then((data) => {
        if (data.result) {
          console.log(data.data, "datain creation of assessment");
          setAvailableQuestionsState({
            ...availableQuestionsState,
            isloading: false,
            availableQuestions: data.data,
          });
        } else {
          setAvailableQuestionsState({
            ...availableQuestionsState,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        setAvailableQuestionsState({
          ...availableQuestionsState,
          isloading: false,
        });
        console.log("api response", error);
      });
  }

  function showAvailableQuestions() {
    if (!state.showAvailableQuestions == true) {
      /* scroll to div starts */
      document.getElementById("availableQuestionsDiv").scrollIntoView({
        behavior: "smooth",
      });
      /* scroll to div ends */
    }
    setState({
      ...state,
      showAvailableQuestions: !state.showAvailableQuestions,
    });
  }

  function openCloseCreateQuestionModel() {
    // ;<CreateQuestion
    //   onClose={openCloseCreateQuestionModel}
    //   getAvailableQuestions={getAvailableQuestions}
    //   //   getAssignmentDetails={getAssignmentDetails}
    //   assignmentId={state.assignment_id}
    // />
  }
  //   function handleNext() {
  //     // if (stateNext !== 3) {
  //     setStateNext(stateNext + 1)
  //     // } else {
  //     //   return
  //     // }
  //   }

  function openCloseQuestionModel(clickedQuestionID, clickedQuestionType) {
    setState({
      ...state,
      isQuestionModalShow: !state.isQuestionModalShow,
      clickedQuestionID: clickedQuestionID,
      clickedQuestionType: clickedQuestionType,
    });
  }

  function addQuestionToAssessment(question_id) {
    setAssessmentState({ ...assessmentState, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_id: props.assessmentDetails.assessment_id
        ? props.assessmentDetails.assessment_id
        : Qustionid,
      question_id: [question_id],
    });
    simplePostCall(ApiConfig.ADD_QUESTION_TO_ASSESSMENT, newRequestBody)
      .then((data) => {
        if (data.result) {
          setAssessmentState({ ...assessmentState, isloading: false });
          toast.success(data.message);
          getAssessmentDetails();
          getAvailableQuestions();
        } else {
          setAssessmentState({ ...assessmentState, isloading: false });
          toast.error(data.message);
          getAssessmentDetails();
          getAvailableQuestions();
        }
      })
      .catch((error) => {
        setAssessmentState({ ...assessmentState, isloading: false });
        console.log("api response", error);
      });
  }

  function publishAssessment() {
    setState({ ...state, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      assessment_id: props.assessmentDetails.assessment_id
        ? props.assessmentDetails.assessment_id
        : Qustionid,
    });
    simplePostCall(ApiConfig.PUBLISH_ASSESSNMENT_API, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          setState({ ...state, isloading: false });
          toast.success(data.message);
          navigate("/AssessmentDetails/" + Qustionid);

          // setTimeout(function () {
          //   window.location.replace(
          //     "/AssessmentDetails/" + props.assessmentDetails.assessment_id
          //   );
          // }, 1000);
        } else {
          setState({ ...state, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  return (
    <>
      {availableQuestionsState.isloading ||
      assessmentState.isloading ||
      state.isloading ? (
        <SpinnerCmp
          loading={
            availableQuestionsState.isloading ||
            assessmentState.isloading ||
            state.isloading
          }
        />
      ) : (
        <>
          <ReactTooltip />
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ float: "left" }}
              >
                {t("Add Questions")}
              </h5>
              <div
                style={{
                  display: "-webkit-inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={showAvailableQuestions}
                  style={{ marginRight: "5px" }}
                >
                  {t("Available Questions")}
                </button>
                <Link to={"CreateQuestion/" + Qustionid}>
                  <button className="btn btn-secondary">
                    {t("Create Question")}
                  </button>
                </Link>
              </div>
            </div>

            <div
              class="modal-body datetime-picker"
              id="questionsUnderAssignment"
            >
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%", float: "left" }}>
                  <tr>
                    <th>{t("Assessment Title")}:</th>
                    <td>{assessmentState.assessment.assessment_title}</td>
                    <th>{t("Grade")}:</th>
                    <td>{assessmentState.assessment.assessment_grade}</td>
                    <th>{t("Subject")}:</th>
                    <td>{assessmentState.assessment.assessment_subject}</td>
                  </tr>
                </table>
              </div>
              <br />
              <br />
              <div className="row edsys-table">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h6>{t("Questions Under Current Assesment")}</h6>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingBottom: "10px" }}
                    >
                      {assessmentState.questions &&
                      assessmentState.questions.length > 0 ? (
                        <table
                          className="table scrollable_table"
                          style={{
                            display:
                              assessmentState.questions.length > 0
                                ? ""
                                : "none",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t("Task Title")} </th>
                              <th scope="col">{t("Lesson Name")}</th>
                              <th scope="col">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assessmentState.questions &&
                            assessmentState.questions.length > 0
                              ? assessmentState.questions.map(
                                  (added_question, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {
                                            added_question.assessment_questions_title
                                          }
                                        </td>
                                        <td>
                                          {
                                            added_question.assessment_questions_type_display_name
                                          }
                                        </td>
                                        <td>
                                          <span
                                            data-tip="View Question"
                                            onClick={() =>
                                              openCloseQuestionModel(
                                                added_question.assessment_questions_id,
                                                added_question.assessment_questions_type
                                              )
                                            }
                                          >
                                            <Eye className="assignment_table_icon" />
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              : null}
                          </tbody>
                        </table>
                      ) : (
                        <span style={{ marginLeft: "1%" }}>
                          {t("No questions found")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="availableQuestionsDiv">
              <div
                class="modal-body datetime-picker"
                style={{ display: state.showAvailableQuestions ? "" : "none" }}
              >
                <div className="row edsys-table">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h6>{t("Available Questions For This Assesment")}</h6>
                      </div>
                      <div
                        className="table-responsive"
                        style={{ paddingBottom: "10px" }}
                      >
                        <table
                          className="table scrollable_table"
                          style={{
                            display: "block",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" style={{ width: "87%" }}>
                                {t("Task Title")}
                              </th>
                              <th scope="col">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {availableQuestionsState.availableQuestions &&
                            availableQuestionsState.availableQuestions.length >
                              0 ? (
                              availableQuestionsState.availableQuestions.map(
                                (available_question, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>
                                        {available_question.question_title}
                                      </td>
                                      <td>
                                        <span
                                          data-tip="View Question"
                                          onClick={() =>
                                            openCloseQuestionModel(
                                              available_question.question_id,
                                              available_question.questions_type
                                            )
                                          }
                                        >
                                          <Eye className="assignment_table_icon" />
                                        </span>

                                        <span
                                          data-tip="Add Question to Assessment"
                                          onClick={() =>
                                            addQuestionToAssessment(
                                              available_question.question_id
                                            )
                                          }
                                        >
                                          <PlusSquare className="assignment_table_icon" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <th scope="row">{t("No tasks found")}</th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                class="btn btn-primary"
                type="button"
                onClick={() => publishAssessment()}
              >
                {t("Publish")}
              </button>
            </div>
          </div>
          <br />
          {state.isQuestionModalShow &&
          state.clickedQuestionType == "selection" ? (
            <MCQSingleAnswer
              onClose={openCloseQuestionModel}
              questionID={state.clickedQuestionID}
              assessmentDetails={assessmentState.assessment}
            />
          ) : null}
          {/* {state.isQuestionModalShow ? (
            // <CreateQuestion
            //   onClose={openCloseCreateQuestionModel}
            //   getAvailableQuestions={getAvailableQuestions}
            //   getAssignmentDetails={getAssignmentDetails}
            //   assignmentId={state.assignment_id}
            // />
            <CreateQuestion
              onClose={openCloseCreateQuestionModel}
              getAvailableQuestions={getAvailableQuestions}
              //   getAssignmentDetails={getAssignmentDetails}
              assignmentId={state.assignment_id}
            />


          ) : null} */}

          {/* {stateNext === 2 ? (
            <CreateQuestion assignmentId={state.assignment_id} />
          ) : null} */}
        </>
      )}
    </>
  );
}
