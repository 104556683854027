import React, { useState, useEffect } from "react";
import ApiConfig from "../../../../api/ApiConfig";
import { simplePostCall, multipartPostCall } from "../../../../api/ApiServices";
import "../../../../assets/css/select2.css";
import Params from "../../../../config/Params";
import SpinnerCmp from "../../../../components/SpinnerCmp";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CreateQuestionModal = (props) => {
  const { t } = useTranslation();

  const [createQuestionState, setCreateQuestionState] = useState({
    isloading: false,
    task_title: "",
    task_description: "",
    question_type: 0,
    question_content: "",
    question_file: "",
    content_file_name: "",
    content_file_url: "",
    question_grade: "",
    question_subject: "",
    question_lesson: "",
  });
  const [state, setState] = useState({
    isloading: false,
    class_details: [],
    subject_details: [],
    lesson_details: [],
  });

  function getDataForCreateQuestion() {
    setState({ ...state, isloading: true });

    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      assignment_id: props.assignmentId,
    });
    simplePostCall(
      ApiConfig.DATA_FOR_CREATING_ASSIGNMENT_QUESTION_API,
      newRequestBody
    )
      .then((data) => {
        if (data.result) {
          setState({
            ...state,
            isloading: false,
            class_details: data.data.class_details,
            lesson_details: data.data.lesson_details,
            subject_details: data.data.subject_details,
          });

          setCreateQuestionState({
            ...createQuestionState,
            question_grade: data.data.class_details.class_division_id,
            question_subject: data.data.subject_details.subject_id,
            question_lesson:
              data.data.lesson_details.length > 0
                ? data.data.lesson_details[0].lessons_id
                : "",
          });
        } else {
          setState({ ...state, isloading: false });
        }
      })
      .catch((error) => {
        setState({ ...state, isloading: false });
        console.log("api response", error);
      });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setCreateQuestionState({
        ...createQuestionState,
        question_file: file,
        content_file_name: file.name,
        content_file_url: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  function createQuestion() {
    setCreateQuestionState({ ...createQuestionState, isloading: true });

    var formData = new FormData();
    formData.append("customer_id", Params.customer_id);
    formData.append("api_key", Params.api_key);
    formData.append("teacher_id", Params.teacher_id);
    formData.append("title", createQuestionState.task_title);
    formData.append("description", createQuestionState.task_description);
    formData.append(
      "question_type",
      createQuestionState.question_type == 1 ? "upload" : "text"
    );
    formData.append(
      "content",
      createQuestionState.question_type == 0
        ? createQuestionState.question_content
        : createQuestionState.question_file
    );
    formData.append("class_division_id", createQuestionState.question_grade);
    formData.append("subject", createQuestionState.question_subject);
    formData.append("lesson_id", createQuestionState.question_lesson);
    formData.append("assignment_id", props.assignmentId);

    multipartPostCall(ApiConfig.CREATE_ASSIGNMENT_QUESTION_API, formData)
      .then((data) => {
        if (data.result) {
          setCreateQuestionState({ ...createQuestionState, isloading: false });
          callFunctionsAfterQuestionCreation();
        } else {
          setCreateQuestionState({ ...createQuestionState, isloading: false });
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setCreateQuestionState({ ...createQuestionState, isloading: false });
        console.log("api response", error);
      });
  }

  function callFunctionsAfterQuestionCreation() {
    props.onClose();
    props.getAvailableQuestions();
    props.getAssignmentDetails();
  }

  useEffect(() => {
    getDataForCreateQuestion();
  }, []);
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={{ display: "block" }}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {createQuestionState.isloading || state.isloading ? (
        <SpinnerCmp
          loading={createQuestionState.isloading || state.isloading}
        />
      ) : (
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Create Question")}
              </h5>
              <button
                className="btn-close"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
              </button>
            </div>
            <div className="modal-body datetime-picker modal-scroll">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Task Title")}</label>
                    <input
                      className="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                      value={createQuestionState.task_title}
                      name="title"
                      onChange={(e) => {
                        setCreateQuestionState({
                          ...createQuestionState,
                          task_title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="sub-task">{t("Task Description")}</label>
                    <textarea
                      className="form-control"
                      required=""
                      autocomplete="off"
                      value={createQuestionState.task_description}
                      name="description"
                      onChange={(e) => {
                        setCreateQuestionState({
                          ...createQuestionState,
                          task_description: e.target.value,
                        });
                      }}
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">
                      {t("How you going to add task details")} ?
                    </label>
                    <select
                      className="js-example-basic-single form-select"
                      value={createQuestionState.question_type}
                      onChange={(e) => {
                        setCreateQuestionState({
                          ...createQuestionState,
                          question_type: e.target.value,
                        });
                      }}
                    >
                      <option
                        value="0"
                        selected={
                          createQuestionState.question_type == 0 ? true : false
                        }
                      >
                        {t("Type task details")}
                      </option>
                      <option
                        value="1"
                        selected={
                          createQuestionState.question_type == 1 ? true : false
                        }
                      >
                        {t("Upload task details")}
                      </option>
                    </select>
                  </div>

                  {createQuestionState.question_type == 0 ? (
                    <div className="form-group col-md-12">
                      <label for="sub-task">{t("Task Content")}</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={createQuestionState.question_content}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setCreateQuestionState({
                            ...createQuestionState,
                            question_content: data,
                          });
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                        style={{ height: "300px" }}
                      />
                    </div>
                  ) : (
                    <div className="form-group col-md-12">
                      <label for="sub-task">{t("Question Content")}</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </div>
                  )}

                  <div className="row">
                    <div className="form-group col">
                      <label for="task-title">{t("Grade")}</label>
                      <select
                        className="js-example-basic-single form-select"
                        name="class_division"
                        value={createQuestionState.question_grade}
                        onChange={(e) => {
                          setCreateQuestionState({
                            ...createQuestionState,
                            question_grade: e.target.value,
                          });
                        }}
                      >
                        {state.class_details ? (
                          <option
                            value={state.class_details.class_division_id}
                            selected={
                              createQuestionState.question_grade ==
                              state.class_details.class_division_id
                                ? true
                                : false
                            }
                          >
                            {state.class_details.class_name}
                          </option>
                        ) : null}
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Subject")}</label>
                      <select
                        //   className="jsstate-example-basic-single form-select"
                        className="form-select"
                        name="subject"
                        value={createQuestionState.question_subject}
                        onChange={(e) => {
                          setCreateQuestionState({
                            ...createQuestionState,
                            question_subject: e.target.value,
                          });
                        }}
                      >
                        {state.subject_details ? (
                          <option
                            value={state.subject_details.subject_id}
                            selected={
                              createQuestionState.question_subject ==
                              state.subject_details.subject_id
                                ? true
                                : false
                            }
                          >
                            {state.subject_details.subject_name}
                          </option>
                        ) : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="task-title">{t("Task Lesson")}</label>
                    <select
                      className="js-example-basic-single form-select"
                      name="lesson_id"
                      value={createQuestionState.question_lesson}
                      onChange={(e) => {
                        setCreateQuestionState({
                          ...createQuestionState,
                          question_lesson: e.target.value,
                        });
                      }}
                    >
                      {state.lesson_details.length > 0
                        ? state.lesson_details.map((lesson, index) => {
                            return (
                              <option
                                value={lesson.lessons_id}
                                selected={
                                  createQuestionState.question_lesson ==
                                  lesson.lessons_id
                                    ? true
                                    : false
                                }
                              >
                                {lesson.lessons_name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={props.onClose}
                type="button"
                data-bs-dismiss="modal"
              >
                {t("Cancel")}
              </button>
              <button
                onclick="location.href='add-questions.html';"
                className="btn btn-primary"
                id="Bookmark"
                type="button"
                onClick={createQuestion}
              >
                {t("Create Question")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CreateQuestionModal;
