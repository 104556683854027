import React, { useEffect, useState } from "react";
import like_btn from "../../assets/images/like_btn.svg";
import part_4 from "../../assets/images/part_4.svg";
import com_send_btn from "../../assets/images/com_send_btn.svg";
import { Link, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Params from "../../config/Params";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { Socket, io } from "socket.io-client";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { TabPanel, TabView } from "primereact/tabview";
import Reply from "../../assets/images/Reply.png";
import moment from "moment";
import { useTranslation } from "react-i18next";

function NoteView() {
  //---------state management----------//
  const [isShown, setIsShown] = useState([]);
  const [currentReplyIndex, setCurrentReplyIndex] = useState(0);
  const [status, setStatus] = useState(0);
  const [diary_allow, setDiary_allow] = useState("");
  const [text, setText] = useState("");
  const [diary_attach, setDiary_attach] = useState([]);
  const [showreply, setShowReply] = useState(false);
  const [show, setShow] = useState(false);
  const [activ, setActiv] = useState(false);
  const [viewdata, setViewdata] = useState([]);
  const [diary_comments, setDiary_comments] = useState([]);
  const [reviewer, setReviewer] = useState([]);
  const [total, setTotal] = useState("");
  const [viewDiary, setViewdiary] = useState("");
  const [unreaded, setUnreaded] = useState([]);
  const [seen, setSeen] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [replyUser, setreplyUser] = useState("");
  const [diaryCount, setDiaryCount] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleshow = () => setShowReply(!showreply);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  //-------useEffect-----//
  useEffect(() => {
    diaryView();
    Diary_comments();
  }, []);
  useEffect(() => {
    ParentsApi();
  }, [status]);
  useEffect(() => {
    const handleFocuss = async () => {
      const socket = io(
        ApiConfig.NODE_URL +
          "/?customerId=" +
          Params.customer_id +
          "&userId=" +
          Params.teacher_id +
          "&diary_id=" +
          getData +
          "&user_type=student",
      );
      // setSocket(socket);
      // Listen to the 'connect' event
      socket.on("connect", () => {
        console.log("Socket connected successfully diary");
      });

      socket.on("comment_get", (value) => {
        let user_type = socket.handshake.query.user_type;
        // console.log("usertype for commewnt", user_type);
        // console.log("commentedd", value.data);
        if (value?.result) {
          // console.log("message", value.data.comment_parent_comment_id);
          if (
            value?.data.comment_parent_comment_id === null ||
            value?.data.comment_parent_comment_id === 0
          ) {
            setDiary_comments((prevData) => [value.data, ...prevData]);
            // console.log("changed in socket", value.data);
          } else {
            if (
              value?.data.comment_main === null ||
              value?.data.comment_main === undefined
            ) {
              setDiary_comments((prevData) => {
                const dataArray = [...prevData];
                const parentComment = dataArray?.find(
                  (comment) =>
                    comment?.comment_id ===
                    value?.data.comment_parrent_comment_id,
                );
                if (parentComment) {
                  parentComment.comment_comments.unshift(value.data);
                }
                return dataArray;
              });
            } else {
              setDiary_comments((prevData) => {
                const dataArray = [...prevData];
                const parentComment = dataArray.find(
                  (comment) => comment?.comment_id === value?.data.comment_main,
                );
                if (parentComment) {
                  if (!parentComment.comment_comments) {
                    parentComment.comment_comments = [];
                  }
                  const specificComment = parentComment.comment_comments.find(
                    (comment) =>
                      comment?.comment_id ===
                      value?.data.comment_parrent_comment_id,
                  );
                  if (specificComment) {
                    if (!specificComment.comment_comments) {
                      specificComment.comment_comments = [];
                    }
                    specificComment.comment_comments.unshift(value?.data);
                  }
                }
                // console.log("dataf", dataArray);
                return dataArray;
              });
            }
          }
        }
      });
      // Emit the 'diaryRoom' event
      socket.emit("rooms", {});
    };
    handleFocuss();
  }, []);

  //---------------Api functions------------//
  const diaryView = () => {
    try {
      axios(ApiConfig.DIARY_VIEW, {
        method: "GET",
        params: {
          teacher_id: Params.teacher_id,
          diary_id: getData,
        },
        headers: {
          Accept: "application/json",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      })
        .then((data) => {
          if (data.data.result) {
            setViewdiary(data?.data?.data?.diary_seen_by);
            setDiary_allow(data?.data?.data.diary_allow_comment);
            // ParseData(data.data.data);
            setViewdata(data?.data?.data);
            setIsShown(data.data?.data?.diary_comments[0]);
            setDiary_attach(data?.data?.data.diary_attach);
            setDiaryCount(data?.data?.data?.diary_like_count);
          } else {
            console.log("NO DATA in view");
          }
        })
        .catch((err) => {
          console.log("cath error", err.response?.data);
        });
    } catch (err) {
      console.log("Planned TripListApi Error: ", err);
      throw err;
    }
  };
  const Diary_comments = () => {
    axios(ApiConfig.DIARY_VIEW, {
      method: "GET",
      params: {
        diary_id: getData,
        teacher_id: Params.teacher_id,
        page_no: 0,
        // page_limit: 6,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((data) => {
        if (data?.data?.result) {
          ParseData(data?.data?.data);
        }
      })
      .catch((err) => {
        // setisLoading(false);
        console.log("home error3: ", err);
      });
  };
  const ParseData = (data) => {
    let temp = [...diary_comments];

    for (let i = 0; i < data.diary_comments?.length; i++) {
      let comment_diary_id = data.diary_comments[i]?.comment_diary_id;
      let comment_id = data.diary_comments[i]?.comment_id;
      let comment_text = data.diary_comments[i]?.comment_text;
      let comment_day = data.diary_comments[i]?.comment_day;
      let comment_times = data.diary_comments[i]?.comment_times;
      let user_name = data.diary_comments[i]?.user_name;
      let viewChange = false;
      let user_profile_pic = data.diary_comments[i]?.user_profile_pic;
      let text = "";
      let children = [];
      for (let j = 0; j < data.diary_comments[i].children?.length; j++) {
        let comment_id = data.diary_comments[i].children[j].comment_id;
        let comment_diary_id =
          data.diary_comments[i].children[j].comment_diary_id;
        let comment_parent_comment_id =
          data.diary_comments[i].children[j].comment_parent_comment_id;
        let comment_text = data.diary_comments[i].children[j].comment_text;
        let comment_day = data.diary_comments[i].children[j].comment_day;
        let comment_times = data.diary_comments[i].children[j].comment_times;
        let user_profile_pic =
          data.diary_comments[i].children[j].user_profile_pic;
        let user_name = data.diary_comments[i].children[j].user_name;

        let viewChanges = false;
        let childrenSecond = [];
        for (
          let k = 0;
          k < data.diary_comments[i].children[j].children?.length;
          k++
        ) {
          let comment_id =
            data.diary_comments[i].children[j].children[k].comment_id;
          let comment_diary_id =
            data.diary_comments[i].children[j].children[k].comment_diary_id;
          let comment_parent_comment_id =
            data.diary_comments[i].children[j].children[k]
              .comment_parent_comment_id;
          let comment_text =
            data.diary_comments[i].children[j].children[k].comment_text;
          let comment_day =
            data.diary_comments[i].children[j].children[k].comment_day;
          let comment_times =
            data.diary_comments[i].children[j].children[k].comment_times;
          let user_profile_pic =
            data.diary_comments[i].children[j].children[k].user_profile_pic;
          let user_name =
            data.diary_comments[i].children[j].children[k].user_name;

          childrenSecond.push({
            comment_diary_id,
            comment_id,
            comment_parent_comment_id,
            comment_text,
            comment_day,
            comment_times,
            user_profile_pic,
            user_name,
          });
        }

        children.push({
          comment_diary_id,
          comment_id,
          comment_parent_comment_id,
          comment_text,
          comment_day,
          comment_times,
          user_profile_pic,
          viewChanges,
          childrenSecond,
          user_name,
        });
        // console.log("length", children);
      }

      temp.push({
        comment_id,
        comment_diary_id,
        user_name,
        comment_text,
        comment_day,
        comment_times,
        children,
        viewChange,
        user_profile_pic,
        text,
      });
      // console.log("temp", temp);
    }
    setDiary_comments(temp);
  };
  const ParentsApi = () => {
    axios(ApiConfig.DIARY_REVIEW, {
      method: "GET",
      params: {
        diary_id: getData,
        filter: status == 0 ? "student" : "parent",
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((data) => {
        setReviewer(data.data.read_diary);
        setUnreaded(data.data.unread_diary);
        setTotal(data.data.parent_count + data.data.student_count);
        setSeen(data.data.unreaded_count);
        // console.log(data.data, "datainsisde diary review");
      })
      .catch((err) => {
        console.log("home error: ", err);
      });
  };
  //---------------------------Diary-comments-create-----------------------//

  const Diary_comments_create = async () => {
    const requestBody = {
      diary_id: getData,
      diary_comment: inputValue,
      diary_user_id: Params.teacher_id,
      // diary_replay_to:"285"
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };

    axios
      .post(ApiConfig.DIARY_COMMENT_CREATE, requestBody, { headers })
      .then((response) => {
        // console.log("check", response.data.result);
        if (response.data.result) {
          diary_comments.length = 0;
          setInputValue("");
          Diary_comments();
        }
        // setisLoading(false);
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error4: ", error);
      });
  };
  //---------------------------Commnets_reply-----------------------//

  const Commnets_reply = async (comment_id) => {
    const requestBody = {
      diary_id: getData,
      diary_comment: text,
      diary_user_id: Params.teacher_id,
      diary_replay_to: comment_id,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };

    axios
      .post(ApiConfig.DIARY_COMMENT_CREATE, requestBody, { headers })
      .then((data) => {
        if (data) diary_comments.length = 0;
        Diary_comments();
        // setisLoading(false);
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error5: ", error);
      });
  };
  const handleReply = () => {
    setText("");
    Commnets_reply(currentReplyIndex);
    Diary_comments();
  };
  const handleButtonPress = () => {
    setInputValue("");
    Diary_comments_create();
    // Diary_comments();
  };

  const handleClick = (id, index, user_name) => {
    console.log(id, index, user_name, "index indiseascacna");
    setActiv(!activ);
    const newComments = [...diary_comments];
    newComments[index].viewChange = !newComments[index].viewChange;
    setDiary_comments(newComments);
    setreplyUser(user_name);
    setCurrentReplyIndex(id);
  };
  const handleClicksecond = (id, ind, index, user_name) => {
    const newComments = [...diary_comments];
    newComments[index].children[ind].viewChanges =
      !newComments[index].children[ind].viewChanges;
    setDiary_comments(newComments);
    setActiv(true);
    setCurrentReplyIndex(id);
  };
  const currentImage = diary_attach[currentImageIndex];
  const onNext = () => {
    if (currentImageIndex < diary_attach?.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  const onPrev = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const location = useLocation();
  const getData = location.state;
  const base_url = "https://app.edsys.in/";
  const LastTackedTime = (commentDateTime) => {
    const currentTime = moment();
    const commentDate = moment(commentDateTime, "YYYY-MM-DD");
    const yesterday = moment().subtract(1, "day").startOf("day");

    if (commentDate.isSame(currentTime, "day")) {
      // Comment sent today
      const formattedTime = moment(
        commentDateTime,
        "YYYY-MM-DD HH:mm:ss",
      ).format("hh:mm A");
      return formattedTime;
    } else if (commentDate.isSame(yesterday, "day")) {
      // Comment sent yesterday
      const formattedTime = moment(
        commentDateTime,
        "YYYY-MM-DD HH:mm:ss",
      ).format("hh:mm A");
      return "Yesterday, " + formattedTime;
    } else {
      // Comment sent before yesterday
      const formattedDateTime = moment(
        commentDateTime,
        "YYYY-MM-DD HH:mm:ss",
      ).format("DD MMM YYYY, hh:mm A");
      return formattedDateTime;
    }
  };
  const isPrevDisabled = currentImageIndex === 0;
  const isNextDisabled = currentImageIndex === diary_attach?.length - 1;
  const handleKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <>
      <div class="Note_view announcementnew">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label className="note-view-title">
                  {viewdata?.diary_title}
                </label>
                {/* <img src={filter_icon} alt="" /> */}
              </div>
              <div className="class-text-wrapper">
                <div className="ct-title">
                  <p className="title"></p>
                </div>

                <div className="comment-like-bt">
                  <span>
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "red",
                        textAlign: "center",
                        borderRadius: "100%",
                        height: "17px",
                        width: "17px",
                      }}
                    >
                      {diaryCount}
                    </div>
                    <img src={like_btn} alt="" />
                  </span>
                </div>
              </div>
              <div className="test-desc">{viewdata?.diary_message}</div>

              <div className="stats-wrap">
                <p className="date-time">{viewdata?.diary_date}</p>
                <p className="views" onClick={handleShow}>
                {t("Seen by")} {viewdata?.diary_seen_by}/{total}
                </p>
              </div>
              {diary_attach == "" || diary_attach == undefined ? (
                <div />
              ) : (
                <div
                  style={{
                    width: "100%",
                    // height: "700px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div className="colorbutton">
                    <div
                      className={`colorbutton1 ${
                        isPrevDisabled ? "disabled" : ""
                      }`}
                      onClick={!isPrevDisabled ? onPrev : null}
                      style={{
                        pointerEvents: isPrevDisabled ? "none" : "auto",
                      }}
                    >
                      <FaArrowLeftLong />
                    </div>

                    <div
                      className={`colorbutton2 ${
                        isNextDisabled ? "disabled" : ""
                      }`}
                      onClick={!isNextDisabled ? onNext : null}
                      style={{
                        pointerEvents: isNextDisabled ? "none" : "auto",
                      }}
                    >
                      <FaArrowRightLong />
                    </div>
                  </div>
                  {currentImage.attachment_file.split(".").pop() === "pdf" ? (
                    <div className="ramanan">
                      <iframe
                        width="1060"
                        height="815"
                        className="responsive-iframe"
                        src={ApiConfig.NODE_URL + currentImage?.attachment_file}
                      />
                    </div>
                  ) : currentImage?.attachment_file.split(".").pop() ===
                    "ppt" ? (
                    <div className="ramanan">
                      <iframe
                        width="560"
                        height="315"
                        className="responsive-iframe"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                          ApiConfig.NODE_URL + currentImage?.attachment_file
                        }`}
                      />
                    </div>
                  ) : currentImage?.attachment_file.split(".").pop() ===
                    "jpg" ? (
                    <div className="ramanan">
                      <img
                        width="560"
                        height="560"
                        className="responsive-iframe"
                        src={ApiConfig.NODE_URL + currentImage?.attachment_file}
                      />
                    </div>
                  ) : currentImage ? (
                    <div className="ramanan">
                      <iframe
                        width="1400"
                        height="700"
                        className="responsive-iframe"
                        src={ApiConfig.NODE_URL + currentImage?.attachment_file}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
        {viewdata?.diary_allow_comment === "true" ? (
          <div className="main-section">
            <div className="comments-wrapper">
              <p className="title">{t("Comments")}</p>
            </div>
            <div className="main-section">
              {diary_comments?.length > 0
                ? diary_comments?.map((item, index) => {
                    const result = LastTackedTime(
                      item.comment_day + " " + item.comment_times,
                    );
                    return (
                      <div key={index}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex" }}>
                            <div>
                              <img
                                style={{
                                  height: "35px",
                                  width: "35px",
                                  borderRadius: "100px",
                                  marginRight: "10px",
                                  // add some space between the image and the text
                                }}
                                src={ApiConfig.NODE_URL + item.user_profile_pic}
                                onError={(e) => {
                                  e.target.src = dashboardAvatar;
                                }}
                              />
                            </div>
                            <div>
                              <p style={{ margin: 0 }}>{item.user_name}</p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "45px",
                            }}
                          >
                            <div>{item.comment_text}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "45px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              onClick={() =>
                                handleClick(
                                  item.comment_id,
                                  index,
                                  item.user_name,
                                )
                              }
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  marginRight: "5px",
                                }}
                                src={Reply}
                              />
                              <p>{t("Reply")}</p>
                            </div>
                            <div>{result}</div>
                          </div>
                        </div>
                        <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                          {diary_comments[index].children?.map(
                            (second, ind) => (
                              <div key={ind}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <img
                                        style={{
                                          height: "35px",
                                          width: "35px",
                                          borderRadius: "100px",
                                          marginRight: "10px",
                                          // add some space between the image and the text
                                        }}
                                        src={
                                          ApiConfig.NODE_URL +
                                          second?.user_profile_pic
                                        }
                                        onError={(e) => {
                                          e.target.src = dashboardAvatar;
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <p style={{ margin: 0 }}>
                                        {second?.user_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "45px",
                                    }}
                                  >
                                    <div>{second?.comment_text}</div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "45px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        handleClicksecond(
                                          second.comment_id,
                                          ind,
                                          index,
                                          second.user_name,
                                        )
                                      }
                                    >
                                      <img
                                        style={{
                                          height: "15px",
                                          width: "15px",
                                          marginRight: "5px",
                                        }}
                                        src={Reply}
                                      />
                                      <p>{t("Reply")}</p>
                                    </div>
                                    <div>
                                      {LastTackedTime(
                                        second.comment_day +
                                          " " +
                                          second.comment_times,
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {diary_comments[index].children[
                                    ind
                                  ].childrenSecond?.map((third, inde) => (
                                    <div key={inde}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <div>
                                            <img
                                              style={{
                                                height: "35px",
                                                width: "35px",
                                                borderRadius: "100px",
                                                marginRight: "10px",
                                                // add some space between the image and the text
                                              }}
                                              src={
                                                ApiConfig.NODE_URL +
                                                third?.user_profile_pic
                                              }
                                              onError={(e) => {
                                                e.target.src = dashboardAvatar;
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <p style={{ margin: 0 }}>
                                              {third?.user_name}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "45px",
                                          }}
                                        >
                                          <div>{third?.comment_text}</div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "45px",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div />

                                          <div>
                                            {LastTackedTime(
                                              third.comment_day +
                                                " " +
                                                third.comment_times,
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : (
          <div />
        )}
        {diary_allow == "true" ? (
          activ == false ? (
            <div className="main-comment-input-wrapper">
              <Form>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Send Message..."
                    style={{ width: "92%" }}
                    value={inputValue}
                    onKeyPress={handleKey}
                    onChange={(e) => setInputValue(e.target.value)}
                  />

                  <img src={com_send_btn} alt="" onClick={handleButtonPress} />
                </Form.Group>
              </Form>
            </div>
          ) : (
            <div className="main-comment-input-wrapper">
              <Form>
                <Form.Group className="" controlId="formBasicEmail">
                  <div
                    style={{
                      // backgroundColor: "red",
                      display: "flex",
                      justifyContent: "row",
                      alignItems: "center",
                    }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Send Repiles..."
                      style={{ width: "85%" }}
                      value={text}
                      onKeyPress={handleKey}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <div
                      onClick={() => {
                        setActiv(false);
                      }}
                    >
                     {t("Cancel")} 
                    </div>
                  </div>
                  <img src={com_send_btn} alt="" onClick={handleReply} />
                </Form.Group>
              </Form>
            </div>
          )
        ) : null}
      </div>

      {/* Sight Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <React.Fragment>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="title-wrapper">
                <p>{t("Sight")}</p>
                <p>
                  {viewDiary}/{total}
                </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <div className="Forum library">
            <div className="books-main-wrapper">
              <TabView
                activeIndex={status}
                onTabChange={(e) => [
                  setUnreaded([]),
                  setReviewer([]),
                  setStatus(e.index),
                ]}
              >
                <TabPanel header="Student">
                  <Modal.Body>
                    <div className="title">
                      <p>{t("Seen By")}</p>
                      <p>{viewDiary}</p>
                    </div>
                    {reviewer?.length > 0 ? (
                      reviewer.map((review, index) => (
                        <div className="list-wrapper">
                          <div className="list-item" key={index}>
                            <img
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "100px",
                              }}
                              src={
                                ApiConfig.NODE_URL +
                                review?.recepient_profile_pic
                              }
                              onError={(e) => {
                                e.target.src = dashboardAvatar;
                              }}
                            />
                            <div className="text-box">
                              <p className="main">{review?.recepient_name}</p>
                              <p className="sub">
                                {review.recepient_viewed_time
                                  ? review.recepient_viewed_time
                                  : ""}
                              </p>
                              <div className="sub">
                                {review?.sudent_detail?.map((student) => (
                                  <p>{student.student_name}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div />
                    )}

                    <div className="title mt-2">
                      <p>{t("Remaining")}</p>
                      <p>{seen}</p>
                    </div>
                    <div className="list-wrapper">
                      {unreaded?.length > 0 ? (
                        unreaded.map((unread, index) => (
                          <div className="list-item" key={index}>
                            <img
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "100px",
                              }}
                              src={
                                ApiConfig.NODE_URL +
                                unread?.recepient_profile_pic
                              }
                              onError={(e) => {
                                e.target.src = dashboardAvatar;
                              }}
                            />
                            <div className="text-box">
                              <p className="main">{unread?.recepient_name}</p>
                              <p className="sub">
                                {unread.recepient_viewed_time
                                  ? unread.recepient_viewed_time
                                  : ""}
                              </p>
                              <div className="sub">
                                {unread?.sudent_detail?.map((student) => (
                                  <p>{student.student_name}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div />
                      )}
                    </div>
                  </Modal.Body>
                </TabPanel>
                <TabPanel header="Parent">
                  <Modal.Body>
                    <div className="title">
                      <p>{t("Seen By")}</p>
                      <p>{viewDiary}</p>
                    </div>
                    {reviewer?.length > 0 ? (
                      reviewer.map((review, index) => (
                        <div className="list-wrapper">
                          <div className="list-item" key={index}>
                            <img
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "100px",
                              }}
                              src={
                                ApiConfig.NODE_URL +
                                review?.recepient_profile_pic
                              }
                              onError={(e) => {
                                e.target.src = dashboardAvatar;
                              }}
                            />
                            <div className="text-box">
                              <p className="main">
                                {review?.recepient_name} ({review?.parent_type})
                              </p>
                              <p className="sub">
                                {review.recepient_viewed_time
                                  ? review.recepient_viewed_time
                                  : ""}
                              </p>
                              <div className="sub">
                                {review?.sudent_detail?.map((student) => (
                                  <p>{student.student_name}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div />
                    )}

                    <div className="title mt-2">
                      <p>{t("Remaining")}</p>
                      <p>{seen}</p>
                    </div>
                    <div className="list-wrapper">
                      {unreaded?.length > 0 ? (
                        unreaded.map((unread, index) => (
                          <div className="list-item" key={index}>
                            <img
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "100px",
                              }}
                              src={
                                ApiConfig.NODE_URL +
                                unread?.recepient_profile_pic
                              }
                              onError={(e) => {
                                e.target.src = dashboardAvatar;
                              }}
                            />
                            <div className="text-box">
                              <p className="main">
                                {unread?.recepient_name} ({unread?.parent_type})
                              </p>
                              <p className="sub">
                                {unread.recepient_viewed_time
                                  ? unread.recepient_viewed_time
                                  : ""}
                              </p>
                              <div className="sub">
                                {unread?.sudent_detail?.map((student) => (
                                  <p>{student.student_name}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div />
                      )}
                    </div>
                  </Modal.Body>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </React.Fragment>
      </Modal>

      {/* Sight Modal End */}
    </>
  );
}
export default NoteView;
