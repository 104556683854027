import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import FileSaver from "file-saver";
import Params from "../../config/Params";
function ViewGradeDivision() {
  const [gradedivision, setGradeDivision] = useState("");
  const [showDataApi, setShowDataApi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAfterDelete, setShowAfterDelete] = useState("");
  const [showId, setShowId] = useState("");
  const clearFields = () => {
    setGradeDivision("");
  };

  const deleteid = (id) => {
    setShowId(id);
  };

  const deletedata = (id) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      division_id: id,
    });
    simplePostCall(ApiConfig.CLASS_DIVISION_DELETE, requestBody)
      .then((data) => {
        setShowAfterDelete(data);
        console.log(data, "deletesuccess");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handlePageChange = () => {
    if (nextPage != false) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (nextPage !== false) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    classdivisionlist();
  }, [currentPage, showAfterDelete]);
  const classdivisionlist = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: "",
      division_name: "",
      page: Number(currentPage),
      limit: 20,
    });
    simplePostCall(ApiConfig.CLASS_DIVISION_LIST, RequestBody)
      .then((data) => {
        setShowDataApi(data?.data);
        setNextPage(data?.next_page);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const studentexcel = (studown) => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      format: studown,
    });
    simplePostCall(ApiConfig.CLASS_DIVISION_EXPORT, RequestBody)
      .then((data) => {
        if (data) {
          FileSaver.saveAs(data?.file_path);
        } else {
          console.log("not working");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Grade Divisions</h5>
            <div className="tablebuttonview">
              <a
                href="/CreateGradeDivision"
                type="button"
                className="btn timetablebutton "
              >
                <span>Create Grade Division</span>
              </a>
              <a
                href="/ImportSubjects"
                type="button"
                className="btn timetablebutton"
              >
                <span>Import</span>
              </a>
              <a
                className="btn timetablebutton dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Export
              </a>

              <span class="visually-hidden">Toggle Dropdown</span>

              <ul class="dropdown-menu">
                <li
                  onClick={() => {
                    studentexcel("pdf");
                  }}
                >
                  <a class="dropdown-item  datasize" href="#">
                    Save as PDF
                  </a>
                </li>
                <li
                  onClick={() => {
                    studentexcel("excel");
                  }}
                >
                  <a class="dropdown-item datasize" href="#">
                    Export to Excel
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Division Name</th>
                    <th scope="col">Number Of Students</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={gradedivision}
                        onChange={(e) => setGradeDivision(e.target.value)}
                      ></input>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                          Clear
                        </button>
                      </div>
                    </th>
                  </tr>
                  {showDataApi.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          {" "}
                          {index + 1 + 20 * (currentPage - 1)}
                        </th>
                        <td>{item.class_name}</td>
                        <td>{item.class_division_name}</td>
                        <td>{item.number_of_students}</td>
                        <td></td>

                        <td style={{ alignItems: "center" }}>
                          <div className="buttons">
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Link to="/EditGradeDivision">
                                <div className="view">
                                  <AiTwotoneEdit className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Link to="">
                                <div
                                  className="view"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    deleteid(item.class_division_id);
                                  }}
                                >
                                  <RiDeleteBin6Fill className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="showingnumber">
                <div></div>
                <nav aria-label="Pagination">
                  <ul className="pagination">
                    <li
                      className={`page - item${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        &laquo;
                      </button>
                    </li>
                    <button
                      className="page-link"
                      style={{ backgroundColor: "#adc927" }}
                      onClick={() => handlePageChange()}
                    >
                      {currentPage}
                    </button>

                    <li
                      className={`page-item ${
                        currentPage === currentPage - 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handleNextPage}
                        disabled={currentPage === currentPage - 1}
                        aria-label="next"
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal Start */}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        dialogClassName=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="dlt-btn-1"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(false);
                deletedata(showId);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal End */}
    </>
  );
}

export default ViewGradeDivision;
