import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

function ImportSubjects() {
  const [showclose, setShowClose] = useState();
  const { t } = useTranslation();
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="d-flex">
          <h5 className="mb-2 flex-grow-1">{t("Import Subjects")}</h5>
        </div>
        <div className="card">
          <div className="card-header">
            {showclose ? (
              <div className="div_Succesfull">
                <label>{t("Imported Successfully")}</label>
                <IoClose onClick={() => setShowClose(!showclose)} />
              </div>
            ) : null}

            <div className="form-group col-md-6">
              <label for="task-title">{t("Upload File")}</label>
              <input
                className="datepicker-here form-control digits"
                type="file"
              />
            </div>
            <div className="schedulebutton">
              <div className="">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setShowClose(true)}
                >
                 {t("Import")} 
                </button>
              </div>
              <div className="">
                <button className="btn btn-primary" type="button">
                {t("Download Import Format")} 
                </button>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <label className="Instruction_label">
              {t("Please follow the instruction before uploading the file")} .
              </label>
              <ul className="Instruction_list">
                <li>  {t("Uploading file should be a xls file")}</li>
                <li>  {t("Date format is Year-Month-Date for example 2014-12-20")}.</li>
                <li>
                {t("Successive rows should be filled out from first row onwords")} .
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportSubjects;
