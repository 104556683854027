import React, { useEffect, useState, useContext } from "react";
import No_Attends_List from "module";
import RankList from "./RankList";

import { Clock } from "react-feather";
import { Link, useParams } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import AssignmentDetailsSkeleton from "../../../components/skeletons/AssignmentDetailsSkeleton";

function AssessmentDetails() {
  const { t } = useTranslation();

  const {
    setAttended_list,
    loading,
    setLoading,
    seAssessmentId,
    setState,
    setPandinge,
  } = useContext(AppContext);

  const { id } = useParams();
  // console.log('route id', id);
  const [assessment, setAssessment] = useState({});

  useEffect(() => {
    document.title = "Edsys | Assessment Details";
    getAssessments();
  }, [id]);

  function getAssessments() {
    setLoading(true);

    simplePostCall(
      ApiConfig.ASSESSMENT_DETAILS_API,
      JSON.stringify({ ...Params, assessment_id: id })
    )
      .then((data) => {
        // console.log(data);
        setLoading(false);
        // if (data.result) {
        setAssessment(data.assessment_details);
        seAssessmentId(data.assessment_details.assessment_id);
        setPandinge(data.pending_list);
        setAttended_list(data.attended_list);
        setState(0);
        // }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  //format date
  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }
  const dateString = "2024-10-03";
  const formattedDate = formatDate(dateString);
  console.log(formattedDate, "formattedDate");
  return (
    <>
      {/* <!-- Container-fluid starts--> */}
      {/* {!isloading ? (
        <SpinnerCmp loading={isloading} />
      ) : ( */}
      {loading ? (
        <AssignmentDetailsSkeleton />
      ) : (
        <div class="row assessment-details">
          <div class="col-12">
            <div class="card br-15">
              <div class="card-body">
                <div class="knowledgebase-bg b-center bg-size banner-img"></div>
                <div class="knowledgebase-search">
                  <div class="details-banner">
                    {/* <h3>{state.assessment_details[0].assessment_title}</h3> */}
                    <h5>{assessment?.assessment_title}</h5>
                    <h6>
                      {t("Assigned by")}
                      <span>{assessment?.assessment_created_by}</span>
                    </h6>
                    <div class="d-flex">
                      <div
                        class="sub-date"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {formatDate(assessment?.assessment_created_date)}
                      </div>
                      <div
                        class="sub-time"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <i class="icofont icofont-clock-time"></i>{" "}
                        {assessment?.assessment_duration} {t("Mins")}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desc">
                  <h5>{t("Description")}</h5>
                  <p>{assessment?.assessment_description}</p>
                </div>
              </div>
              <div class="modal-footer">
                <Link
                  to="/No_Attends_List"
                  // assessment={{
                  //   data: pandinge ,
                  // }}
                  class="btn btn-secondary"
                  onclick="location.href='not-attended-list.html';"
                >
                  {t("Not Attended List")}
                </Link>
                <Link
                  to="/RankList"
                  class="btn btn-primary"
                  onclick="location.href='rank-list.html';"
                >
                  {t("Rank List")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- Container-fluid Ends--> */}
    </>
  );
}

export default AssessmentDetails;
