import React, { useState, useEffect } from "react";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function SyllabusProg() {
  const { t } = useTranslation();

  const [isTodoModelShow, setisTodoModelShow] = useState(false);
  const [isTagModelShow, setisTagModelShow] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [curriculumState, setCurriculumState] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [lessonId, setLessonId] = useState("");
  const [state, setState] = useState({
    subject: "",
    class_division_id: "",
    class_id: "",
    curriculum_id: "",
    curriculum_name: "",
  });
  const [activeLessonIndices, setActiveLessonIndices] = useState([]);
  const [activeSubIndices, setActiveSubIndices] = useState({});

  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
    loadClassList();
    // getCurriculumList();
  }, []);
  useEffect(() => {}, [subjectList]);
  const Showmodal = () => {
    setisTodoModelShow(true);
  };

  const openCloseTodoModel = () => {
    setisTodoModelShow(false);
  };
  const openCloseTagModel = () => {
    setisTodoModelShow(false);
  };

  function loadClassList() {
    simplePostCall(
      ApiConfig.CLASSES_LIST_API,
      JSON.stringify({ ...Params, user_id: Params.teacher_id })
    )
      .then((data) => {
        if (data.result) {
          setClassList(data.list);

          //   setState({ ...state, class_id: data.list[0].class_id })
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // function getCurriculumList() {
  //   let newRequestBody = JSON.stringify({
  //     api_key: Params.api_key,
  //     customer_id: Params.customer_id,
  //     user_id: Params.teacher_id,
  //   });

  //   simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
  //     .then((data) => {
  //       console.log(data, "YEAR data Lists");
  //       if (data.result) {
  //         var curriculums = [];
  //         if (data?.curriculum_list.length > 0) {
  //           data.curriculum_list.map((clases, index) => {
  //             console.log(clases, "curicullam");
  //             curriculums.push({
  //               label: clases.curriculum_name,
  //               value: clases.curriculum_id,
  //             });
  //           });
  //         }
  //         console.log(curriculums, "curriculums");
  //         setCurriculumState({ ...curriculumState, curriculums: curriculums });
  //         console.log(curriculumState, "AccYear");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("api response", error);
  //     });
  // }
  function loadSubjectList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.SUBJECT_IN_SYLLABUS, newRequestBody)
      .then((data) => {
        // setState({ ...state, subject: data.list[0].id });
        setSubjectList(data.list);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // const onInputChangeHandleSyllabus = (e) => {
  //   console.log(e, "insisde ciriculam selector");
  //   // const index = e.target.selectedIndex;
  //   setState({ ...state, curriculum_id: e.value, curriculum_name: e.label });
  //   // loadSubjectList(value);
  // };

  const onInputChangeHandle = (e) => {
    console.log(e.target, "classesss");
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const { name, value } = e.target;
    setState({ ...state, class_id: value });
    loadSubjectList(value);
  };
  const onInputChangeHandleLessons = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const { name, value } = e.target;
    setLessonId(option);

    if (name === "id") getLessons(state.class_id, value, option);
  };

  function getLessons(class_id = "", subject_id = "", lesson_id = "") {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      subject_id: subject_id,
      class_id: class_id,
      // lesson_id: lesson_id,
      teacher_id: Params.teacher_id,
    });
    console.log(ApiConfig.LESSONS_DETAIL_API, newRequestBody, "api calling");
    simplePostCall(ApiConfig.LESSONS_DETAIL_API, newRequestBody)
      .then((data) => {
        console.log(data, "testing");
        setLesson(data.list);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const handleLessonClick = (lessonIndex) => {
    setActiveLessonIndices((prevState) => {
      const newState = [...prevState];
      newState[lessonIndex] = !newState[lessonIndex];
      return newState;
    });
  };

  const handleTopicClick = (lessonIndex, topicIndex) => {
    setActiveSubIndices((prevState) => {
      const newState = { ...prevState };
      if (!newState[lessonIndex]) {
        newState[lessonIndex] = [];
      }
      newState[lessonIndex][topicIndex] = !newState[lessonIndex][topicIndex];
      return newState;
    });
  };
  return (
    <>
      <div className="row syllabus-sec">
        <div className="col-md-12">
          <div className="card br-15">
            <div className="card-body">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row row">
                  {/* <div className="form-group col-md-4">
                    <label htmlFor="task-title">{t("Select Syllabus")}</label>
                    <Select
                      class="js-example-basic-single form-select"
                      placeholder="Syllabus"
                      // value={state.curriculum_name}
                      onChange={onInputChangeHandleSyllabus}
                      options={curriculumState?.curriculums}
                    />
                  </div> */}
                  <div className="form-group col-md-4">
                    <label htmlFor="task-title">{t("Select Class")}</label>
                    <select
                      className="js-example-basic-single form-select"
                      name="class_division_id"
                      //   value={state.class_division_id}

                      onChange={onInputChangeHandle}
                    >
                      {classList && classList.length > 0
                        ? classList.map((obj, index) => (
                            <option
                              selected
                              key={"obj" + index}
                              value={obj.class_division_id}
                            >
                              {obj.class_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>

                  <div className="form-group col-md-4">
                    <label for="task-title">{t("Select Subject")}</label>
                    <select
                      className="js-example-basic-single form-select"
                      name="id"
                      //   value={state.subject}
                      onChange={onInputChangeHandleLessons}
                    >
                      {subjectList && subjectList.length > 0
                        ? subjectList.map((obj, index) => (
                            <option selected id={obj.lessons_id} value={obj.id}>
                              {obj.subject_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
              </form>

              {lesson && lesson.length > 0 ? (
                lesson[0]?.map((postDetail, lessonIndex) => (
                  <Accordion key={lessonIndex} defaultActiveKey="0">
                    {console.log(postDetail, "postDetail")}
                    <Accordion.Item eventKey={lessonIndex.toString()}>
                      <Accordion.Header
                        className="lessons d-flex align-items-center"
                        onClick={() => handleLessonClick(lessonIndex)}
                      >
                        <div className="acco_title-1">
                          <div className="acco_title">
                            <div className="flex-shrink-0 icon-tile">
                              <i className="icon-book"></i>
                            </div>
                            <div className="lession-title ms-3">
                              {postDetail?.lessons_name}
                            </div>
                          </div>
                          <div>
                            {t("Status")} :{" "}
                            {postDetail?.lesson_status
                              ? postDetail?.lesson_status
                              : t("Pending")}
                          </div>
                        </div>
                      </Accordion.Header>

                      {activeLessonIndices[lessonIndex] &&
                        postDetail?.topics &&
                        postDetail?.topics.map((topic, topicIndex) => (
                          <Accordion.Body
                            key={topicIndex}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTopicClick(lessonIndex, topicIndex);
                            }}
                          >
                            <div className="acco_title-1">
                              <div className="acco_title">
                                <div style={{}} key={topicIndex}>
                                  {t("Topic")} : {topic.topic_name}
                                </div>
                              </div>
                              <div>
                                {t("Status")} :{" "}
                                {topic?.topic_status
                                  ? topic?.topic_status
                                  : t("Pending")}
                              </div>
                            </div>
                            {activeSubIndices[lessonIndex] &&
                              activeSubIndices[lessonIndex][topicIndex] &&
                              topic.subTopics &&
                              topic.subTopics.map((subtopic, subtopicIndex) => (
                                <div className="acco_title-1">
                                  <div className="acco_title">
                                    <div
                                      className="accordion-collapse"
                                      style={{
                                        marginLeft: "20px",
                                        marginTop: "15px",
                                        marginBottom: "5px",
                                        // borderWidth: "5px",
                                        // borderColor: "grey",
                                      }}
                                      key={subtopicIndex}
                                    >
                                      {t("SubTopic")} :{" "}
                                      {subtopic.sub_topic_name}
                                      {/* Optional: Add icons or additional content */}
                                      {/* <div style={{ display: "flex", gap: "11px" }}>
                                    <FaEdit />
                                    <RiDeleteBin6Line />
                                  </div> */}
                                    </div>
                                  </div>{" "}
                                  <div>
                                    {t("Status")} :{" "}
                                    {subtopic?.sub_topic_status
                                      ? subtopic?.sub_topic_status
                                      : t("Pending")}
                                  </div>
                                </div>
                              ))}
                          </Accordion.Body>
                        ))}
                    </Accordion.Item>
                  </Accordion>
                ))
              ) : (
                <div
                  className="d-flex align-items-center"
                  style={{
                    color: "#592160",
                  }}
                >
                  <span>{t("No records founds")}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
