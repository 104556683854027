import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import LoadingModal from "./Loadingmodal";
// Import the LoadingModal component
import { useTranslation } from "react-i18next";

export const CreateSyllabus = () => {
  const [curriculam, setCurriculam] = useState("");
  const [language, setLanguage] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navi = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!curriculam) newErrors.curriculam = "Curriculum name is required";
    if (!language) newErrors.language = "Language is required";
    return newErrors;
  };

  const CirriculamCreate = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setLoading(true);

    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      curriculum_name: curriculam,
      curriculum_language: language,
    });

    simplePostCall(ApiConfig.SYLLABUS_CREATE, RequestBody)
      .then((data) => {
        setLoading(false);
        if (data.result) {
          toast.success("Syllabus created successfully!");
          navi("/SyllabusCreate");
        } else {
          toast.error("Failed to create syllabus.");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("API request failed.");
        console.log("API request failed", err);
      });
  };
  const { t } = useTranslation();
  return (
    <div className="SyllabusCreate">
      <ToastContainer />
      {loading && <LoadingModal />}
      {/* Show the loading modal when loading is true */}
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="labelCreate">{t("Create Syllabus")}</label>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              CirriculamCreate();
            }}
          >
            <div className="col-md-12 curriculum">
              <div className="col">
                <label>{t("Curriculum Name")}</label>
              </div>
              <input
                type="text"
                className="textar"
                value={curriculam}
                onChange={(val) => {
                  setCurriculam(val.target.value);
                  console.log(curriculam);
                }}
              />
              {errors.curriculam && (
                <p className="errors">{errors.curriculam}</p>
              )}
            </div>
            <div className="col-md-12">
              <div className="col">
                <label>{t("Curriculum Language")}</label>
              </div>
              <select
                className="form-select"
                aria-label="Default select example"
                value={language}
                onChange={(ev) => {
                  setLanguage(ev.target.value);
                  console.log(language);
                }}
              >
                <option value="">{t("Select Language")}</option>
                <option value="English">English</option>
                <option value="Arabic">Arabic</option>
                <option value="Urdu">Urdu</option>
              </select>
              {errors.language && <p className="errors">{errors.language}</p>}
            </div>

            <button type="submit" className="buttcreate" disabled={loading}>
              {t("Create")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
