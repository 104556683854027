import React, { useEffect, useState } from "react";
import Select from "react-select";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const initialState = {
  isloading: false,
  grade_code: "",
  grade_name: "",
  school_hour: "",

  grade_code_error: "",
  grade_name_error: "",
  school_hour_error: "",
};
function CreateGrade() {
  const [state, setState] = useState(initialState);
  const [showGradeCode, setShowGradeCode] = useState("");
  const [showGradeName, setShowGradeName] = useState("");
  const [classListapi, setClassListapi] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    ClassesList();
  }, []);
  const ClassesList = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.CLASS_SHIFT_LIST, requestbody)
      .then((data) => {
        const classeslist = data.list.map((classdata) => ({
          id: classdata.shift_id,
          value: classdata.shift_name, // Using shift_name as the value
          label: classdata.shift_name, // Using shift_name as the label
        }));
        setClassListapi(classeslist);
        console.log(classeslist, "classslist");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(state, "schoolhourr");
  function handleSaveAndNext() {
    try {
      if (
        state.grade_code === "" ||
        state.grade_name === "" ||
        state.school_hour === ""
      ) {
        toast.error(t("Please fill all mandatory fields"));

        setState(() => ({
          grade_code_error: state.grade_code === "" ? t("Enter grade Code") : null,

          grade_name_error: state.grade_name === "" ? t("Enter grade") : null,
          school_hour_error: state.school_hour === "" ? t("Select Hour") : null,
        }));
      } else {
        CreateMode();
        setState("");
      }
    } catch (error) {
      console.error("Error in handleSaveAndNext:", error);
    }
  }

  const CreateMode = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade: showGradeCode,
      grade_name: showGradeName,
      school_hour: state.school_hour,
    });
    simplePostCall(ApiConfig.CLASSCREATE, requestbody)
      .then((data) => {
        console.log(data, "createvannu");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="d-flex">
          <h5 className="mb-2 flex-grow-1">{t("Create Grade")}</h5>
        </div>
        <div className="card">
          <div className="card-header">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Grade Code")}</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="text"
                      onChange={(e) => setShowGradeCode(e.target.value)}
                      value={showGradeCode}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.grade_code_error ? "" : "none",
                      }}
                    >
                      {state.grade_code_error}
                    </small>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Grade Name")}</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="text"
                      onChange={(e) => setShowGradeName(e.target.value)}
                      value={showGradeName}
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.grade_name_error ? "" : "none",
                      }}
                    >
                      {state.grade_name_error}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("School Hour")}</label>

                    <Select
                      placeholder="Select School Hour"
                      options={classListapi}
                      onChange={(selectedOption) =>
                        setState({ school_hour: selectedOption.id })
                      }
                    />
                    <small
                      style={{
                        color: "red",
                        display: state.school_hour_error ? "" : "none",
                      }}
                    >
                      {state.school_hour_error}
                    </small>
                  </div>
                </div>

                <div className="schedulebutton">
                  <div></div>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        handleSaveAndNext();
                      }}
                    >
                     {t("Create")} 
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateGrade;
