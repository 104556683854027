import React, { useState } from "react";
import { toast } from "react-toastify";
import b3 from "../../assets/images/user/b3.png";
import SpinnerCmp from "../../components/SpinnerCmp";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useTranslation } from "react-i18next";

const StudentLeaveModal = (props) => {
  const {t} = useTranslation();

  const [approveLeaveState, setApproveLeaveState] = useState({
    isloading: false,
  });
  const displayStyle = {
    display: "block",
  };

  function cancelLeave(leave_id) {
    setApproveLeaveState({ ...approveLeaveState, isloading: true });

    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      leave_id: leave_id,
    });

    simplePostCall(ApiConfig.STUDENT_LEAVE_REJECT, requestBody)
      .then((data) => {
        if (data.result) {
          // setTimeout(function () {
          //     window.location.replace("/StudentLeaves");
          // }, 1000);
          toast.success(data.message);
        } else {
          setApproveLeaveState({ ...approveLeaveState, isloading: false });
          toast.success(data.message);
        }
      })
      .catch((error) => {
        setApproveLeaveState({ ...approveLeaveState, isloading: false });
        console.log("api response", error);
      });
  }
  function approveLeave(leave_id) {
    setApproveLeaveState({ ...approveLeaveState, isloading: true });

    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      teacher_id: Params.teacher_id,
      leave_id: leave_id,
    });

    simplePostCall(ApiConfig.STUDENT_LEAVE_APPROVE, requestBody)
      .then((data) => {
        if (data.result) {
          // setTimeout(function () {
          //     window.location.replace("/StudentLeaves");
          // }, 1000);
          toast.success(data.message);
        } else {
          setApproveLeaveState({ ...approveLeaveState, isloading: false });
          toast.success(data.message);
        }
      })
      .catch((error) => {
        setApproveLeaveState({ ...approveLeaveState, isloading: false });
        console.log("api response", error);
      });
  }

  return (
    <div
      class="modal fade modal-bookmark show testingll"
      id="exampleModal1"
      style={displayStyle}
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModal1Label"
      aria-hidden="true"
    >
      {approveLeaveState.isloading ? (
        <SpinnerCmp />
      ) : (
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex align-items-center">
                <div class="p-image flex-grow-0">
                  <img
                    src={
                      props.studentLeaveData.student_profilepicture
                        ? props.studentLeaveData.student_profilepicture
                        : b3
                    }
                  />
                </div>
                <div class="student-name flex-grow-1">
                  {props.studentLeaveData.student_name}
                </div>
                <a href="#" class="no-leave">
                  <b onClick={props.onClose}>x</b>
                </a>
              </div>
            </div>
            <div class="modal-body modal-scroll" style={{ maxHeight: "500px" }}>
              <div class="row">
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("From")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_from_date}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("To")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_to_date}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("Applied Date")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_apply_date}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("No of Days")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_no_of_days &&
                      props.studentLeaveData.leave_no_of_days > 1
                        ? props.studentLeaveData.leave_no_of_days + " Days"
                        : props.studentLeaveData.leave_no_of_days + " Day"}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("Leave Type")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_type}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="fixed-form">
                    <div class="al-label">{t("Leave Mode")}</div>
                    <div class="al-input">
                      {props.studentLeaveData.leave_mode}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="fixed-form">
                    <div class="al-label">{t("Leave Reason")}</div>
                    <div class="al-text-area">
                      {props.studentLeaveData.leave_reason}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="fixed-form">
                    <div class="al-label">{t("Leave Status")}</div>
                    {props.studentLeaveData.leave_status == "pending" ? (
                      <div class="al-pending">
                        {props.studentLeaveData.leave_status}
                      </div>
                    ) : (
                      <div class="al-approved">
                        {props.studentLeaveData.leave_status}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {props.studentLeaveData.leave_status == "pending" ||
              props.studentLeaveData.leave_status == "cancel requested" ? (
                <>
                  <button
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => cancelLeave(props.studentLeaveData.leave_id)}
                  >
                        {t("Reject")}
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={() =>
                      approveLeave(props.studentLeaveData.leave_id)
                    }
                  >
                    {t("Approve")}
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-secondary"
                  onClick={props.onClose}
                  data-bs-dismiss="modal"
                >
                  {t("Close")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StudentLeaveModal;
