import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import dropdown_icons from "../../assets/images/dropdown_icons.svg";
import forum_img_1 from "../../assets/images/forum_img_1.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import share_icon from "../../assets/images/share_icon.svg";
import Delete from "../../assets/images/delete_icon.svg";
import forum_img_com from "../../assets/images/forum_img_com.svg";
import like_btn from "../../assets/images/like_btn.svg";
import Likee from "../../assets/images/Likee.svg";
import dislike_btn from "../../assets/images/dislike_btn.svg";
import com_icon from "../../assets/images/com_icon.svg";
import edit_icon from "../../assets/images/edit_icon.svg";
import dislike_icon from "../../assets/images/dislikee.svg";
// import Modal from "react-bootstrap/Modal";
import { Dropdown, Modal, Button } from 'react-bootstrap';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import add_btn from "../../assets/images/add_btn.svg";
import Form from "react-bootstrap/Form";
import ListSkeleton from "../../components/skeletons/ListSkeleton";
import ApiConfig from "../../api/ApiConfig";
import axios from "axios";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import { IoIosClose } from "react-icons/io";
import { io } from "socket.io-client";
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
// import { Button } from "react-bootstrap";

function Forum() {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [feedData, setFeedData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [validated, setValidated] = useState(false);

  //////////////////////////////////////////Start------UseState\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  const [liked, setLiked] = useState(true);
  const [categoryLists, setCategoryLists] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [more, setMore] = useState("");
  const [toggleAllForum, setToggleAllForum] = useState(true);
  const [toggleManageForum, setToggleManageForum] = useState(true);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1);
  const [section, setSection] = useState("");
  const [isShownItem, setIsShownItem] = useState({});
  const [createMeassage, setCreateMeassage] = useState("");
  const [forumId, setForumId] = useState("");
  const [socket, setSocket] = useState("");

  const [filterData, setFilterData] = useState([
    { id: 1, label: "Most Recent", value: false },
    { id: 2, label: "Most Liked", value: false },
    { id: 3, label: "Most Commented", value: false },
  ]);

  //////////////////////////////////////////Ends----Usestate\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const handleSubmitone = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      CreateForumApi();
    }

    setValidated(true);
  };
  //////////////////////////////////////////My\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const handleRadioChange = (id) => {
    setSelectedOption(id);
  };
  const handleClear = () => {
    setSelectedOption("");
  };
  console.log(isShownItem,"item");
  //------------------------Start----create_Forum_Api---List--------------------------//
  const HandleForumone = (item = null, item2 = null) => {
    try {
      axios(ApiConfig.ALL_FORUM, {
        method: "GET",
        params: {
          like_dislike: "",
          most_commented: "",
          user_id: Params.teacher_id,
          page_limit: 50,
          page_no: totalPages,
          // teacher_id: Params.teacher_id,
        },

        headers: {
          Accept: "application/json",
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      }).then((data) => {
        if (data.data.result) {
          console.log(data.data.data,"dtaas");
          setData((prevData) => [...prevData, ...data.data.data]);
          setTotalPages(data.data.next_page);
          setIsloading(false);
        } else {
          setIsloading(false);
         
        }
      });
    } catch (error) {
      console.log("home error:", error);
      setIsloading(false);
      setData([]);
    }
  };

  //------------------------Ends----create_Forum_Api---List--------------------------//
  //------------------------Start----create_Forum_Api---Date--------------------------//
  // function formatDate(dateString) {
  //   const months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   const dateObj = new Date(dateString);
  //   const day = dateObj.getDate();
  //   const month = months[dateObj.getMonth()];
  //   const year = dateObj.getFullYear();

  //   return `${month} ${day}, ${year}`;
  // }
  //------------------------Ends----create_Forum_Api---List--------------------------//
  useEffect(() => {
    HandleForumone();
    {
      console.log("This is the user id", Params.teacher_id);
    }
  }, [createMeassage]);
  // const handleclick = (id, value) => {
  //   const updateFilterData = filterData.map((item) => {
  //     if (item.id === id) {
  //       return { ...item, value: true };
  //     }
  //     return { ...item, value: false };
  //   });
  //   setFilterData(updateFilterData);
  //   setShow(false);
  //   HandleForumone(
  //     value === true ? true : value === false ? false : toggleAllForum,
  //     id,
  //   );
  // };
  const [showModal, setShowModal] = useState(false);
  const handleClosee = () => setShowModal(false);
  const handleShoww = () => setShowModal(true);
  //------------------------Start----create_Forum_Api---Like--------------------------//
  useEffect(() => {
    const customerId = Params.customer_id;
    const userId = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + customerId + "&userId=" + userId
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected on forum room");

      socket.emit("forum", () => {
        console.log("forum room is create");
      });
    });
    socket.on("forum_like_count", (data) => {
      console.log(data, "socket response");
      HandleSocket(data);
    });
    socket.on("newforum", (data) => {
      console.log("newFeed received", data);
    });
    // Handle socket disconnection
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket manually disconnected forum list");
      }
    };
  }, []);
  const LikeCountEvent = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: true,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("likeEvent is emited");
    });
  };
  const DisLikeCount = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: false,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("dislikedevent is emitted");
    });
  };

  const HandleSocket = (conn) => {
    console.log(conn, "response from socket data");

    // setLiked(true);
    setData((prevFeedLists) => {
      const UpdateListedForum = prevFeedLists?.map((forum) => {
        if (forum.forum_id == conn.forum_id) {
          return {
            ...forum,
            forum_like_count: conn.forum_like_count,
            forum_dislike_count: conn.forum_dislike_count,
            existing_like: conn.existing_like,
            existing_dislike: conn.existing_dislike,
          };
        }
        return forum;
      });
      const forumExisting = UpdateListedForum.some(
        (forum) => forum.forum_id == conn.forum_id,
      );
      if (!forumExisting) {
        return [
          ...UpdateListedForum,
          {
            forum_id: conn.forum_id,
            forum_like_count: conn.forum_like_count,
            forum_dislike_count: conn.forum_dislike_count,
            existing_like: conn.existing_like,
            existing_dislike: conn.existing_dislike,
          },
        ];
      }
      return UpdateListedForum;
    });
  };

  //------------------------Ends----create_Forum_Api---Like--------------------------//
  //------------------------Starts----create_Forum_Api--------------------------//
  const handleCatorylist = (catid) => {
    setCategory(catid);
    console.log(catid, "This will be the category id");
  };
  const handleFileUpdate = (e) => {
    setFile((prevAttachment) => [
      ...prevAttachment,
      ...Array.from(e.target.files),
    ]);
  };
  const handleDelete = (index) => {
    const newFiles = [...file];
    newFiles.splice(index, 1);
    setFile(newFiles);
  };
  const Handleclearcreate = () => {
    setTitle("");
    setDescription("");
    setCategory("");
  };console.log(Params.teacher_id,"techer");
  const forumDelete = async () => {
    // console.log("delete");
    const requestBody = {
      forum_createdby: forumCreated,
      forum_id: delId,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };
    // console.log(headers, requestBody, "reqbod");

    axios
      .post(ApiConfig.FORUM_DELETE, requestBody, { headers })
      .then((response) => {
        // console.log("check", response.data.result);
        if (response.data.result) {
          // toast.show(response.data.message, Toast.SHORT);
          console.log("deleted");
          handleClosee()
          
        }
        // setisLoading(false);
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error4: ", error);
      });
  };
  const CreateForumApi = async () => {
    if (section == "create") {
      console.log("create API is working", section == "create");
      const url = ApiConfig.FORUM_CREATE;
      const headers = {
        Accept: "application/json",

        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        customer_id: Params.customer_id,
        api_key: Params.api_key,
      };
      const formData = new FormData();
      formData.append("forum_title", title);
      formData.append("forum_createdby", Params.teacher_id);

      formData.append("forum_description", description);
      formData.append("forum_category", category);
      file.forEach((files) => {
        formData.append("forum_attachment", files);
      });
      try {
        const response = await axios.post(url, formData, { headers });
        console.log(response, "forum created successfully");
        setCreateMeassage(response);
        toast.success(response.data.message);
      } catch (error) {
        console.log(error, "axios error");
        toast.error("error in creating forum");
      }
    } else if (section == "edit") {
      const url = ApiConfig.FORUM_EDIT;
      const headers = {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        customer_id: Params.customer_id,
        api_key: Params.api_key, // Ensure api_key is in headers
      };
    
      const formData = new FormData();
      formData.append("forum_title", title); // Assuming 'title' is defined
      formData.append("forum_createdby", isShownItem.forum_createdby); // Assuming 'Params.teacher_id' is defined
      formData.append("forum_description", description); // Assuming 'description' is defined
      formData.append("forum_category", category); // Assuming 'category' is defined
      formData.append("forum_id", forumId); // Assuming 'forumId' is defined
    
      // Append files if they exist
      // if (file && file.length > 0) {
      //   file.forEach((files) => {
      //     formData.append("forum_attachment", files || isShownItem.forum_attachment);
      //   });
      // }
    
      try {
        const response = await axios.post(url, formData, { headers });
        setCreateMeassage(response); // Assuming 'setCreateMeassage' is defined
        toast.success(response.data.message); // Assuming 'toast' is defined
      } catch (error) {
        console.log(error, "axios error");
        toast.error("This is the Error"); // Assuming 'toast' is defined
      }
    }
    }
  //------------------------End----create_Forum_Api--------------------------//
  const moreCheck = (id) => {
    if (id === more) {
      setMore("");
    } else setMore(id);
  };

  //------------------------Start----create_Forum_Api---Pagination--------------------------//

  const Pagination = (item, item2) => {
    setIsloading(true);
    axios(ApiConfig.ALL_FORUM, {
      method: "GET",
      params: {
        like_dislike: item2 === 2 ? true : null,
        most_commented: item2 === 3 ? true : null,
        user_id: Params.user_id,
        page_limit: 50,
        page_no: totalPages,
        teacher_id: Params.user_id,
      },
      headers: {
        Accept: "application/json",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          setData((prevData) => [...prevData, ...response.data.data]); // Append new data
          setTotalPages(response.data.next_page);
        } else {
          console.log("No more data available.");
        }
      })
      .catch((error) => {
        console.error("Error fetching forums:", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };
  const [delId,setDelid]=useState("");
  const [forumCreated,setForumCreated]=useState("")
  //------------------------Ends----create_Forum_Api---Pagination--------------------------//
  // *******
  //------------------------Starts----Dropdown Catogory--------------------------//
  const SelectCategoryList = async () => {
    const url = ApiConfig.FORUM_CATEGORY;
    const headers = {
      customer_id: Params.customer_id,
      api_key: Params.api_key,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.result == true) {
        setCategoryLists(response?.data?.data);
      }
    } catch (error) {
      console.log(error, "This is the axios error");
    }
  };
  //------------------------End----Dropdown Catogory--------------------------//
  useEffect(() => {
    SelectCategoryList();
  }, []);
  console.log(delId,forumCreated,"delid");
   //----------------------------Translation --------------------------//
  const { t } = useTranslation();
  return (
    <>
      <div class="Forum library">
        {isloading ? (
          <ListSkeleton />
        ) : (
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="announcement-top">
                <label>{t("Forums")}</label>
                  <div className="top-btn-wrapper">
                    <button onClick={() => setModalShow(true)}>
                      <img src={add_btn} alt="" />
                      {t("Create Forum")}
                    </button>
                    <img src={filter_icon} alt="" onClick={handleShow} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <TabView>
                    <TabPanel
                      header={t("All Forums")}
                      // onClick={HandleAllForumToggle}
                    >
                      
                      <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={
                          data?.filter((item) => item.user_role === "student")
                            .length
                        }
                        next={() => {
                          if (currentPage < totalPages) {
                            setCurrentPage((prev) => prev + 1);
                            Pagination();
                          }
                        }}
                        hasMore={currentPage < totalPages}

                        // loader={<h4>Loading...</h4>}
                      >
                        {data
                          .filter((item) => item.user_role === "student") // Only students
                          .map((item, index) => {
                            return (
                              <div className="forum-item" key={index}>
                               
                                  
                                  <div className="forum-header">
                                    <div className="forum-img">
                                      <img src={forum_img_1} alt="" />
                                    </div>  
                                    <div className="forum-title-box">
                                    <Link
                                  to={`/ForumView?id=${encodeURIComponent(
                                    item.forum_id,
                                  )}`}
                                >
                                      <div className="forum-title-wrapper">
                                        <p className="ft-main-title">
                                          {item.forum_title}
                                        </p>

                                        <div className="d-flex flex-row">
                                          <img
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%",
                                          }}
                                          class="flex-grow-0"
                                            src={
                                             
                                                ApiConfig.NODE_URL +"/"+
                                                item.user_profile_pic
                                            }
                                          />
                                          <p className="ft-sub-title">
                                            {item.forum_created_by}
                                          </p>
                                          {"/"}
                                          <p className="ft-sub-title">
                                            {item.user_role}
                                          </p>
                                        </div>
                                        <p className="ft-sub-title">
                                          {item.category_name}
                                        </p>
                                        <p className="ft-date">
                                          {/* {formatDate(item.forum_created_date)} */}
                                        </p>
                                      </div>
                                      
                                      </Link>

                                      <div className="forum-dropdown">
                                        <Dropdown className="Common_dropDown">
                                          <Dropdown.Toggle
                                            className="dropdown-btn"
                                            id="dropdown-basic"
                                          >
                                            <img src={dropdown_icons} alt="" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item>
                                              <div className="d-flex flex-row">
                                                <img src={share_icon} alt="" />
                                                <p>{t("Share")}</p>
                                              </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                              <div
                                                className="d-flex flex-row"
                                                onClick={() => {
                                                  setModalShow1(true);
                                                  setForumId(item.forum_id);
                                                  setIsShownItem(item);
                                                }}
                                              >
                                                <img src={edit_icon} alt="" />
                                                <p>{t("Edit")}</p>
                                              </div>
                                            </Dropdown.Item>
                                           
                                          <Dropdown.Item onClick={handleShoww}>
            <div className="d-flex flex-row" onClick={()=>{setDelid(item.forum_id);setForumCreated(item.forum_createdby)}}>
              <img src={Delete} alt="Delete" />
              <p>{t("Delete")}</p>
            </div>
          </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
     
                                <div className="forum-footer">
                                  <div className="d-flex flex-row ">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        LikeCountEvent(item.forum_id)
                                      }
                                    >
                                      {item.existing_like ? (
                                        <BiSolidLike
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            color: "#954386",
                                          }}
                                        />
                                      ) : (
                                        <BiLike
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            color: "#954386",
                                          }}
                                        />
                                      )}
                                      {/* <img src={like_btn} alt="like button" /> */}
                                    </div>
                                    <span className="ms-0.5">
                                      {item.forum_like_count}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-row ms-4">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        DisLikeCount(item.forum_id)
                                      }
                                    >
                                      {item.existing_dislike ? (
                                        <BiSolidDislike
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            color: "#954386",
                                          }}
                                        />
                                      ) : (
                                        <BiDislike
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            color: "#954386",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <span>{item.forum_dislike_count}</span>
                                  </div>

                                  <div className="d-flex flex-row ms-4">
                                    {" "}
                                    <img src={com_icon} alt="like button" />
                                    <span>{item.forum_comment_count}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                      {/* {renderPagination()} */}
                    </TabPanel>

                    <TabPanel
                     header=  {t("My Forums")}
                    
                      // onClick={HandleManageForumToggle}
                    >
                      <InfiniteScroll
                        style={{ overflow: "hidden" }}
                        dataLength={
                          data?.filter((item) => item.user_role === "teacher")
                            .length
                        }
                        next={() => {
                          if (currentPage < totalPages) {
                            setCurrentPage((prev) => prev + 1);
                            Pagination();
                          }
                        }}
                        hasMore={currentPage < totalPages}

                        // loader={<h4>Loading...</h4>}
                      >
                        {console.log(data,"data")}
                        {data
                          .filter((item) => item.user_role === "teacher") // Only teachers
                          .map((item, index) => {
                            return (
                              <div className="forum-item" key={index}>
                                <div className="forum-header">
                                  <div className="forum-img">
                                    <img src={forum_img_1} alt="" />
                                  </div>
                                  <div className="forum-title-box">
                                    <div className="forum-title-wrapper">
                                      <p className="ft-main-title">
                                        {item.forum_title}
                                      </p>

                                      <div className="d-flex flex-row">
                                        <img
                                          src={{
                                            uri:
                                              ApiConfig.PROFILE_THEME +
                                              item.user_profile_pic,
                                          }}
                                        />
                                        <p className="ft-sub-title">
                                          {item.forum_created_by}
                                        </p>
                                        {"/"}
                                        <p className="ft-sub-title">
                                          {item.user_role}
                                        </p>
                                      </div>
                                      <p className="ft-sub-title">
                                        {item.category_name}
                                      </p>
                                      <p className="ft-date">
                                        {/* {formatDate(item.forum_created_date)} */}
                                      </p>
                                    </div>
                                    <div className="forum-dropdown">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <div className="d-flex flex-row">
                                              <img src={share_icon} alt="" />
                                            <p>{t("Share")}</p>
                                            </div>
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <div className="d-flex flex-row">
                                              <img src={edit_icon} alt="" />
                                               <p>{t("Edit")}</p>
                                            </div>
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={handleShoww}>
            <div className="d-flex flex-row">
              <img src={Delete} alt="Delete" />
              <p>{t("Delete")}</p>
            </div>
          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
          
                                <div className="forum-footer">
                                  <div className="d-flex flex-row ">
                                    {/* <div style={{ cursor: "pointer" }}>
                                  <img src={Likee} alt="like button" />
                                </div> */}

                                    <div>
                                      <img src={like_btn} alt="like button" />
                                    </div>

                                    <p className="ms-0.5">
                                      {item.forum_like_count}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-row ms-4">
                                    <div style={{ cursor: "pointer" }}>
                                      <img
                                        src={dislike_icon}
                                        alt="like button"
                                      />
                                    </div>

                                    {/* <div>
                                  <img src={dislike_btn} alt="like button" />
                                </div> */}

                                    <p>{item.forum_dislike_count}</p>
                                  </div>

                                  <div className="d-flex flex-row ms-4">
                                    <img src={com_icon} alt="like button" />
                                    <p>{item.forum_comment_count}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Filter Modal Start */}
      <Modal show={showModal} onHide={handleClosee}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t("Are you sure you want to delete this item")}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosee}>
          {t("Cancel")}  
          </Button>
          <Button variant="danger" onClick={forumDelete}>
          {t("Delete")} 
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>{t("Filter By")}</p>
              <p onClick={handleClear} style={{ cursor: "pointer" }}>
              {t("Clear")}
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {filterData.map((item) => (
            <div
              className="select-wrapper"
              key={item.id}
              // onClick={() => handleclick(item.id)}
            >
              <ul>
                <li>
                  <input
                    type="radio"
                    id={`option-${item.id}`}
                    name="selector"
                    checked={selectedOption === item.id}
                    onChange={() => handleRadioChange(item.id)}
                  />
                  <label htmlFor={`option-${item.id}`}>{item.label}</label>
                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      {/* Filter Modal End */}

      {/* Create Forum Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
          {t("Create Forum")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitone}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>{t("Title")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Type Your Title Here")}
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {" "}
                      {t("Please provide a title")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>{t("Category")}</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        handleCatorylist(e.target.value);
                      }}
                    >
                      <option value="">{t("Select Subject")}</option>
                      {categoryLists?.map((categories) => (
                        <option
                          key={categories.category_id}
                          value={categories.category_id}
                          id={categories.category_id}
                        >
                          {categories.category_name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    
                      {t("Please choose a category")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3 " controlId="validationCustom01">
                    <Form.Label>{t("Forum Text")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder={t("Type Your Text Here")}
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("Please provide forum text")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="validationCustomAttachments"
                  >
                    <Form.Label>{t("Attachments")}</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileUpdate}
                    />
                    {file?.map((files, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{files.name}</p>
                        <div 
                        onClick={() => handleDelete(index)}
                        >
                          <IoIosClose />
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button
              onClick={() => {
                setModalShow(false);
                Handleclearcreate();
              }}
              className="cx-btn-1"
            >
                {t("Cancel")}
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
              onClick={() => setSection("create")}
            >
                {t("Publish")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Create Forum Modal End */}

      {/* Edit Forum Modal Start */}
      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
          {t("Edit Forum")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitone}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label> {t("Title")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Type Your Title Here...")}
                      onChange={(e) => setTitle(e.target.value)}
                      value={title || isShownItem.forum_title}
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("Please provide a title")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>{t("Category")}</Form.Label>
                    <Form.Select
                      onChange={(e) => handleCatorylist(e.target.value)}
                    >
                      <option value={isShownItem.forum_category}>
                        
                        {isShownItem.category_name}
                      </option>
                      {categoryLists?.map((categories) => (
                        <option
                          key={categories.category_id}
                          value={categories.category_id}
                          id={categories.category_id}
                        >
                          {categories.category_name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {t("Please choose a category")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>{t("Forum Text")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Type Your Text Here...")}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description || isShownItem.forum_description}
                    />
                    <Form.Control.Feedback type="invalid">
                    {t("Please provide forum text")}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>{t("Attachments")}</Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFileUpdate}
                    />
                    {file?.map((files, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{files.name}</p>
                        <div
                         onClick={() => handleDelete(index)}
                         >
                          <IoIosClose />
                        </div>
                      </div>
                    ))}
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow1(false)} className="cx-btn-1">
            {t("Cancel")}
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
              onClick={() => {
                setSection("edit");
              }}
            >
               {t("Update")} 
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Edit Forum Modal End */}

      {/* Delete Modal Start */}
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
          {t("Delete")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
          {t("Are you sure you want to delete")}?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
            {t("Cancel")}
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
            {t("Delete")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete  Modal End */}
    </>
  );
}

export default Forum;
