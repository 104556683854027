import React, { useState } from "react";
import cross from "../../../assets/images/masterdatapic/cross.png";
import { useTranslation } from "react-i18next";
const SubjectWeekend = (props) => {
  const { t } = useTranslation();
  return (
    <div class="tt-col tt-content weekend">
      <div class="tt-desc tt-desc-inner">
        <img src={cross} />
        <div class="title">{t("Weekend")}</div>
      </div>
    </div>
  );
};
export default SubjectWeekend;
