import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";


// A utility function to reorder the list when dragging
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function PriorityGrades() {
  const [gardelist, setGradeList] = React.useState([
    { classgrade: "10th" },
    { classgrade: "Nursery 2" },
    { classgrade: "Primary 5" },
    { classgrade: "Grade 3" },
    { classgrade: "Plus One" },
    { classgrade: "Computer Architect" },
  ]);

  // Handle the drag end event
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedList = reorder(
      gardelist,
      result.source.index,
      result.destination.index
    );

    setGradeList(reorderedList);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">{t("Prioritise Grade")}</h5>
            <div className="tablebuttonview">
              <a
                href="/ViewGrades"
                type="button"
                className="btn timetablebutton "
              >
                <span>{t("Grades")}</span>
              </a>
              <a
                href="/CreateGrade"
                type="button"
                className="btn timetablebutton "
              >
                <span>{t("New Grade")}</span>
              </a>
              <a
                href="/ImportSubjects"
                type="button"
                className="btn timetablebutton"
              >
                <span>{t("Import")}</span>
              </a>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <label className="Instruction_label" htmlFor="task-title">
              {t("To order Grades from lowest grade to highest grade please drag and drop grade to required positions")}  
              </label>

              {/* DragDropContext handles the drag and drop logic */}
              <DragDropContext onDragEnd={onDragEnd}>
                {/* Droppable is the area where draggable items are dropped */}
                <Droppable droppableId="grades">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {/* Mapping through the grades and making each one draggable */}
                      {gardelist.map((item, index) => (
                        <Draggable
                          key={item.classgrade}
                          draggableId={item.classgrade}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                userSelect: "none",
                                padding: "10px",
                                margin: "4px 0",
                                backgroundColor: "#f4f4f4",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <label htmlFor="task-title">
                                {item.classgrade}
                              </label>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriorityGrades;
