import React, { useEffect, useState } from "react";
import "../../assets/css/CreateDiary.css";
import { IoIosClose } from "react-icons/io";
import Params from "../../config/Params";
import axios from "axios";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
import { simplePostCall } from "../../api/ApiServices";
import { Multiselect } from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateDiary = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [descrip, setDescrip] = useState("");
  const [clas, setClas] = useState("");
  const [recip, setRecip] = useState("");
  const [priority, setPriority] = useState("Medium");
  const [attach, setAttach] = useState([]);
  const [comm, setComm] = useState("true");
  const [errors, setErrors] = useState({});
  const [selectedClass, setSelectedClass] = useState([]);
  const [classess, setClassess] = useState("");
  const [selectedStud, setSelectedStud] = useState([]);
  const [studen, setStuden] = useState([]);

  //--------useEffect for initial api call--------//
  useEffect(() => {
    getGradeList();
  }, []);
  //--------useEffect for student api call--------//
  useEffect(() => {
    getStudents();
  }, [classess]);
  //--------useEffect for initial api call--------//
  const validateForm = () => {
    console.log(title, "titilr");
    let valid = true;
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = "Title is required";
      valid = false;
    }

    if (!descrip.trim()) {
      newErrors.descrip = "Description is required";
      valid = false;
    }

    if (!clas) {
      newErrors.clas = "Class is required";
      valid = false;
    }

    if (!recip) {
      newErrors.recip = "Recipient is required";
      valid = false;
    }

    if (attach.length === 0) {
      newErrors.attach = "At least one attachment is required";
      valid = false;
    }

    if (!priority) {
      newErrors.priority = "Priority is required";
      valid = false;
    }

    if (!comm) {
      newErrors.comm = "Comment option is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const navi = useNavigate();

  const handleSubmit = (e) => {
    // console.log(validateForm, "validateDo");
    e.preventDefault();
    if (validateForm) {
      // Proceed with form submission

      const formData = new FormData();
      formData.append("teacher_id", Params.teacher_id);
      formData.append("diary_title", title);
      formData.append("diary_message", descrip);
      formData.append("diary_class", JSON.stringify(classess));
      formData.append("diary_recipients", JSON.stringify(selectedStud));
      formData.append("diary_priority", priority);
      formData.append("diary_allow_comments", comm);
      attach.map((file, i) => {
        formData.append("diary_attachment[" + i + "]", file);
      });

      axios({
        url: ApiConfig.DIARY_CREATE,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
          customer_id: JSON.parse(Params.customer_id),
          api_key: Params.api_key,
        },
      })
        .then((data) => {
          if (data?.data?.result === true) {
            toast.success(data?.data?.message);
            navi("/MyDiary");
          } else {
            toast.warning(data?.data?.message);
          }
        })
        .catch((error) => {
          console.log("no use ::", error);
        });
    }
  };

  function getGradeList() {
    // setGradeState({ ...gradeState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        if (data.result) {
          var grades = [];
          if (data.list.length > 0) {
            data.list.map((grade, index) => {
              grades.push({
                label: grade.class_name + " " + grade.class_division_name,
                value: grade.class_division_id,
              });
            });
          }
          setSelectedClass({ ...selectedClass, grades: grades });
          // setGradeState({ ...gradeState, isloading: false, grades: grades });
        } else {
          console.log(data);

          // setGradeState({ ...gradeState, isloading: false });
        }
      })
      .catch((error) => {
        // setGradeState({ ...gradeState, isloading: false });
        console.log("api response", error);
      });
  }
  const onGradeChange = (e) => {
    let temp = e;
    const allClass = temp.map((el) => el.value);
    setClassess(allClass);
  };
  function getStudents() {
    console.log(classess, "classess");
    // setStudentsState({ ...studentsState, isloading: true });
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
      section_ids: classess,
    });

    simplePostCall(
      ApiConfig.STUDENTS_LIST_BY_SELECTED_CLASSES_API,
      newRequestBody,
    )
      .then((data) => {
        if (data.result) {
          var students = [];
          if (data.list?.length > 0) {
            data.list.map((student, index) => {
              students.push({
                label: student.student_name,
                value: student.student_id,
              });
            });
          }
          setStuden({ ...studen, students: students });
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const OnStudentChange = (e) => {
    console.log(e, "element in Student");
    let temp = e;
    const selectedStud = temp.map((el) => el.value);
    setSelectedStud(selectedStud);
  };
  const handleChange = (evt) => {
    setTitle(evt.target.value);
  };

  const handleDesci = (evt) => {
    setDescrip(evt.target.value);
  };

  const commChange = (e) => {
    setComm(e.target.value);
  };

  const handleFile = (e) => {
    setAttach((prevAttach) => [...prevAttach, ...Array.from(e.target.files)]);
  };

  const handleDelete = (index) => {
    const newFiles = [...attach];
    newFiles.splice(index, 1);
    setAttach(newFiles);
  };

  return (
    <div className="CreateDiary">
      <div className="main-sectionn">
        <div className="row">
          <div className="col-md-12">
            <div className="announcementCreate">
              <label className="labelCreate">{t("Create Diary")}</label>
            </div>

            <div className="row content">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="contentHeader">{t("Title")}</label>
                    <textarea
                      className="createInput form-control"
                      value={title}
                      onChange={handleChange}
                    ></textarea>
                    {errors.title && (
                      <span className="text-danger">{errors.title}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">{t("Description")}</label>
                    <textarea
                      className="form-control"
                      value={descrip}
                      onChange={handleDesci}
                      rows="4"
                    ></textarea>
                    {errors.descrip && (
                      <span className="text-danger">{errors.descrip}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">{t("Class")}</label>
                    {/* <Select
                      class="js-example-basic-single form-select"
                      onChange={onGradeChange}
                      options={selectedClass?.grades}
                      placeholder="Select Class"
                    /> */}
                    <Multiselect
                      options={selectedClass.grades}
                      displayValue={"label"}
                      style={{
                        chips: { background: "#73276b" },
                      }}
                      onSelect={onGradeChange}
                    />
                    {/* <select
                      className="form-select form-control"
                      value={clas}
                      onChange={classCH}
                    >
                      <option value="">Select Grade</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select> */}
                    {errors.clas && (
                      <span className="text-danger">{errors.clas}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">
                      {t("Recipient List")}
                    </label>
                    {/* <select
                      className="form-select form-control"
                      value={recip}
                      onChange={(evt) => setRecip(evt.target.value)}
                    >
                      <option value="">Select Student Name</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select> */}
                    {/* <Select
                      class="js-example-basic-single form-select"
                      onChange={(e) => setSelectedStud(e.value)}
                      options={studen?.students}
                      placeholder="Select Class"
                    /> */}
                    <Multiselect
                      options={studen.students}
                      displayValue={"label"}
                      style={{
                        chips: { background: "#73276b" },
                      }}
                      onSelect={OnStudentChange}
                    />
                    {errors.recip && (
                      <span className="text-danger">{errors.recip}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">
                    {t(" Attachments only 4 Files are allowed")}
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      multiple
                      onChange={handleFile}
                    />
                    {errors.attach && (
                      <span className="text-danger">{errors.attach}</span>
                    )}
                    {attach.map((file, index) => (
                      <div key={index} className="deletebutt">
                        <p>{file.name}</p>
                        <button
                          onClick={() => handleDelete(index)}
                          className="closebtt"
                        >
                          <IoIosClose />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <label className="contentHeader">{t("Priority")}</label>
                    <select
                      className="form-select form-control"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                    >
                      <option value="">{t("Select Priority")}</option>
                      <option value="High">{t("High")}</option>
                      <option value="Medium">{t("Medium")}</option>
                      <option value="Low">{t("Low")}</option>
                    </select>
                    {errors.priority && (
                      <span className="text-danger">{errors.priority}</span>
                    )}
                  </div>
                  <div className="form-group radioButtons">
                    <span className="contentHeader2">{t("Allow Comments")} ?</span>
                    <div className="chechlist">
                      <p>{t("yes")}</p>
                      <input
                        type="radio"
                        id="specifyColor"
                        name="comm"
                        value="true"
                        defaultChecked
                        onChange={commChange}
                        checked={comm === "true"}
                      />
                      <p>{t("no")}</p>
                      <input
                        type="radio"
                        id="specifyColor"
                        name="comm"
                        value="false"
                        onChange={commChange}
                        checked={comm === "false"}
                      />
                    </div>
                    {errors.comm && (
                      <span className="text-danger">{errors.comm}</span>
                    )}
                  </div>
                  {/* <label className="contentHeader">
                    Attachments only (4 Files are allowed)
                  </label>
                  <div className="col-md-12" onChange={handleeFile}>
                    <input
                      class="form-control"
                      type="file"
                      multiple
                      id="formFileDisabled"
                    />
                    {files.map((file, index) => (
                      <div key={index} className="deletebutt">
                        <p>{file.name}</p>
                        <button
                          onClick={() => handleDeelete(index)}
                          className="closebtt"
                        >
                          <IoIosClose />
                        </button>
                      </div>
                    ))}
                  </div> */}
                  <div className="form-group SubmitDiv">
                    <Link to="/MyDiary">
                      <button type="button" className="cancelBut">
                      {t("Cancel")}
                      </button>
                    </Link>
                    <button type="submit" className="submitBut">
                    {t("Submit")} 
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDiary;
