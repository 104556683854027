import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import eye from "../assets/images/eye.png";

function AssessmentsTable(props) {
  const { t } = useTranslation();

  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="card">
          <div className="card-header">
            <h5>{t("Assessments")}</h5>
          </div>
          <div className="table-responsive" style={{ paddingBottom: "10px" }}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("Title")} </th>
                  <th scope="col">{t("Grade")}</th>
                  <th scope="col">{t("Pending")}</th>
                  <th scope="col">{t("Attended")}</th>
                  <th scope="col">{t("Total")}</th>
                  <th scope="col">{t("View")}</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(props.assessment_data, "assesssments")} */}
                {props.assessment_data && props.assessment_data.length > 0 ? (
                  props.assessment_data
                    .slice(0, 5)
                    .map((assessments, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{assessments.assessment_title}</td>
                          <td>{assessments.assessment_class}</td>
                          <td>{assessments.assessment_pending}</td>
                          <td>{assessments.assessment_attended}</td>
                          <td>{assessments.assessment_total}</td>
                          <td>
                            <Link
                              to={`/AssessmentDetails/${assessments.assessment_id}`}
                              className="col-md-4"
                            >
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={eye}
                              />
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <th scope="row">{t("No records found")}</th>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <Link
              to={"/dashboard/assessments"}
              state={{ assessment_data: props.assessment_data }}
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              className="btn btn-edsys-primary"
              style={{
                display: props.assessment_count && props.assessment_count > 5 ? "" : "none",
                float: "right",
                width: "20%",
                marginTop: "1%"
              }}
            >
              <span>{t('See More')}</span>
            </Link> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "98%",
              marginTop: "10px",
            }}
          >
            <Link
              to={"/dashboard/assessments"}
              state={{ assessment_data: props.assessment_data }}
              type="button"
              className="btn btn-edsys-primary"
              style={{
                display:
                  props.assessment_count && props.assessment_count > 5
                    ? ""
                    : "none",
                float: "right",
                width: "160px",
                marginTop: "1%",
              }}
            >
              <span>{t("See More")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentsTable;
