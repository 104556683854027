import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import SubjectTimeTableGrid from "./subjecttimetable/SubjectTimeTableGrid";
import Params from "../../config/Params";
import { useLocation } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
function EditTeacher() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uid = params.get("message");
  console.log(uid, "teacherid");
  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];
  const parentstaff = [
    { value: "Theertha", label: "Theertha" },
    { value: "Darik", label: "Darik" },
    { value: "Amit", label: "Amit" },
    { value: "Rizwan", label: "Rizwan" },
    { value: "Sheena", label: "Sheena" },
  ];
  const teachercir = [
    { value: "alpha", label: "alpha" },
    { value: "biochemistry", label: "bio chemistry" },
    { value: "CBSE", label: "CBSE" },
    { value: "English", label: "English" },
    { value: "Geography", label: "Geography" },
  ];
  const Periods = [
    {
      id: 1,
      PeriodId: 1,
      startTime: "09:30 AM",
      endTime: "10:15 AM",
      isInterval: false,
    },
    {
      id: 2,
      PeriodId: 2,
      startTime: "10:15 AM",
      endTime: "11:00 AM",
      isInterval: false,
    },
    {
      id: 3,
      PeriodId: 0,
      startTime: "11:00 AM",
      endTime: "11:15 AM",
      isInterval: true,
    },
    {
      id: 4,
      PeriodId: 3,
      startTime: "11:15 AM",
      endTime: "12:00 PM",
      isInterval: false,
    },
    {
      id: 5,
      PeriodId: 4,
      startTime: "12:00 PM",
      endTime: "12:45 PM",
      isInterval: false,
    },
    {
      id: 6,
      PeriodId: 0,
      startTime: "12:45 PM",
      endTime: "01:45 PM",
      isInterval: true,
    },
    {
      id: 7,
      PeriodId: 5,
      startTime: "01:45 PM",
      endTime: "02:30 PM",
      isInterval: false,
    },
    {
      id: 8,
      PeriodId: 6,
      startTime: "02:30 PM",
      endTime: "03:15 PM",
      isInterval: false,
    },
    {
      id: 9,
      PeriodId: 0,
      startTime: "03:15 PM",
      endTime: "03:30 PM",
      isInterval: true,
    },
    {
      id: 10,
      PeriodId: 7,
      startTime: "03:30 PM",
      endTime: "04:15 PM",
      isInterval: false,
    },
  ];

  const lectures = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Ann",
      imgPath: "assets/images/masterdatapic/question.png",
    },
    {
      id: 2,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Ann",
    },
    {
      id: 3,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Ann",
    },
    {
      id: 4,
      Period: 4,
      subject: "Biology",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 5,
      Period: 5,
      subject: "History",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 6,
      Period: 6,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 7,
      Period: 7,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
  ];
  const WeeklyPeriods = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 2,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 3,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 4,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 5,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 6,
      Period: 1,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //2nd Period
    {
      id: 7,
      Period: 2,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 8,
      Period: 2,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 9,
      Period: 2,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 10,
      Period: 2,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 11,
      Period: 2,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 12,
      Period: 2,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //3rd Period
    {
      id: 13,
      Period: 3,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 14,
      Period: 3,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 15,
      Period: 3,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 16,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 17,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 18,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //4th Period
    {
      id: 19,
      Period: 4,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 20,
      Period: 4,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 21,
      Period: 4,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 22,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 23,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 24,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //5th Period
    {
      id: 25,
      Period: 5,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 26,
      Period: 5,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 27,
      Period: 5,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 28,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //6th Period
    {
      id: 30,
      Period: 6,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 31,
      Period: 6,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 32,
      Period: 6,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 33,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 34,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 35,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //7th Period
    {
      id: 36,
      Period: 7,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 37,
      Period: 7,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 38,
      Period: 7,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 39,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 40,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 41,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
  ];

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  const [selected, setSelected] = useState("");
  const [isclassteacher, setIsClassTeacher] = useState(false);
  const [Issupervisor, setIsSupervisor] = useState(false);
  const [selectReportView, setSelectReportView] = useState([]);
  const [selectCurriculumView, setSelectCurriculumView] = useState([]);
  const [selectStaffMapView, setSelectStaffMapView] = useState([]);
  const [classTeacherView, setClassTeacherView] = useState([]);

  useEffect(() => {
    ViewReportingPerson();
    ViewCurriculumTeacher();
    ViewStaffMap();
    ViewIsClassTeacherSelect();
  }, []);
  //ReportingPerson dropdown
  const ViewReportingPerson = () => {
    let Requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_REPORTINGPERSON, Requestbody)
      .then((data) => {
        const reportPersonView = data.list.map((person) => ({
          id: person.user_id,
          value: person.user_name, // Using user_name as the value
          label: person.user_name, // Using user_name as the label
        }));
        setSelectReportView(reportPersonView);
        // console.log(reportPerson, "ReportinPerson");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //TeacherCurriculum dropdown
  const ViewCurriculumTeacher = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_CURRICULUM, RequestBody)
      .then((data) => {
        const teachercurriculumview = data.list.map((curriculum) => ({
          id: curriculum.curriculum_id,
          value: curriculum.curriculum_name, // Using curriculum_name as the value
          label: curriculum.curriculum_name, // Using curriculum_name as the label
        }));
        setSelectCurriculumView(teachercurriculumview);
        console.log(teachercurriculumview, "teachercurriculum");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //UserStaffMap dropdown
  const ViewStaffMap = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_STAFF_MAP, requestBody)
      .then((data) => {
        const staffmapview = data.list.map((staff) => ({
          id: staff.user_id,
          value: staff.user_name, // Using user_name as the value
          label: staff.user_name, // Using user_name as the label
        }));
        setSelectStaffMapView(staffmapview);
        console.log(staffmapview, "teachercurriculum");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //IsClassTeacherList dropdown API same as Supervisor list and teaching grade division list
  const ViewIsClassTeacherSelect = () => {
    let classrequestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ISCLASSTEACHERLIST, classrequestbody)
      .then((data) => {
        setClassTeacherView(data?.list);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(classTeacherView, "Aanju");

  const viewTeacherApi = () => {
    let requestview = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      id: uid,
    });
    simplePostCall(ApiConfig.TEACHER_VIEW, requestview)
      .then(() => {})
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Update Teacher</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">First Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Last Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Address</label>

                      <textarea
                        className="form-control"
                        required=""
                        autocomplete="off"
                      ></textarea>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">City</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Contact Number</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Nationality</label>
                      <ReactFlagsSelect
                        className="flag"
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">User Password</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="password"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Confirm Password</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="password"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Date of Birth</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Gender</label>
                      <Select placeholder="Select Gender" options={gender} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Qualification</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Designation</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Joining Date</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Staff ID</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Upload File</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="file"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Reporting Person</label>
                      <Select
                        // onChange={}
                        options={selectReportView}
                        placeholder="Select Reporting Person"
                      />
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                    />
                    <label htmlFor="IsClassTeacher">Is Parent</label>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">User Staff Map</label>
                      <Select
                        placeholder="Select ParentStaff"
                        options={selectStaffMapView}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">Teacher Curriculum</label>
                      <Select
                        placeholder="Select Teacher Curriculum"
                        options={selectCurriculumView}
                      />
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                      onClick={() => setIsClassTeacher(!isclassteacher)}
                    />
                    <label htmlFor="IsClassTeacher">Is Class Teacher</label>
                  </div>
                  {isclassteacher ? (
                    <div className="row">
                      {classTeacherView?.map((item, index) => (
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.class_division_id}
                            />
                            <label htmlFor="GRADE9A">
                              {item.class_name}
                              {item.class_division_name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                      onClick={() => setIsSupervisor(!Issupervisor)}
                    />
                    <label htmlFor="IsClassTeacher">
                      Is Supervisor/Head Of The Department
                    </label>
                  </div>
                  {Issupervisor ? (
                    <div className="row">
                      {classTeacherView?.map((item, index) => (
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.class_division_id}
                            />
                            <label htmlFor="GRADE9A">
                              {item.class_name}
                              {item.class_division_name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="form-group col-md-6">
                    <label for="task-title">Teaching Grade - Divisions</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="IsClassTeacher"
                      />
                      <label htmlFor="IsClassTeacher">Grade 5 A </label>
                    </div>
                  </div>

                  <div style={{ marginTop: "50px" }}>
                    <SubjectTimeTableGrid data={dataGrid} />
                  </div>
                  <div className="schedulebutton">
                    <div></div>
                    <div className="">
                      <button className="btn btn-primary" type="button">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTeacher;
