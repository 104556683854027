import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Clock } from "react-feather";
import b4 from "../../../../assets/images/user/b4.png";
import ViewAssignmnet from "./ViewAssignmnet";
import Params from "../../../../config/Params";
import ApiConfig from "../../../../api/ApiConfig";
import { useTranslation } from "react-i18next";

function ViewReviewedAssignment() {
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [isTabShow, setIsTabShow] = useState(true);
  const [files, setFiles] = useState([]);
  const [isTeacherReviewActive, setIsTeacherReviewActive] = useState(true);
  const [isCheckAnswersActive, setIsCheckAnswersActive] = useState(false);

  const location = useLocation();
  const [isFromReview, setIsFromReview] = useState(
    window.location.href.split("/")[window.location.href.split("/").length - 1]
  );
  const { idy, student_id } = useParams();

  console.log(student_id, idy, "studentIds");
  useEffect(() => {
    document.title = "Edsys | Assignments Review Details";
  }, []);

  const setInitialState = () => {
    setIsTabShow(false);
  };

  const openCloseTab = () => {
    setInitialState();
    setIsTabShow(!isTabShow);
  };
  const base_url = "https://app.edsys.in:3000";
  //.......formatting time.......//

  let formattedTime = ""; // Initialize formattedTime variable

  const submissionTime = assignmentDetails?.submission_time;

  if (submissionTime) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes] = submissionTime.split(":");

    // Convert hours to 12-hour format and determine AM/PM
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    const ampm = parseInt(hours, 10) >= 12 ? "pm" : "am";

    // Construct the formatted time string
    formattedTime = `${formattedHours}:${minutes} ${ampm}`;

    console.log(formattedTime); // Output: "04:56 pm" (for the given example)
  } else {
    console.log("Submission time is undefined or null.");
  }

  //........formatted date.......//
  function formatDate(dateString) {
    const options = { month: "short", year: "numeric", weekday: "short" };
    const date = new Date(dateString);

    // Get day and suffix
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    // Format date without day, only month and year
    const monthYear = date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    // Get weekday
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    // Concatenate the formatted date parts
    return `${day}${suffix} ${monthYear}, ${weekday}`;
  }

  useEffect(() => {
    fetchAssignmentDetails();
  }, []);

  const fetchAssignmentDetails = async () => {
    console.log("workinggg iun review");
    try {
      const response = await fetch(ApiConfig.ASSIGNMENT_SUBMITVIEW, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: Params.customer_id, // Assuming Params.customer_id is a constant
          api_key: Params.api_key, // Assuming Params.api_key is a constant
          assignment_id: idy,
          student_id: student_id, // You may want to change this if it should be dynamic
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(
          "Fetched assignment details:",
          data.submitted_details.submitted_files
        );
        setAssignmentDetails(data.submitted_details);
        setFiles(data.submitted_details.submitted_files);
      } else {
        console.error("Failed to fetch assignment details");
      }
    } catch (error) {
      console.error("Error fetching assignment details:", error);
    }
  };
  const handleTeacherReviewTabClick = () => {
    setIsTeacherReviewActive(true);
    setIsCheckAnswersActive(false);
  };

  const handleCheckAnswersTabClick = () => {
    setIsTeacherReviewActive(false);
    setIsCheckAnswersActive(true);
  };
  const { t } = useTranslation();

  return (
    <div className="page-body">
      {/* <!-- Container-fluid starts--> */}

      <div className="row view-review view-assignment">
        <div className="col-12">
          <div>
            {isTeacherReviewActive && (
              <div className="card br-15">
                <div className="card-body header-card">
                  <div className="student-header">
                    <div className="details-banner">
                      <h3>{assignmentDetails?.assignment}</h3>
                      <h5>{formattedTime}</h5>
                      <div className="sub-date">
                        {formatDate(assignmentDetails?.submission_date)}
                      </div>
                    </div>
                  </div>
                  <div className="student-image">
                    <div className="student-avatar">
                      <img
                        src={base_url + assignmentDetails?.profilepicture}
                        alt="..."
                      />
                      <div className="class">{assignmentDetails?.class}</div>
                    </div>
                    <div className="student-name">
                      {assignmentDetails?.student}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className={
                    "nav-link " + (isTeacherReviewActive ? "active" : "")
                  }
                  onClick={handleTeacherReviewTabClick}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                >
                  {t("Teacher Review")}
                  <div className="media"></div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    "nav-link " + (isCheckAnswersActive ? "active" : "")
                  }
                  onClick={handleCheckAnswersTabClick}
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                >
                  {t("Teacher Review")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="card br-15">
              <div className="card-body">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={
                      "tab-pane fade " +
                      (isTeacherReviewActive ? "show active" : "")
                    }
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <h4 className="mt-1 mb-3">{t("Teacher Review")}</h4>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="card mh-145 br-15">
                          <div className="card-body">
                            <div className="t-review">
                              <div className="percent">
                                {
                                  assignmentDetails?.review_details
                                    ?.completeness
                                }
                                %
                              </div>
                              <div className="review-title">
                                {t("Completeness")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card mh-145 br-15">
                          <div className="card-body">
                            <div className="t-review">
                              <div className=" percent">
                                {" "}
                                {assignmentDetails?.review_details?.creativity}
                              </div>
                              <div className="review-title">
                                {t("Creativity")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card mh-145 br-15">
                          <div className="card-body">
                            <div className="t-review">
                              <div className="neatness">
                                {assignmentDetails?.review_details?.neatness}
                                <span>/10</span>
                              </div>
                              <div className="review-title">
                                {t("Neatness")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="card mh-145 br-15">
                          <div className="card-body">
                            <div className="t-review">
                              <div className="neatness">
                                {assignmentDetails?.review_details?.rating}
                              </div>
                              <div className="review-title">
                                {t("Overall Rating")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>{t("Comments")}</h6>
                    <p>{assignmentDetails?.review_details?.comment}</p>
                    <div className="d-flex feedback-sec">
                      <div className="fb-sec flex-grow-1">{t("Feedback")}</div>
                      <div className="fb-status">
                        {" "}
                        {assignmentDetails?.review_details?.grade}
                      </div>
                    </div>
                  </div>
                  {isCheckAnswersActive && (
                    <div className="file-content">
                      <ViewAssignmnet
                        onClose={handleTeacherReviewTabClick}
                        idy={idy}
                        student_id={student_id}
                      />
                      {/* <h2>hbvbvhjbvhjbfb</h2> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default ViewReviewedAssignment;
