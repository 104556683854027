import React, { useEffect, useState } from "react";
import Select from "react-select";

import SubjectTimeTableGrid from "./subjecttimetable/SubjectTimeTableGrid";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditSubjects() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ids = params.get("message");
  console.log(ids, "id vannu");
  const selecttype = [
    { value: "Optional", label: "Optional" },
    { value: "Mandatory", label: "Mandatory" },
  ];

  const Periods = [
    {
      id: 1,
      PeriodId: 1,
      startTime: "09:30 AM",
      endTime: "10:15 AM",
      isInterval: false,
    },
    {
      id: 2,
      PeriodId: 2,
      startTime: "10:15 AM",
      endTime: "11:00 AM",
      isInterval: false,
    },
    {
      id: 3,
      PeriodId: 0,
      startTime: "11:00 AM",
      endTime: "11:15 AM",
      isInterval: true,
    },
    {
      id: 4,
      PeriodId: 3,
      startTime: "11:15 AM",
      endTime: "12:00 PM",
      isInterval: false,
    },
    {
      id: 5,
      PeriodId: 4,
      startTime: "12:00 PM",
      endTime: "12:45 PM",
      isInterval: false,
    },
    {
      id: 6,
      PeriodId: 0,
      startTime: "12:45 PM",
      endTime: "01:45 PM",
      isInterval: true,
    },
    {
      id: 7,
      PeriodId: 5,
      startTime: "01:45 PM",
      endTime: "02:30 PM",
      isInterval: false,
    },
    {
      id: 8,
      PeriodId: 6,
      startTime: "02:30 PM",
      endTime: "03:15 PM",
      isInterval: false,
    },
    {
      id: 9,
      PeriodId: 0,
      startTime: "03:15 PM",
      endTime: "03:30 PM",
      isInterval: true,
    },
    {
      id: 10,
      PeriodId: 7,
      startTime: "03:30 PM",
      endTime: "04:15 PM",
      isInterval: false,
    },
  ];

  const lectures = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Ann",
      imgPath: "assets/images/masterdatapic/question.png",
    },
    {
      id: 2,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Ann",
    },
    {
      id: 3,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Ann",
    },
    {
      id: 4,
      Period: 4,
      subject: "Biology",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 5,
      Period: 5,
      subject: "History",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 6,
      Period: 6,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 7,
      Period: 7,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
  ];
  const WeeklyPeriods = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 2,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 3,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 4,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 5,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 6,
      Period: 1,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //2nd Period
    {
      id: 7,
      Period: 2,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 8,
      Period: 2,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 9,
      Period: 2,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 10,
      Period: 2,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 11,
      Period: 2,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 12,
      Period: 2,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //3rd Period
    {
      id: 13,
      Period: 3,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 14,
      Period: 3,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 15,
      Period: 3,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 16,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 17,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 18,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //4th Period
    {
      id: 19,
      Period: 4,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 20,
      Period: 4,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 21,
      Period: 4,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 22,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 23,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 24,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //5th Period
    {
      id: 25,
      Period: 5,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 26,
      Period: 5,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 27,
      Period: 5,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 28,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //6th Period
    {
      id: 30,
      Period: 6,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 31,
      Period: 6,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 32,
      Period: 6,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 33,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 34,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 35,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //7th Period
    {
      id: 36,
      Period: 7,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 37,
      Period: 7,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 38,
      Period: 7,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 39,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 40,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 41,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
  ];

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  const [editImage, setEditImage] = useState([]);
  const [viewData, setViewData] = useState();
  const [showcode, setShowcode] = useState("");
  const [showtype, setShowtype] = useState("");
  const [showname, setShowname] = useState("");
  const [showEditImage, setShowEditImage] = useState("");
  useEffect(() => {
    ViewMode();
  }, []);
  const ViewMode = () => {
    let requestedit = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      id: ids,
    });
    simplePostCall(ApiConfig.SUBJECT_VIEW, requestedit)
      .then((data) => {
        setViewData(data?.data);
        setShowcode(data?.data[0]?.subject_code);
        setShowtype(data?.data[0]?.subject_type);
        setShowname(data?.data[0]?.subject_name);
        setShowEditImage(data?.data[0]?.subject_icon);
      })

      .catch((error) => {
        console.log(error, "error");
      });
  };
  // console.log(viewData, "editingg", showcode);
  const { t } = useTranslation();
  useEffect(() => {
    IconEdit();
  }, []);
  const IconEdit = () => {
    let editIcon = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.SUBJECT_IMAGE, editIcon)
      .then((data) => {
        setEditImage(data?.data);
        console.log(data, "dataedit");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const UpdateMode = () => {
    let requestupdate = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      subject_code: showcode,
      subject_name: showname,
      subject_type: showtype,
      subject_icon: showEditImage,
      id: ids,
    });
    simplePostCall(ApiConfig.SUBJECT_UPDATE, requestupdate)
      .then((data) => {})
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">{t("Upadte Subjects")} :{showname}</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Subject Code")}</label>
                      <input
                        value={showcode}
                        onChange={(e) => {
                          setShowcode(e.target.value);
                        }}
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Subject Name")}</label>
                      <input
                        value={showname}
                        onChange={(e) => {
                          setShowname(e.target.value);
                        }}
                        className="datepicker-here form-control digits"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Type")}</label>

                      <Select
                        placeholder={showtype}
                        options={selecttype}
                        onChange={(e) => setShowtype(e.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">{t("Select Subject Icon")}</label>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      overflow: "hidden",
                      overflowY: "scroll",
                      height: "200px",
                    }}
                  >
                    {editImage.map((item) => (
                      <div className="col-lg-2">
                        <div
                          className="image_div"
                          onClick={() =>
                            setShowEditImage(
                              item.subject_icon_url
                                ? item.subject_icon_url
                                : showEditImage,
                            )
                          }
                        >
                          <img
                            src={
                              ApiConfig.NODE_URL + "/" + item.subject_icon_url
                            }
                            width={60}
                            height={60}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <SubjectTimeTableGrid data={dataGrid} />
                  </div>
                  <div className="schedulebutton">
                    <div></div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          UpdateMode();
                        }}
                      >
                        {t("Update")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSubjects;
