import React, { useState, useEffect } from "react";
import Syllabus_data from "../../../api/Syllabus_data.json";
import SyllabusModal from "./SyllabusModal";
import { Book, PlusCircle } from "react-feather";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { TfiMenuAlt } from "react-icons/tfi";
import { LiaEdit } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import NewSyllabusSkelton from "./NewSyllabusSkelton";
import ReactTooltip from "react-tooltip";

const NewSyllabus = () => {
  const { t } = useTranslation();
  const navic = useNavigate();

  const [isTodoModelShow, setisTodoModelShow] = useState(false);
  const [isTagModelShow, setisTagModelShow] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [lessonId, setLessonId] = useState("");
  const [tableDatas, setTableDatas] = useState([]);
  const [tableDataStores, settableDataStores] = useState("");
  const [curriculumState, setCurriculumState] = useState([]);
  const [CurriculamsListss, setCurriculamsListss] = useState("");
  const [classname, setClassname] = useState([]);
  const [selectedclass, setSelectedclass] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [stSubject, setstSubject] = useState("");
  const [storedata, setstoredata] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sybname, setsybname] = useState("");

  const [state, setState] = useState({
    subject: "",
    class_division_id: "",
    class_id: "",
  });
  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
    loadClassList();
    SyllabusListTable();
    getCurriculumList();
    classListss();
  }, [CurriculamsListss, stSubject, selectedclass, sybname]);
  useEffect(() => {}, [subjectList]);
  const Showmodal = () => {
    setisTodoModelShow(true);
  };

  const openCloseTodoModel = () => {
    setisTodoModelShow(false);
  };
  const openCloseTagModel = () => {
    setisTodoModelShow(false);
  };

  function loadClassList() {
    simplePostCall(
      ApiConfig.CLASSES_LIST_API,
      JSON.stringify({ ...Params, user_id: Params.teacher_id })
    )
      .then((data) => {
        if (data.result) {
          setClassList(data.list);

          //   setState({ ...state, class_id: data.list[0].class_id })
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  function deleteData() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_id: storedata,
    });
    simplePostCall(ApiConfig.SYLLABUS_DELETE_DATA, newRequestBody)
      .then((data) => {
        // setState({ ...state, subject: data.list[0].id });
        toast.error("deleted successfully");
        SyllabusListTable();
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function loadSubjectList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.SUBJECT_IN_SYLLABUS, newRequestBody)
      .then((data) => {
        // setState({ ...state, subject: data.list[0].id });
        setSubjectList(data.list);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const onInputChangeHandle = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const { name, value } = e.target;
    setState({ ...state, class_id: value });
    loadSubjectList(value);
  };
  const onInputChangeHandleLessons = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const { name, value } = e.target;
    setLessonId(option);

    if (name === "id") getLessons(state.class_id, value, option);
  };

  function getLessons(class_id = "", subject_id = "", lesson_id = "") {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      subject_id: subject_id,
      class_id: class_id,
      lesson_id: lesson_id,
      teacher_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.LESSONS_IN_SYLLABUS, newRequestBody)
      .then((data) => {
        setLesson(data.list);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const storedatas = (selects) => {
    settableDataStores({ label: selects.label });
  };

  const SyllabusListTable = () => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      syllabus_name: sybname,
      class_id: selectedclass,
      subject_id: stSubject,
      academic_year_id: Params.academic_year_id,
      curriculum_id: CurriculamsListss,
    });
    simplePostCall(ApiConfig.SYLLABU_TABLE_LIST, newRequestBody)
      .then((data) => {
        // setClassess(data.list);
        console.log(data, "Table data");
        if (data.result) {
          setIsLoading(false);
          var fullData = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              fullData.push({
                sylabel: clases.syllabus_name,
                accyear: clases.academic_year_name,
                curric: clases.curriculum_name,
                classNames: clases.class_name,
                syllabusId: clases.syllabus_id,
                Subject_name: clases.subject_name,
              });
            });
          }

          setTableDatas({ ...tableDatas, fullData: fullData });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("api response", error);
        setIsLoading(false);
      });
  };
  // ---------Curriculam ----------
  const onCurriculamChange = (curriculums) => {
    setCurriculamsListss(curriculums.value);
  };
  // ------------------------------
  // <-------------Syllabus/curriculum Api---------->

  function getCurriculumList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        console.log(data, "YEAR data Lists");
        if (data.result) {
          var curriculums = [];
          if (data?.curriculum_list.length > 0) {
            data.curriculum_list.map((clases, index) => {
              console.log(clases, "curicullam");
              curriculums.push({
                label: clases.curriculum_name,
                value: clases.curriculum_id,
              });
            });
          }
          console.log(curriculums, "curriculums");
          setCurriculumState({ ...curriculumState, curriculums: curriculums });
          console.log(curriculumState, "AccYear");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ----------------------------------------------
  // ---------classListApi----------
  function classListss() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        // setClassess(data.list);
        console.log(data, "data Lists");
        if (data.result) {
          var classlists = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              classlists.push({
                label: clases.class_name,
                value: clases.class_division_id,
                class_id: clases.class_id,
              });
            });
          }
          setClassname({ ...classname, classlists: classlists });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // -----------------------------------
  // ---------classList----------
  function onGradeChange(selected) {
    console.log(selected, "selected");
    let temp = selected.value;
    setSelectedclass(selected.class_id);
    subjectListss(temp);
  }
  // ------------------------------
  // ---------Subject Api----------
  function subjectListss(class_id) {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: class_id,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data, "subjects");
        if (data.result) {
          var subjectlist = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              subjectlist.push({
                label: clases.subject_name,
                value: clases.subject_id,
              });
            });
          }
          setSubjects({ ...subjects, subjectlist: subjectlist });
          console.log(subjects, "subjects");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ---------Subject----------
  const onSubjectChange = (subjectList) => {
    setstSubject(subjectList.value);
    console.log(subjectList);
  };
  // ------------------------------

  return (
    <>
      {isLoading ? (
        <NewSyllabusSkelton />
      ) : (
        <div className="row syllabus-sec">
          <div className="col-md-12">
            <div className="card br-15">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">{t("Syllabus")}</h5>

                  <div className="btn-edsys-sec">
                    <Link to={"/SyllabusCreate"} className="">
                      <div className="btnn">{t("Syllabus")}</div>
                    </Link>
                  </div>

                  <a
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => navic("/create-Syllabus-modals")}
                    data-bs-target="#exampleModal"
                    className="btn btn-edsys-primary"
                  >
                    <PlusCircle className="me-2" />
                    <span>{t("Create Lesson Plan")}</span>
                  </a>
                  {/* {Modal Start} */}
                  {/* {isTodoModelShow ? (
                  <SyllabusModal onClose={openCloseTodoModel} />
                ) : null} */}
                  {/* {isTagModelShow ? (
                  <SyllabusModal onClose={openCloseTagModel} />
                ) : null} */}
                  {/* modal End */}
                </div>
              </div>

              <div className="syllabus-pro1">
                <div className="tableCreate">
                  <div className="table-responsive">
                    <table class="table table-dark table-borderless newTable ">
                      <thead className="tableHead">
                        <th>#</th>
                        <th className="sYna">{t("Syllabus Name")}</th>
                        <th>{t("Academic Year")}</th>
                        <th>{t("Syllabus")}</th>
                        <th>{t("Class")}</th>
                        <th>{t("Subject")}</th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <div>
                              <input
                                type="text"
                                className="Sytetxinp"
                                onChange={(e) => setsybname(e.target.value)}
                              />
                            </div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div>
                              {/* syllabus */}
                              <Select
                                class="js-example-basic-single form-select "
                                aria-label="Default select example"
                                value={curriculumState.label}
                                onChange={onCurriculamChange}
                                options={curriculumState?.curriculums}
                              ></Select>
                            </div>
                          </td>
                          <td>
                            <div>
                              {/* class */}
                              <Select
                                class="js-example-basic-single form-select "
                                aria-label="Default select example"
                                value={classname.label}
                                onChange={onGradeChange}
                                options={classname.classlists}
                              ></Select>
                            </div>
                          </td>
                          <td>
                            <div>
                              {/* subject */}
                              <Select
                                class="js-example-basic-single form-select "
                                aria-label="Default select example"
                                value={subjects.label}
                                onChange={onSubjectChange}
                                options={subjects.subjectlist}
                              ></Select>
                            </div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                        </tr>
                        {tableDatas.fullData?.map((item, index) => (
                          <tr className="secroW tab-text-color">
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {index + 1}
                            </td>
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {item.sylabel}
                            </td>
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {item.accyear}
                            </td>
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {item.curric}
                            </td>
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {item.classNames}
                            </td>
                            <td style={{ color: "#666970", fontWeight: "500" }}>
                              {item.Subject_name}
                            </td>
                            <td>
                              <div className="iconetable">
                                <div className="menuicone">
                                  <TfiMenuAlt
                                    style={{ height: "24px", color: "white" }}
                                    data-tip="Lesson Topics & Subtopics"
                                    data-place="top"
                                    onClick={() =>
                                      navic("/ViewSyllabusData", {
                                        state: item.syllabusId,
                                      })
                                    }
                                  />
                                  <ReactTooltip effect="solid" />
                                </div>
                                <div className="menuicone1 ">
                                  <LiaEdit
                                    style={{ height: "24px", color: "white" }}
                                    data-tip="Edit"
                                    data-place="top"
                                    onClick={() =>
                                      navic("/syllabus-Edit", {
                                        state: item.syllabusId,
                                      })
                                    }
                                  />
                                </div>

                                <button
                                  type="button"
                                  className="menuicone2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                  data-tip="Delete"
                                  data-place="top"
                                  onClick={() => setstoredata(item.syllabusId)}
                                >
                                  {console.log(storedata, "store datass...")}
                                  <RiDeleteBin6Line
                                    style={{ height: "24px", color: "white" }}
                                    data-tip="Delete"
                                    data-place="top"
                                  />
                                </button>
                                <div
                                  class="modal fade"
                                  id="staticBackdrop"
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabindex="-1"
                                  aria-labelledby="staticBackdropLabel"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h1
                                          class="modal-title fs-5"
                                          id="staticBackdropLabel"
                                        ></h1>
                                        <button
                                          type="button"
                                          class="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div class="modal-body">
                                        {t(
                                          "Are you sure you want to delete this item ?"
                                        )}
                                      </div>
                                      <div class="modal-footer">
                                        <button
                                          type="button"
                                          class="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          {t("Close")}
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          data-bs-dismiss="modal"
                                          onClick={() => deleteData()}
                                        >
                                          {t("Delete")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewSyllabus;
