import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Params from "../config/Params";
import { Video } from "react-feather";
import s_topicicon from "../assets/images/svg/s-topicicon.svg";
import topicicon from "../assets/images/svg/topicicon.svg";
import { X, Check } from "react-feather";
import moment from "moment";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function LessonsSlider(props) {
  // console.log(props, "new slider");
  var i = 0;

  const [rightMenuState, setRightMenuState] = useState({
    showRightMenu: false,
    rightMenuOf: 1,
  });
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"));
  const [lesson, setLesson] = useState([]);
  const [timetableDataState, setTimetableDataState] = useState({
    timetable_data: [],
    initialSlide: null,
  });

  function openRightMenu({
    rightMenuOf: rightMenuOf,
    timetable: timetable,
    index: index,
  }) {
    console.log(rightMenuOf, "rightMenuOf", timetable);
    if (rightMenuOf == index + 1) {
      console.log(timetable[index], "lesson_name");
      setTimetableDataState({
        ...timetableDataState,
        lesson_name: timetable[index]?.syllabus.lesson_name,
        topic_name: timetable[index]?.syllabus.topic_name,
        sub_topic_name: timetable[index]?.syllabus.sub_topic_name,
        lesson_id: timetable[index]?.syllabus.lesson_id,
        topic_id: timetable[index]?.syllabus.topic_id,
        sub_topic_id: timetable[index]?.syllabus.sub_topic_id,
        section_id: timetable[index]?.section_id,
        subject_id: timetable[index]?.subject_id,
      });
    }

    setRightMenuState({
      showRightMenu: true,
      rightMenuOf: rightMenuOf,
    });
  }

  const checkRightMenu = ({
    lesson_id: lesson_id,
    topic_id: topic_id,
    sub_topic_id: sub_topic_id,
    section_id: section_id,
    subject_id: subject_id,
  }) => {
    setRightMenuState({
      showRightMenu: false,
      rightMenuOf: 1,
    });
    completeSyllabus({
      lesson_id: lesson_id,
      topic_id: topic_id,
      sub_topic_id: sub_topic_id,
      section_id: section_id,
      subject_id: subject_id,
    });
  };
  const ignoreRightMenu = () => {
    setRightMenuState({
      showRightMenu: false,
      rightMenuOf: 1,
    });
  };
  const subjectSyllabus = async () => {
    let Today = today;
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      date: Today,
    });
    console.log(ApiConfig.SUBJECTLIST, newRequestBody, "lessonslider");
    simplePostCall(ApiConfig.SUBJECTLIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log(data.timetable_details, "propsss");
          setLesson(data.timetable_details);
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const completeSyllabus = ({
    lesson_id: lesson_id,
    topic_id: topic_id,
    sub_topic_id: sub_topic_id,
    section_id: section_id,
    subject_id: subject_id,
  }) => {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      lesson_id: lesson_id,
      topic_id: topic_id,
      sub_topic_id: sub_topic_id,
      division_id: section_id,
      subject_id: subject_id,

      // date: today,
    });
    console.log(ApiConfig.SYLLABUS_COMPLETE_API, newRequestBody, "qwerty");
    simplePostCall(ApiConfig.SYLLABUS_COMPLETE_API, newRequestBody)
      .then((data) => {
        if (data.result == true) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  useEffect(() => {
    console.log(props, "propsss");
    subjectSyllabus();
    setTimetableDataState({
      timetable_data: props?.timetable_data,
      initialSlide: props?.initial_slide,
      timetable: props?.timetable_data,
    });
  }, [props?.initial_slide]);
  const { t } = useTranslation();

  return (
    <>
      {/* Right Menu starts */}
      <div
        class={
          "edsys-sidebar edsys-bar-block card edsys-animate-right subject-" +
          rightMenuState.rightMenuOf
        }
        style={{
          display: rightMenuState.showRightMenu == true ? "block" : "none",
          right: 0,
        }}
        id="rightMenu"
      >
        <div class="card-body">
          <div class="title">
            {t("Have you completed following topic?")}
            <table class="lesson-table">
              <tbody>
                <tr>
                  <td>
                    <img src={s_topicicon} />
                  </td>
                  <td>{t("Lesson")}</td>
                  <td>
                    :
                    <span>
                      {timetableDataState?.lesson_name
                        ? timetableDataState?.lesson_name
                        : "N.A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={topicicon} />
                  </td>
                  <td>{t("Topic")}</td>
                  <td>
                    :
                    <span>
                      {timetableDataState?.topic_name
                        ? timetableDataState?.topic_name
                        : "N.A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={s_topicicon} />
                  </td>
                  <td>{t("Sub Topic")}</td>
                  <td>
                    :
                    <span>
                      {timetableDataState?.sub_topic_name
                        ? timetableDataState?.sub_topic_name
                        : "N.A"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex">
              <div onClick={ignoreRightMenu} class="ignore-btn">
                {t("Ignore")}
              </div>
              <div onClick={ignoreRightMenu} class="cancel-btn">
                <X />
              </div>
              <div
                class="proceed-btn"
                onClick={() =>
                  checkRightMenu({
                    lesson_id: timetableDataState?.lesson_id,
                    topic_id: timetableDataState?.topic_id,
                    sub_topic_id: timetableDataState?.sub_topic_id,
                    section_id: timetableDataState?.section_id,
                    subject_id: timetableDataState?.subject_id,
                  })
                }
              >
                <Check />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Right Menu Ends */}

      <div className="row">
        <div className="col-md-12 subject-sec">
          <main className="main">
            <div className="container">
              {timetableDataState.timetable_data &&
              timetableDataState.timetable_data.length > 0 ? (
                <Swiper
                  className="scroll"
                  spaceBetween={0}
                  slidesPerView={2.5}
                  initialSlide={
                    timetableDataState.initialSlide
                      ? parseInt(timetableDataState.initialSlide)
                      : 0
                  }
                >
                  {timetableDataState.timetable_data &&
                  timetableDataState.timetable_data.length > 0
                    ? timetableDataState.timetable_data.map(
                        (timetable, index) => {
                          i = i == 3 ? 1 : i + 1;
                          var rightMenuOf = i;
                          return (
                            <SwiperSlide
                              className={"scroll-container sub-" + i}
                              key={index}
                            >
                              <Link
                                to={{
                                  pathname: "/studentAttendance",
                                  state: {
                                    classId: [
                                      timetable.timetable_class_division_id,
                                    ],
                                  },
                                }}
                                state={{ message: "hi" }}
                              >
                                <div
                                  onClick="location.href='attendance.html';"
                                  className="head-block"
                                  data-tip="dsfdgg"
                                >
                                  <div className="class">
                                    {timetable.class_division_display_name}
                                    {/*<span>th</span>*/}
                                  </div>

                                  <div className="period">{t("Period")}</div>

                                  <div className="period">
                                    <span>{timetable.period_name}</span>
                                  </div>
                                </div>
                              </Link>
                              <div className="scroll-container__text">
                                <div className="d-flex">
                                  <h5
                                    onClick="location.href='attendance.html';"
                                    className="flex-grow-1"
                                  >
                                    {timetable.subject_name}
                                  </h5>

                                  {/* <Link
                                      to={"/conference"}
                                      state={{
                                        class_division_id:
                                          timetable.timetable_class_division_id,
                                        jitsi_domain: props.jitsi_domain,
                                      }}
                                      style={{
                                        display:
                                          timetable.video_class === true
                                            ? ""
                                            : "none",
                                      }}
                                      className="video-conf"
                                    >
                                      <Video />
                                    </Link> */}
                                </div>
                                <p>
                                  {timetable.start_time} - {timetable.end_time}
                                </p>
                                <div className="row">
                                  <div className="col-8">
                                    <h6>
                                      {timetable.syllabus.lesson_name
                                        ? timetable.syllabus.lesson_name
                                        : "NA"}
                                    </h6>
                                  </div>
                                  <div className="col-4">
                                    <div
                                      onClick={() =>
                                        openRightMenu({
                                          index: index,
                                          rightMenuOf: rightMenuOf,
                                          timetable:
                                            timetableDataState.timetable_data,
                                        })
                                      }
                                      className="detail"
                                    >
                                      <i className="icofont icofont-book-alt"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        }
                      )
                    : null}
                  <SwiperSlide
                    key={timetableDataState.timetable_data.length}
                  ></SwiperSlide>
                </Swiper>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default LessonsSlider;
