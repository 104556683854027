import React, { useEffect, useState } from "react";
import "../../assets/css/studentlist.css";
import "../../assets/css/uistyles.css";
import FileSaver from "file-saver";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import algebra from "../../assets/images/masterdatapic/algebra.png";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useTranslation } from "react-i18next";

function Subjects() {
  const { t } = useTranslation();
  const [subjectcode, setSubjectCode] = useState("");
  const [subjects, setSubjects] = useState("");
  const [subjecttype, setSubjectType] = useState("");
  const [subjListapi, setSubjListapi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showId, setShowId] = useState("");
  const [showAfterDelete, setShowAfterDelete] = useState("");

  const clearFields = () => {
    setSubjectCode("");
    setSubjects("");
    setSubjectType("");
  };

  const handlePageChange = () => {
    // console.log("buttonpressed");
    if (nextPage != false) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (nextPage !== false) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    studentlist();
  }, [subjects, subjectcode, subjecttype, currentPage, showAfterDelete]);
  const studentlist = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      subject_code: subjectcode,
      subject_name: subjects,
      type: subjecttype,

      page: Number(currentPage),
      limit: 20,
      language: "",
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, requestbody)
      .then((data) => {
        setSubjListapi(data?.data);
        setNextPage(data?.next_page);

        // console.log(data, "dataarray");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  //  console.log(nextPage, "fulldata");

  const studentexcel = (studown) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      format: studown,
    });
    simplePostCall(ApiConfig.SUBJECT_LIST, requestBody)
      .then((data) => {
        if (data) {
          FileSaver.saveAs(data?.file_path);
        } else {
          console.log("not working");
        }

        // console.log(data, "dataarray");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const deleteid = (id) => {
    setShowId(id);
  };
  // console.log(showId, "ffgfgfgfgfggfgfgf");
  const deletedata = (id) => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      id: id,
    });
    simplePostCall(ApiConfig.SUBJECT_DELETE, requestBody)
      .then((data) => {
        setShowAfterDelete(data);
        console.log(data, "deletesuccess");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">{t("Subjects")}</h5>
            <div className="tablebuttonview">
              <a
                href="/CreateSubjects"
                type="button"
                className="btn timetablebutton "
              >
                <span>{t("Create subject")}</span>
              </a>
              <a
                href="/ImportSubjects"
                type="button"
                className="btn timetablebutton"
              >
                <span>{t("Import")}</span>
              </a>
              <a
                className="btn timetablebutton dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("Export")}
              </a>

              <span class="visually-hidden">{t("Toggle Dropdown")}</span>

              <ul class="dropdown-menu">
                <li
                  onClick={() => {
                    studentexcel("pdf");
                  }}
                >
                  <a class="dropdown-item datasize" href="#">
                  {t("Save as PDF")} 
                  </a>
                </li>
                <li
                  onClick={() => {
                    studentexcel("excel");
                  }}
                >
                  <a class="dropdown-item datasize">{t("Export to Excel")}</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("Subject")}</th>
                    <th scope="col">{t("Subject Code")}</th>
                    <th scope="col">{t("Type")}</th>
                    <th scope="col">{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder={t("Search")}
                        value={subjects}
                        onChange={(e) => setSubjects(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder={t("Search")}
                        value={subjectcode}
                        onChange={(e) => setSubjectCode(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder={t("Search")}
                        value={subjecttype}
                        onChange={(e) => setSubjectType(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                         {t("Clear")}
                        </button>
                      </div>
                    </th>
                  </tr>
                  {subjListapi.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          {index + 1 + 20 * (currentPage - 1)}
                        </th>
                        <td>
                          <div className="subject_image_div">
                            <img
                              src={
                                item.subject_icon
                                  ? ApiConfig.NODE_URL + "/" + item.subject_icon
                                  : algebra
                              }
                              className="subject_image"
                            />
                            {item.subject_name}
                          </div>
                        </td>
                        <td>{item.subject_code}</td>
                        <td>{item.subject_type}</td>
                        <td>
                          <div className="buttons">
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                 {t("Edit")} 
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/EditSubjects?message=${encodeURIComponent(
                                  item.subject_id
                                )}`}
                              >
                                <div className="view">
                                  <AiTwotoneEdit className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                              key={item.number}
                              placement="top"
                              overlay={
                                <Tooltip id={"tooltip-${item.number}"}>
                                 {t("Delete")} 
                                </Tooltip>
                              }
                            >
                              <Link to="">
                                <div
                                  className="view"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    deleteid(item.subject_id);
                                  }}
                                >
                                  <RiDeleteBin6Fill className="image" />
                                </div>
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="showingnumber">
                <div></div>
                <nav aria-label="Pagination">
                  <ul className="pagination">
                    <li
                      className={`page - item${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        &laquo;
                      </button>
                    </li>
                    <button
                      className="page-link"
                      style={{ backgroundColor: "#adc927" }}
                      onClick={() => handlePageChange()}
                    >
                      {currentPage}
                    </button>

                    <li
                      className={`page-item ${
                        currentPage === currentPage - 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handleNextPage}
                        disabled={currentPage === currentPage - 1}
                        aria-label="next"
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Modal Start */}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        dialogClassName=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
          {t("Delete Confirmation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
          {t("Are you sure you want to delete")} ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button
              onClick={() => setShowDeleteModal(false)}
              className="dlt-btn-1"
            >
            {t("Cancel")} 
            </button>
            <button
              onClick={() => {
                setShowDeleteModal(false);
                deletedata(showId);
              }}
              type="submit"
              className="dlt-btn-2"
              value="delete"
            >
            {t("Delete")} 
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal End */}
    </>
  );
}

export default Subjects;
