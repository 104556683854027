import React, { useEffect, useState } from "react";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import moment from "moment";
import SpinnerCmp from "../../../components/SpinnerCmp";
import { useTranslation } from "react-i18next";
import TablesSkeleton from "../../../components/skeletons/TablesSkeleton";

const PresentTab = (props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [periodList, setPeriodList] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [secId, setSecId] = useState("");
  const [clId, setClId] = useState("");
  const [presentAttendanceList, setPresentAttendanceList] = useState([]);
  const [selectedPeriodValue, setSelectedPeriodValue] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [attendance_date, setAttendance_date] = useState({
    today_data: "",
  });
  const [dateData, setDataData] = useState("");
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSecationId, setSelectedSecationId] = useState("");
  const [periodListData, setPeriodListData] = useState([]);

  const [selectProidID, setSelectProidID] = useState("");

  useEffect(() => {
    const today = (function () {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      return `${now.getFullYear()}-${month}-${day}`;
    })();
    var result;
    if (attendance_date.today_data === "") {
      result = today;
    } else {
      result = attendance_date.today_data;
    }

    setDataData(result);
  }, [attendance_date]);

  useEffect(() => {
    if (dateData) {
      getTimeTable();
    }
  }, [dateData]);

  useEffect(() => {
    if (selectedClassId) {
      getPresentAttendance();
    }
  }, [selectedClassId]);
  // useEffect(() => {
  //   if (present_date) {
  //     getTimeTable();
  //     getPresentAttendance();
  //   }
  // }, [selectSubjectValue, present_date]);

  const onInputChangeHandle = (e) => {
    const { name, value } = e.target;
    setSelectedPeriodValue({
      selectedPeriodValue: e?.target?.value,
    });
    getPresentAttendance(value);
    setSecId(JSON.parse(value));
  };

  const getTimeTable = () => {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      date: dateData,
    };
    simplePostCall(ApiConfig.TIMETABLE, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          setPeriodList(data.timetable_details);
        } else setIsLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getPresentAttendance = (value) => {
    setIsLoading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: clId ? clId : selectedClassId,
      section_id: sectionId ? sectionId : selectedSecationId,
      period_id: value ? JSON.parse(value) : selectProidID,
      date: dateData,
    };
    console.log(newRequestBody, ApiConfig.PRESENT_STUDENT, "newreqestbody");
    simplePostCall(ApiConfig.PRESENT_STUDENT, JSON.stringify(newRequestBody))
      .then((data) => {
        setPresentAttendanceList(data.list);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    let choiceArry = [];

    let PeriodData = periodList.map((cartData) => {
      return cartData;
    });
    let FilterData = PeriodData.map((value, index) => {
      choiceArry.push(value.period_no);
    });

    let subjectData = periodList.map((cartData) => {
      return cartData.teacher_subject;
    });
    var CurrentSection = periodList.filter((item) => {
      const match = item.period_no.period_id === secId;
      if (match) {
        setSectionId(item.teacher_subject.section_id);
        setClId(item.teacher_subject.class_id);
        console.log("Additional Data:", item);
      }
      return match;
    });
    var CurrentData = choiceArry.filter((item) => item);
    setSelectProidID(CurrentData[0] && CurrentData[0].period_id);
    setSelectedSecationId(subjectData[0] && subjectData[0].section_id);
    setSelectedClassId(subjectData[0] && subjectData[0].class_id);
    setPeriodListData(CurrentData);
  }, [periodList, selectedPeriodValue]);

  const takeAttendance = (id, attendance) => {
    setIsLoading(true);

    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      attendance_id: id,
      status: attendance,
    };
    simplePostCall(ApiConfig.TAKE_ATTENDANCE, JSON.stringify(newRequestBody))
      .then((data) => {
        getPresentAttendance();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <div>
      <div className="form-row row">
        <div class="form-group col-md-6">
          <label for="task-title">{t("Select Date")}</label>
          <input
            class="datepicker-here form-control digits"
            type="date"
            data-language="en"
            placeholder="MM-DD-YYYY"
            data-position="top left"
            name="submission_date"
            // min={moment().format("YYYY-MM-DD")}
            value={dateData}
            onChange={(e) =>
              setAttendance_date({
                ...attendance_date,
                today_data: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="task-title">{t("Select Period")}</label>
          <select
            //   className="js-example-basic-single form-select"
            className="form-select"
            onChange={onInputChangeHandle}
            name="selectClassId"
            disabled={
              periodListData && periodListData.length > 0 ? false : true
            }
          >
              <option value="">{t("Please Select Period")}</option>
            {periodListData && periodListData.length > 0
              ? periodListData.map((data, index) => {
                  return (
                    <>
                      <option
                        selected
                        key={"data" + index}
                        value={data.period_id}
                      >
                        {data.period_name}
                      </option>
                    </>
                  );
                })
              : null}
          </select>
        </div>
      </div>
      <>
        {isLoading ? (
          <TablesSkeleton />
        ) : (
          <div class="atnd-scroll">
            <div class="std-container">
              <ul class="responsive-table">
                {presentAttendanceList && presentAttendanceList.length > 0 ? (
                  presentAttendanceList.map((studData, index) => {
                    return (
                      <li class="table-row" key={"studData" + index}>
                        <div
                          class="col col-1 avatar"
                          data-label="Student Image"
                        >
                          <img
                            src={
                              ApiConfig.BASE_URL +
                              studData.student_profilepicture
                            }
                          />
                        </div>
                        <div
                          class="col col-8 std-name"
                          data-label="Student Name"
                        >
                          {studData.student_name}
                        </div>
                        <div class="switch-toggle switch-3 switch-candy">
                          <div className="col d-flex justify-content-between">
                            <button
                              className={
                                studData.attendance_classroom_status ===
                                "PRESENT"
                                  ? "present_btn  present_btn1 m-2"
                                  : "present_btn"
                              }
                              style={{
                                color:
                                  studData.attendance_classroom_status ===
                                  "PRESENT"
                                    ? "#fff"
                                    : "#747474",
                              }}
                              onClick={() =>
                                setAttendanceStatus(
                                  {
                                    attendanceStatus: "PRESENT",
                                  },
                                  takeAttendance(
                                    studData.attendance_classroom_id,
                                    "PRESENT",
                                  ),
                                )
                              }
                            >
                              {t("Present")}
                            </button>
                            <button
                              className={
                                studData.attendance_classroom_status ===
                                "ABSENT"
                                  ? "absent_btn  absent_btn1 m-2"
                                  : "absent_btn"
                              }
                              style={{
                                color:
                                  studData.attendance_classroom_status ===
                                  "ABSENT"
                                    ? "#fff"
                                    : "#747474",
                              }}
                              onClick={() =>
                                setAttendanceStatus(
                                  {
                                    attendanceStatus: "ABSENT",
                                  },
                                  takeAttendance(
                                    studData.attendance_classroom_id,
                                    "ABSENT",
                                  ),
                                )
                              }
                            >
                              {t("Absent")}
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <p className="d-flex justify-content-center text-danger">
                    {t("No Present Student Found")}
                  </p>
                )}
              </ul>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
export default PresentTab;
