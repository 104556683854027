import React from "react";
import "../../../assets/css/LoadingModal.css"; // Optional: For custom styling
import { useTranslation } from "react-i18next";

const LoadingModal = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-modal">
      <div className="loading-modal-content">
        <div className="spinner"></div> {/* Optional: Add a spinner */}
        <p>{t("Loading...")}</p>
      </div>
    </div>
  );
};

export default LoadingModal;
