import React, { useEffect, useState } from "react";
import active_like_btn from "../../assets/images/active_like_btn.svg";
import like_btn from "../../assets/images/like_btn.svg";
import part_1 from "../../assets/images/part_1.svg";
import part_2 from "../../assets/images/part_2.svg";
import part_3 from "../../assets/images/part_3.svg";
import part_4 from "../../assets/images/part_4.svg";
import com_send_btn from "../../assets/images/com_send_btn.svg";
import reply_icon from "../../assets/images/reply_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import { Link, useLocation } from "react-router-dom";
import forum_img_3 from "../../assets/images/forum_img_3.svg";
import main_img from "../../assets/images/main_img.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dislike_btn from "../../assets/images/dislike_btn.svg";
import com_icon from "../../assets/images/com_icon.svg";
import add_btn from "../../assets/images/add_btn.svg";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import axios from "axios";
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { MdOutlineModeComment } from "react-icons/md";
import { BsSend } from "react-icons/bs";
import { simplePostCall } from "../../api/ApiServices";
import { io } from "socket.io-client";
function ForumView() {
  const [isShown, setIsShown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [forumSubIndex, setForumSubIndex] = useState("");
  const [showreply, setShowReply] = useState(false);
  const [showreplySub, setShowReplySub] = useState(false);
  const handleshowSub = () => setShowReplySub(!showreplySub);
  const handleshow = () => setShowReply(!showreply);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [like, setLike] = useState("");
  const [forumIndex, setForumIndex] = useState("");
  const [dislike, setDislike] = useState("");
  const [socket, setSocket] = useState("");
  const [click, setClick] = useState(false);
  const [forumCommentIndex, setForumCommentIndex] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [replaycommentInput, setReplaycommentInput] = useState("");
  const [replaycommentSub, setReplaycommentSub] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const [visibleReplyBox, setVisibleReplyBox] = useState(null); // Track the visible reply box
  const [visibleReplyBoxx, setVisibleReplyBoxx] = useState(null);
  const [sendReplayTextt, setSendReplayTextt] = useState("");
  const [sendReplayText, setSendReplayText] = useState("");
  const [commentList, setCommentList] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const handleIndex = (index) => {
    setForumIndex(index);
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const forum_id = params.get("id");
  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    return `${month} ${day}, ${year}`;
  }
  const HandleForumone = () => {
    {
      console.log("ApiConfig.ALL_FORUM", setData);
    }
    setIsloading(true);
    try {
      axios(ApiConfig.ALL_FORUM, {
        method: "GET",
        params: {
          forum_id: forum_id,
          user_id: Params.teacher_id,
        },

        headers: {
          api_key: Params.api_key,
          customer_id: Params.customer_id,
        },
      }).then((data) => {
        if (data.data.result) {
          console.log(data.data.data,"data");
          setData((prevData) => [...prevData, ...data.data.data]);
          setTotalPages(data.data.next_page);
          setIsloading(false);
        } else {
          setIsloading(false);
          setData([]);
        }
      });
    } catch (error) {
      console.log("home error:", error);
      setIsloading(false);
      setData([]);
    }
  };
  useEffect(() => {
    HandleForumone();
    ListApiComment();
  }, []);
 
  const ListApiComment = () => {
    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      forum_id: forum_id,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };
    axios
      .post(ApiConfig.FORUM_COMMENT, requestBody, { headers })
      .then((response) => {
        if (response.data.result) {
          setCommentData((prevData) => [...prevData, ...response.data.data]);
          setTotalPages(response.data.next_page);
        } else {
          console.log("no comment", response.data);
        }
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error4: ", error);
      });
  };
  const handleClickMessage = (comment, item, index) => {
    setForumCommentIndex(index);
    if (comment.length !== 0) {
      setClick(!click);
    } else {
      setClick(false);
    }
  };
  const ReplayBack = (comment_id) => {
    const eventData = {
      comment_date: replaycommentInput || replaycommentSub,
      forum_id: forum_id,
      comment_user_id: Params.user_id,
      user_type: "teacher",
      comment_parrent_comment_id: comment_id,
    };
    socket.emit("forum_comment", eventData, () => {
      console.log("socket emitted good....");
    });
    setReplaycommentInput("");
    setReplaycommentSub("");
  };
  const SocketEmitEvent = () => {
    if (commentInput.trim().length === 0) {
      alert("Enter the Messages");
      return;
    }
    const eventData = {
      comment_text: commentInput,
    };
  };
  //==========like socket============//
  useEffect(() => {
    const customerId = Params.customer_id;
    const userId = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + customerId + "&userId=" + userId
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected on forum room");

      socket.emit("forum", () => {
        console.log("forum room is create");
      });
    });
    socket.on("forum_like_count", (data) => {
      console.log(data, "socket response");
      HandleSocket(data);
    });
    socket.on("newforum", (data) => {
      console.log("newFeed received", data);
    });
    // Handle socket disconnection
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket manually disconnected forum list");
      }
    };
  }, []);
  const LikeCountEvent = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: true,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("likeEvent is emited");
    });
  };
  const DisLikeCount = (ids) => {
    console.log(ids, "forumid");
    const event = {
      like_dislike: false,
      user_role: "teacher",
      forum_id: ids,
    };
    socket.emit("forum_like", event, () => {
      console.log("dislikedevent is emitted");
    });
  };

  const HandleSocket = (conn) => {
    console.log(conn, "response from socket data");

    // setLiked(true);
    setData((prevFeedLists) => {
      const UpdateListedForum = prevFeedLists?.map((forum) => {
        if (forum.forum_id == conn.forum_id) {
          return {
            ...forum,
            forum_like_count: conn.forum_like_count,
            forum_dislike_count: conn.forum_dislike_count,
            existing_like: conn.existing_like,
            existing_dislike: conn.existing_dislike,
          };
        }
        return forum;
      });
      const forumExisting = UpdateListedForum.some(
        (forum) => forum.forum_id == conn.forum_id,
      );
      if (!forumExisting) {
        return [
          ...UpdateListedForum,
          {
            forum_id: conn.forum_id,
            forum_like_count: conn.forum_like_count,
            forum_dislike_count: conn.forum_dislike_count,
            existing_like: conn.existing_like,
            existing_dislike: conn.existing_dislike,
          },
        ];
      }
      return UpdateListedForum;
    });
  };
  //=============comment================//
  useEffect(() => {
    const customerId = Params.customer_id;
    const userId = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + customerId + "&userId=" + userId
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected on forum room");

      socket.emit("forum_comment_room", () => {
        console.log("forum room is create");
      });
      socket.on("rec_forum_comment", (data) => {
        HandleMeassage(data);
        // setSocketData(data);
        console.log(data, "socket response from comment");
      });
      // socket.on("forum_edit", (data) => {
      //   console.log("Received event from edited data: ", data);
      //   if (data.result == true) {
      //     updateTempArray(data.data);
      //     toast.success(data.message);
      //   }
      // });
    });
    // Handle socket disconnection
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });
    //handle message//
  

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        socket.removeAllListeners();
        console.log("Socket cleaned up from comment section");
      }
    };
  }, [commentData]);
  const HandleMeassage = (data) => {
    console.log(data, "response", data.comment_parrent_comment_id);
    if (
      data.comment_parrent_comment_id === null ||
      data.comment_parrent_comment_id === ""
    ) {
      let arr = [data];
      arr = arr.map((item) => ({
        ...item,
        ...commentData,
      }));

      const newComment = {
        ...data,
        ...commentData, // Assuming you want to merge with the current feed details
      };

      setCommentData((prevDetails) => [...arr, ...prevDetails]);
    } else {
      console.log("else part.....");

      addToComments(commentData, data.comment_parrent_comment_id, data);
    }
  };
  function addToComments(comments, parentCommentId, newComment) {
    console.log("enter the addtoComments....", newComment);

    const parentComment = findParentComment(comments, parentCommentId);
    if (parentComment) {
      console.log(parentComment, "parent");

      if (!parentComment.comment_comments) {
        parentComment.comment_comments = []; // Initialize comment_comments if not exists
      }
      console.log("hiuukuu");
      const childComment = {
        ...newComment,
        comment_comments: [], // Initialize an empty array for child comments
      };

      parentComment.comment_comments.push(childComment); // Add the new comment as a child

      const commentListCopy = [...comments];
      const parentCommentIndex = commentListCopy.findIndex(
        (comment) => comment.comment_id === parentCommentId,
      );
      if (parentCommentIndex !== -1) {
        commentListCopy[parentCommentIndex] = parentComment;
        console.log("last", parentComment);
        setCommentData(commentListCopy);
      }
    }
  }
  const findParentComment = (comments, parentCommentId) => {
    console.log(comments, parentCommentId, "finding to the parent comment");

    for (let comment of comments) {
      console.log("entering the first condition");

      if (comment.comment_id == parentCommentId) {
        console.log("rrrr", parentCommentId, comment,comment.comment_id);
        // setGetFeedDetail((prevState) => [comment, ...prevState]);
        return comment;
      } else if (
        comment.comment_comments &&
        comment.comment_comments?.length > 0
      ) {
        console.log(
          "sub loop",
          comment.comment_comments,
          parentCommentId,
          comment
        );
        const parentComment = findParentComment(
          comment.comment_comments,
          parentCommentId
        );
        console.log("parent.......", parentComment);

        if (parentComment) {
          return parentComment;
        }
      }
    }
    return null;
  };

  const emitSocketEvent = () => {
    if (commentInput.trim().length === 0) {
      alert("Enter Message");
      return;
    }
    // if (comment_id) {
    //   // commentEdit(comment_id);
    //   const eventData = {
    //     comment_text: commentInput,
    //     forum_id: forum_id,
    //     comment_id: comment_id,
    //     comment_user_id: Params.user_id,
    //     user_type: "student",
    //   };

    //   socket.emit("forum_comment_edit", eventData, () => {
    //     console.log("socket emitted....");
    //   });
    //   console.log(comment_id, "first condition");
    // } else {
      const eventData = {
        comment_text: commentInput,
        forum_id: forum_id,
        comment_user_id: Params.teacher_id,
        user_type: "teacher",
      };

      socket.emit("forum_comment", eventData, () => {
        console.log("socket emitted....");
      });
    // }
    setCommentInput("");
    // setComment_id("");
  };
  //======enter key value=======//
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      emitSocketEvent();
    }
  };
  
const sendReplay = (commentid) => {
    console.log(commentid, "comment id.....");
    // if (comment_id) {
    //   // commentEdit(comment_id);
    //   const eventData = {
    //     comment_text: replaycommentInput || replaycommentSub,
    //     forum_id: forum_id,
    //     comment_user_id: Params.user_id,
    //     comment_id: comment_id,
    //     user_type: "student",
    //     comment_parrent_comment_id: commentid,
    //   };
    //   socket.emit("forum_comment_edit", eventData, () => {
    //     console.log("socket emitted....");
    //   });
    //   console.log(comment_id, "first condition");
    // } else {
      const eventData = {
        comment_text: sendReplayText||sendReplayTextt ,
        // || replaycommentSub,
        forum_id: forum_id,
        comment_user_id: Params.teacher_id,
        user_type: "teacher",
        comment_parrent_comment_id: commentid,
      };
      socket.emit("forum_comment", eventData, () => {
        console.log("socket emitted....");
      });
    // }
    // setComment_id("");
    setReplaycommentInput("");
    setReplaycommentSub("");
    // setCommetOpen(a);
  };

  return (
    <>
      <div class="Note_view Forum">
        <div className="main-section">
          <div className="row">
            <label>Forums</label>
            {data.map((item, index) => {
              return (
                <div className="col-md-12">
                  <div className="announcement-top"></div>
                  <div className="class-text-wrapper">
                    <div className="forum-header">
                      <div className="forum-img">
                        <img src={forum_img_3} alt="" />
                      </div>
                      <div className="forum-title-wrapper">
                        <p className="ft-main-title"> {item.forum_title}</p>
                        <p className="ft-sub-title">{item.category_name}</p>
                        <p className="ft-date">
                         
                          {formatDate(item.forum_created_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="test-desc">{item.forum_description}</div>
                  <div className="forum-img-wrapper">
                    {item?.forum_attachment?.split(".").pop() === "pdf" ? (
                      <div className="imagesIf">
                        <iframe
                          width="100%"
                          height="500px"
                          className="responsive-iframe imagesIf"
                          src={
                            ApiConfig.NODE_URL + "/" + item?.forum_attachment
                          }
                        />
                      </div>
                    ) : item?.forum_attachment?.split(".").pop() === "ppt" ? (
                      <div className="ramimagesIfanan">
                        <iframe
                          width="100%"
                          height="100px"
                          className="responsive-iframe imagesIf"
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                            ApiConfig.NODE_URL + item?.forum_attachment
                          }`}
                        />
                      </div>
                    ) : item?.forum_attachment?.split(".").pop() === "jpg" ? (
                      <div className="imagesIf">
                        <img
                          width="50%"
                          height="100px"
                          className="responsive-iframe imagesIf "
                          src={
                            ApiConfig.NODE_URL + "/" + item?.forum_attachment
                          }
                        />
                      </div>
                    ) : item?.forum_attachment?.split(".").pop() === "mp4" ||
                      item?.forum_attachment?.split(".").pop() === "mp3" ||
                      item?.forum_attachment?.split(".").pop() === "3gp" ||
                      item?.forum_attachment?.split(".").pop() === "mkv" ||
                      item?.forum_attachment?.split(".").pop() === "avi" ||
                      item?.forum_attachment?.split(".").pop() === "mov" ||
                      item?.forum_attachment?.split(".").pop() === "wmv" ||
                      item?.forum_attachment?.split(".").pop() === "flv" ||
                      item?.forum_attachment?.split(".").pop() === "webm" ||
                      item?.forum_attachment?.split(".").pop() === "mpeg" ||
                      item?.forum_attachment?.split(".").pop() === "webp" ? (
                      <div className="imagesIf">
                        <iframe
                          width="50%"
                          height="100px"
                          className="responsive-iframe imagesIf "
                          src={
                            ApiConfig.NODE_URL + "/" + item?.forum_attachment
                          }
                        />
                      </div>
                    ) : (
                      <div className="imagesIf">
                        <iframe
                          width="50%"
                          height="100px"
                          className="responsive-iframe imagesIf "
                          src={
                            ApiConfig.NODE_URL + "/" + item?.forum_attachment
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="forum-footer border-bottom">
                    <div
                      className="like-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => LikeCountEvent(item.forum_id)}
                    >
                      {item.existing_like ? (
                        <BiSolidLike
                          style={{
                            width: "18px",
                            height: "18px",
                            color: "#954386",
                          }}
                        />
                      ) : (
                        <BiLike
                          style={{
                            width: "18px",
                            height: "18px",
                            color: "#954386",
                          }}
                        />
                      )}
                      <span>{item.forum_like_count}</span>
                    </div>
                    <div
                      className="dislike-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => DisLikeCount(item.forum_id)}
                    >
                      {item.existing_dislike ? (
                        <BiSolidDislike
                          style={{
                            width: "18px",
                            height: "18px",
                            color: "#954386",
                          }}
                        />
                      ) : (
                        <BiDislike
                          style={{
                            width: "18px",
                            height: "18px",
                            color: "#954386",
                          }}
                        />
                      )}
                      <span>{item.forum_dislike_count}</span>
                    </div>
                    <div className="comments-btn">
                      <img src={com_icon} alt="" />
                      <span>{item.forum_comment_count}</span>
                    </div>
                  </div>
                  <div className="main-comment-input-wrapper">
          <Form>
            <Form.Group className="" controlId="formBasicEmail">
              <Form.Control
                type="text"
                onKeyPress={(event) => handleKeyPress(event)}
                placeholder="Write Comment..."
                onChange={(e) => {
                  setCommentInput(e.target.value);
                }}
                value={commentInput}
              />
              <div></div>
              <img src={com_send_btn} alt="" onClick={emitSocketEvent} />
            </Form.Group>
          </Form>
        </div>
                  <div className="comments-wrapper">
                    <p className="title">Comments</p>
                    <div className="single-comment com-border-bottom" style={{width:"100%",display:"contents"}}>
                      <InfiniteScroll
                        style={{ overflow: "hidden",width:"100%" }}
                        dataLength={commentData?.length}
                      >
                        {console.log(
                          commentData,
                          "This is commentData the data",
                        )}
                         {commentData.map((item, index) => (
                <div className="single-comment mt-3" key={index}>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <div style={{ display: "flex", gap: "15px" }}>
                        <img
                          src={
                            ApiConfig.NODE_URL +
                            "/" +
                            item?.comment_user_profile_pic
                          }
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                          }}
                        />
                        <p
                          className="title"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.comment_user_name}
                        </p>
                      </div>
                      <div className="time-and-like">
                        <div className="time">
                          {
                            new Date(item.comment_date)
                              .toISOString()
                              .split("T")[0]
                          }
                          , {item.comment_time}
                        </div>
                      </div>
                    </div>
                    <div className="comment-text">
                      <p>{item.comment_text}</p>
                    </div>
                    <div className="item-wrapper">
                      <div
                        className="reply-btn-wrapper"
                        onClick={() =>
                          setVisibleReplyBox(
                            visibleReplyBox === index ? null : index,
                          )
                        }
                      >
                        <img src={reply_icon} alt="Reply Icon" />
                        <span>Reply</span>
                      </div>
                      {visibleReplyBox === index && (
                        <div
                          className="reply-input-wrapper"
                          style={{ display: "flex", gap: "5px" }}
                        >
                          <input
                            type="text"
                            placeholder="Type your reply..."
                            className="reply-input"
                            style={{
                              display: "flex",
                              width: "100%",
                              border: "none",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                            onChange={(e) => setSendReplayText(e.target.value)}
                            value={sendReplayText}
                          />

                          <i
                            className="bi bi-send"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => sendReplay(item.comment_id)}
                          />
                        </div>
                      )}
                    </div>

                    {/* Nested Comments */}
                    <div className="teachers-reply">
                      {item.comment_comments.map((subItem, indx) => {
                        const uniqueKey = `${index}-${indx}`; // Create a unique key for nested reply boxes
                        return (
                          <div
                            className="single-comment mt-3"
                            style={{ paddingLeft: "25px" }}
                            key={uniqueKey}
                          >
                            <div className="com-img-box">
                              <img
                                src={
                                  ApiConfig.NODE_URL +
                                  "/" +
                                  subItem?.comment_user_profile_pic
                                }
                                alt=""
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "100%",
                                }}
                              />
                            </div>
                            <div className="text-wrapper">
                              <div className="com-heading-wrapper">
                                <p className="title">
                                  {subItem.comment_user_name}
                                </p>
                                <div className="time-and-like">
                                  <div className="time">
                                    {
                                      new Date(subItem.comment_date)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                    , {subItem.comment_time}
                                  </div>
                                </div>
                              </div>
                              <div className="comment-text">
                                <p>{subItem.comment_text}</p>
                              </div>

                              <div className="item-wrapper">
                                <div
                                  className="reply-btn-wrapper"
                                  onClick={() =>
                                    setVisibleReplyBoxx(
                                      visibleReplyBoxx === uniqueKey
                                        ? null
                                        : uniqueKey,
                                    )
                                  }
                                >
                                  <img src={reply_icon} alt="Reply Icon" />
                                  <span>Reply</span>
                                </div>
                                {visibleReplyBoxx === uniqueKey && (
                                  <div
                                    className="reply-input-wrapper"
                                    style={{ display: "flex", gap: "5px" }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Type your reply..."
                                      className="reply-input"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        border: "none",
                                        padding: "10px",
                                        borderRadius: "10px",
                                      }}
                                      onChange={(e) =>
                                        setSendReplayTextt(e.target.value)
                                      }
                                      value={sendReplayTextt}
                                    />
                                    <i
                                      className="bi bi-send"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        sendReplay(subItem.comment_id)
                                      }
                                    />
                                  </div>
                                )}
                                <div className="teachers-reply">
                                  {subItem.comment_comments.map(
                                    (subItemm, indx) => {
                                      const uniqueKey = `${index}-${indx}`; // Create a unique key for nested reply boxes
                                      return (
                                        <div
                                          className="single-comment mt-3"
                                          style={{ paddingLeft: "25px" }}
                                          key={uniqueKey}
                                        >
                                          <div className="com-img-box">
                                            <img
                                              src={
                                                ApiConfig.NODE_URL +
                                                "/" +
                                                subItemm?.comment_user_profile_pic
                                              }
                                              alt=""
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "100%",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="text-wrappe"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <div className="com-heading-wrapp">
                                              <p
                                                className="title"
                                                style={{
                                                  color: "#282739",
                                                  fontSize: "13px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {subItemm.comment_user_name}
                                              </p>
                                              <div className="comment-text">
                                                <p>{subItemm.comment_text}</p>
                                              </div>
                                            </div>
                                            <div className="time-and-like">
                                              <div className="tim">
                                                <p
                                                  className="title"
                                                  style={{
                                                    color:
                                                      "rgba(40, 39, 57, 0.5)",
                                                    fontSize: "13px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {
                                                    new Date(
                                                      subItemm.comment_date,
                                                    )
                                                      .toISOString()
                                                      .split("T")[0]
                                                  }
                                                  ,{subItemm.comment_time}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        
      </div>
    </>
  );
}
export default ForumView;