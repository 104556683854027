import React from "react";
import { useState } from "react";

function EditGradeDivision() {
  const [checkboxes, setCheckboxes] = useState({
    selectAll: false,
    class12: false,
    NURSERY: false,
    LKG: false,
    grade1: false,
    Test: false,
    HARMONIUM: false,
    grade6A: false,
    grade7A: false,
    grade3: false,
    grade12B: false,
    PlusOne: false,
    grade6: false,
    grade7: false,
  });
  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxes = Object.keys(checkboxes).reduce((result, key) => {
      result[key] = isChecked;
      return result;
    }, {});
    setCheckboxes(updatedCheckboxes);
  };
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
      selectAll: false, // Uncheck "Select All" if any other checkbox is unchecked
    }));
  };
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Update</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                noValidate
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="division-name">Division Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        id="division-name"
                      />
                    </div>
                    <label htmlFor="grades">Grades</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="selectAll"
                        checked={checkboxes.selectAll}
                        onChange={handleSelectAllChange}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="class12"
                          checked={checkboxes.class12}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="class12">12</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="NURSERY"
                          checked={checkboxes.NURSERY}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="NURSERY">NURSERY 1</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="LKG"
                          checked={checkboxes.LKG}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="LKG">LKG</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade1"
                          checked={checkboxes.grade1}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade1">1st grade updated</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Test"
                          checked={checkboxes.Test}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="Test">Testtttt</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="HARMONIUM"
                          checked={checkboxes.HARMONIUM}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="HARMONIUM">HARMONIUM 1</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade6A"
                          checked={checkboxes.grade6A}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade6A">KG1</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade7A"
                          checked={checkboxes.grade7A}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade7A">7th(A)</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade3"
                          checked={checkboxes.grade3}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade3">GRADE 3</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade12B"
                          checked={checkboxes.grade12B}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade12B">12(B)</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="PlusOne"
                          checked={checkboxes.PlusOne}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="PlusOne">Plus One</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade6"
                          checked={checkboxes.grade6}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade6">GRADE 6</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="grade7"
                          checked={checkboxes.grade7}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="grade7">GRADE 7</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="schedulebutton">
                <div></div>
                <div className="">
                  <button className="btn btn-primary" type="button">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditGradeDivision;
