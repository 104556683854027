import React, { useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import { useTranslation } from "react-i18next";

const AvailedLeaves = (props) => {
  const [state, setState] = useState({
    leaveList: [],
  });

  const getAvailedLeaveList = () => {
    const newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      employee_id: Params.teacher_id,
      academic_year_id: "",
    };
    simplePostCall(ApiConfig.MY_LEAVE_LIST_API, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          setState({ ...state, leaveList: data.list });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const displayStyle = {
    display: "block",
  };
  useEffect(() => {
    getAvailedLeaveList();
  }, []);
  const { t } = useTranslation();
  return (
    <div className=" approve-leave">
      {/* <!-- Container-fluid starts--> */}
      <div className="row">
        <div className="col-12">
          <div className="card br-15">
            <div className="card-body">
              <div className="n-attend-total d-flex">
                <div className="flex-grow-1 n-attend-title">
                  <h3>{t("Availed Leaves")}</h3>
                </div>
              </div>
              <div
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="student-rank-list"
              >
                <div className="d-flex align-items-center">

                  <div className="w-50">{t("S_No")}</div>
                  {/* <div className="p-image">
                        <img src="" alt="..." />
                      </div> */}
                  {/* <div className="student-name">
                        {leave.employee_name + " " + leave.employee_last_name}
                      </div> */}
                  <div className="w-100">
                  {t("Apply On")}
                  </div>
                  <div className="w-100">
                  {t("From")}
                  </div>
                  <div className="w-100">
                  {t("To")}
                  </div>
                  <div className="w-100">
                  {t("No of Days")} 
                  </div>
                  <div className="w-100">
                  {t("Status")}
                  </div>
                </div>
              </div>


              {state.leaveList && state.leaveList.length > 0 ? (
                state.leaveList.map((leave, index) => (
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="student-rank-list"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div className="w-50">{index + 1}</div>
                      {/* <div className="p-image">
                        <img src="" alt="..." />
                      </div> */}
                      {/* <div className="student-name">
                        {leave.employee_name + " " + leave.employee_last_name}
                      </div> */}
                      <div className="w-100">
                        {leave.employee_leave_apply_date}
                      </div>
                      <div className="w-100">
                        {leave.employee_leave_from_date}
                      </div>
                      <div className="w-100">
                        {leave.employee_leave_to_date}
                      </div>
                      <div className="w-100">
                        {leave.employee_leave_no_of_days} Days
                      </div>
                      <div className="w-100">
                        {leave.employee_leave_status}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="student-rank-list"
                >
                  <div className="d-flex align-items-center">
                    <p>No records found..!</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
};

export default AvailedLeaves;
