import React, { useState, useEffect } from "react";
import TimeTableGridView from "./gridView/TimeTableGridView";
import TimetableListView from "./listView/TimeTableListView";
import { Grid } from "react-feather";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import SpinnerCmp from "../../components/SpinnerCmp";
import { toast } from "react-toastify";
import TImeTableSkeleton from "../../components/skeletons/TImeTableSkeleton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Timetable = (props) => {
  const { t } = useTranslation();

  const [isloading, setIsLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const [class_division_id, setClass_division_id] = useState("");
  const [indicate, setIndicate] = useState("");
  const [timeTableByClass, setTimeTableByClass] = useState({
    TimetableByClass: [],
    PeriodsByClass: [],
  });

  const [timetableState, setTimetableState] = useState({
    myTimetable: [],
    Periods: [],
  });

  console.log("timeTableByClass", timeTableByClass);
  // console.log("timetableState", timetableState);

  const getTimeTableByClass = () => {
    setIsLoading(true);
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      // teacher_id: Params.teacher_id,
      division_id: class_division_id,
    });
    simplePostCall(ApiConfig.BY_CLASS_TIMETABLE, newRequestBody)
      .then((data) => {
        if (data.result) {
          var periods_list = [];
          if (data.periods.length > 0) {
            data.periods.map((period, index) => {
              periods_list.push({
                id: period.periods_id,
                PeriodId: period.periods_name,
                startTime: period.periods_start_time,
                endTime: period.periods_end_time,
                isInterval: period.periods_type == "break" ? true : false,
              });
            });
          }
          setTimeTableByClass({
            ...timeTableByClass,
            TimetableByClass: data.my_timetable,
            PeriodsByClass: periods_list,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.success(data.message);
          setTimeTableByClass({
            TimetableByClass: [],
            PeriodsByClass: [],
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log("api response", error);
      });
  };

  function getTimetableData() {
    setIsLoading(true);
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      date: "",
    });
    simplePostCall(ApiConfig.MY_TIMETABLE, requestBody)
      .then((data) => {
        if (data.result) {
          setIsLoading(false);

          var periods_list = [];
          if (data.periods.length > 0) {
            data.periods.map((period, index) => {
              periods_list.push({
                id: period.periods_id,
                PeriodId: period.periods_name,
                startTime: period.periods_start_time,
                endTime: period.periods_end_time,
                isInterval: period.periods_type == "break" ? true : false,
              });
            });
          }

          setTimetableState({
            ...timetableState,
            myTimetable: data.my_timetable,
            Periods: periods_list,
          });
        } else {
          toast.error(data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("api response", error);
      });
  }

  const Periods = timetableState?.Periods;

  const WeeklyPeriods = timetableState?.myTimetable;

  const Periods_By_class = timeTableByClass.PeriodsByClass;
  const timeTable_By_class = timeTableByClass.TimetableByClass;

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  const dataGridByClass = {
    Periods: Periods_By_class,
    Lectures: timeTable_By_class,
  };

  const [isGridView, setIsGridView] = useState(true);
  console.log("isGridView", isGridView);

  useEffect(() => {
    document.title = "Edsys | Timetable ";
    if (class_division_id) {
      getTimeTableByClass();
    } else getTimetableData();

    getClassList();
  }, [class_division_id]);

  const getClassList = () => {
    let newRequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data);
        if (data.result) {
          setClassList(data.list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <>
      {isloading ? (
        <TImeTableSkeleton />
      ) : (
        <div className=" timetable">
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex">
                <h5 className="mb-2 flex-grow-1">{t("Timetable")}</h5>
                <a
                  onClick={() => setIsGridView(!isGridView)}
                  className="tt-align"
                >
                  {isGridView ? (
                    <i className="icofont icofont-listing-box"></i>
                  ) : (
                    <Grid />
                  )}
                </a>
              </div>
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class={class_division_id ? "nav-link" : "nav-link active"}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={() => {
                      getTimetableData(setClass_division_id(""));
                      setIndicate("");
                    }}
                  >
                    {t("My Timetable")}
                    <div class="media"></div>
                  </a>
                </li>

                {classList &&
                  classList.map((classList, index) => {
                    return (
                      <li class="nav-item" key={"classList" + index}>
                        <a
                          class={
                            index === indicate ? "nav-link active" : "nav-link"
                          }
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          onClick={() => {
                            setClass_division_id(classList.class_division_id);
                            setIndicate(index);
                          }}
                        >
                              {t("Class")}  {classList.class_name}{" "}
                          {classList.class_division_name}
                        </a>
                      </li>
                    );
                  })}
                <Link
                  to={`/TimeTableGenerate`}
                  style={{
                    marginLeft: 30,
                    //  padding:9,
                    width: "18%",
                    backgroundColor: "#adc927",
                    alignContent: "center",
                    borderRadius: 5,
                    color: "white",
                    textAlign: "center",
                    // marginTop: 10,
                  }}
                >
                  <h6 style={{ marginTop: 10, marginLeft: 12 }}>
                  {t("Generate Time Table")}
                  </h6>
                </Link>
              </ul>
              <div class="card mt-2 br-15">
                <div class="card-body">
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      {isGridView ? (
                        <TimeTableGridView
                          data={class_division_id ? dataGridByClass : dataGrid}
                          myTimetable={timetableState.myTimetable}
                        />
                      ) : (
                        <TimetableListView
                          data={class_division_id ? dataGridByClass : dataGrid}
                          myTimetable={timetableState.myTimetable}
                        />
                      )}
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      {isGridView ? (
                        <TimeTableGridView
                          data={class_division_id ? dataGridByClass : dataGrid}
                          myTimetable={timetableState.myTimetable}
                          TimetableByClass={timeTableByClass.TimetableByClass}
                        />
                      ) : (
                        <TimetableListView
                          data={class_division_id ? dataGridByClass : dataGrid}
                          myTimetable={timetableState.myTimetable}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Timetable;
