import React, { useEffect, useState } from "react";
import profilePic from "../../assets/images/masterdatapic/profilePic.png";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

function TeacherRegisteration() {
  const [fullname, setFullName] = useState("");
  const [registeremail, setRegisterEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [RegisteredtaTeacherApi, setRegisteredTeacherApi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState("");

  const clearFields = () => {
    setFullName("");
    setRegisterEmail("");
    setPhoneNumber("");
  };

  const handlePageChange = () => {
    if (nextPage != false) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (nextPage !== false) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    RegisteredTeacherList();
  }, [fullname, registeremail, phonenumber, currentPage]);
  const RegisteredTeacherList = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      user_name: fullname,
      user_email: registeremail,
      user_mobile: phonenumber,
      page: Number(currentPage),
      limit: 5,
    });
    simplePostCall(ApiConfig.REGISTEREDTEACHER, RequestBody)
      .then((data) => {
        setRegisteredTeacherApi(data?.data);
        setNextPage(data?.next_page);
      })
      .catch((eroor) => {
        console.log(eroor, "Error");
      });
  };
  console.log(RegisteredtaTeacherApi, "woww");
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Registered Teacher</h5>
          </div>

          <div className="card">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Designation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={registeremail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                      ></input>
                    </th>
                    <th>
                      <input
                        className="form-control"
                        type="Search"
                        placeholder="Search"
                        value={phonenumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></input>
                    </th>
                    <th></th>
                    <th>
                      <div className="button_div">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={clearFields}
                        >
                          Clear
                        </button>
                      </div>
                    </th>
                  </tr>
                  {RegisteredtaTeacherApi.map((item, index) => {
                    return (
                      <tr>
                        <th scope="row">{item.slno}</th>
                        <td>
                          <div className="subject_image_div">
                            <img
                              src={
                                item.user_profile_pic
                                  ? ApiConfig.NODE_URL +
                                    "/" +
                                    item.user_profile_pic
                                  : profilePic
                              }
                              className="subject_image"
                            />
                            {item.user_name} {item.user_last_name}
                          </div>
                        </td>
                        <td>{item.user_email}</td>
                        <td>{item.user_mobile}</td>
                        <td>{item.user_role}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="showingnumber">
                <div></div>
                <nav aria-label="Pagination">
                  <ul className="pagination">
                    <li
                      className={`page - item${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                      >
                        &laquo;
                      </button>
                    </li>

                    <button
                      className="page-link"
                      style={{ backgroundColor: "#adc927" }}
                      onClick={() => handlePageChange()}
                    >
                      {currentPage}
                    </button>

                    <li
                      className={`page-item ${
                        currentPage === currentPage - 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        style={{ backgroundColor: "#adc927" }}
                        onClick={handleNextPage}
                        disabled={currentPage === currentPage - 1}
                        aria-label="next"
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherRegisteration;
