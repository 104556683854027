import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import LoadingModal from "./Loadingmodal";
import { toast } from "react-toastify";
import SyllabusssSkeleton from "./SyllabustableSkelton";
import { useTranslation } from "react-i18next";

export const UpdateSyllabus = () => {
  const { t } = useTranslation();

  const [updatesub, setUpdatesub] = useState("");
  const [updatelang, setUpdatelang] = useState("");
  const syllabusViewDATA = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      curriculum_id: getData,
    });
    simplePostCall(ApiConfig.SYLLABUS_VIEW, RequestBody)
      .then((data) => {
        if (data.data) {
          setLoading(false);
          console.log(data.data, "uvjvhvjhv");
          setSyllabusdata(data.data[0]);
          console.log(syllabusdata, "syllabus");
        } else {
          setLoading(false);
          console.log("NO DATA in view");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("api res fail", err);
      });
  };
  const CirriculamUpdateCreate = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      curriculum_id: getData,
      curriculum_name: updatesub ? updatesub : syllabusdata.curriculum_name,
      curriculum_language: updatelang
        ? updatelang
        : syllabusdata.curriculum_language,
    });

    simplePostCall(ApiConfig.SYLLABUS_EDIT, RequestBody)
      .then((data) => {
        console.log(data, "update data");
        // setLoading(false);
        if (data.result) {
          console.log(data);
          toast.success("Syllabus created successfully!");
          navi("/SyllabusCreate");
        } else {
          toast.error("Failed to create syllabus.");
        }
      })
      .catch((err) => {
        // setLoading(false);
        toast.error("API request failed.");
        console.log("API request failed", err);
      });
  };
  useEffect(() => {
    syllabusViewDATA();
  }, []);
  const location = useLocation();
  const getData = location.state;
  const navi = useNavigate();
  const [syllabusdata, setSyllabusdata] = useState("");
  const [loading, setLoading] = useState(true);
  return (
    <div className="SyllabusCreate">
      {loading ? (
        <SyllabusssSkeleton />
      ) : (
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="labelCreate">{t("Update Curriculum")}</label>
              </div>
            </div>
            <form>
              <div className="col-md-12 curriculum">
                <div className="col">
                  <label>{t("Curriculum Name")}</label>
                </div>
                <input
                  type="text"
                  className="textar"
                  // placeholder={syllabusdata.curriculum_name}
                  defaultValue={syllabusdata?.curriculum_name}
                  onChange={(e) => setUpdatesub(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div className="col">
                  <label>{t("Curriculum Language")}</label>
                </div>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setUpdatelang(e.target.value)}
                >
                  <option selected>{syllabusdata.curriculum_language}</option>

                  <option value="English">{t("English")}</option>
                  <option value="Arabic">{t("Arabic")}</option>
                  <option value="Urdu">{t("Urdu")}</option>
                </select>
              </div>

              <button
                type="button"
                onClick={() => CirriculamUpdateCreate()}
                className="buttcreate"
              >
                {t("Update")}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
