import React, { useState, useRef } from 'react'
import { Edit, Edit2, Edit3, Trash2, Link, MoreHorizontal } from 'react-feather'
import { simplePostCall } from '../../api/ApiServices'
import Params from '../../config/Params'
import ApiConfig from '../../api/ApiConfig'
import SpinnerCmp from '../../components/SpinnerCmp'
import { toast } from 'react-toastify'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'

const TodoDetails = (props) => {
  const { t } = useTranslation()

  var taskList = props.data.todoList
  var headerTitle = props.data.title
  const componentRef = useRef()

  const [state, setState] = useState({
    isLoading: false,
  })

  function capitalizeFirstLetterOfEachWord(data) {
    return data.replace(/\b(\w)/g, (s) => s.toUpperCase())
  }

  function deleteTodo(todo_id) {
    setState({ ...state, isLoading: true })
    const newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      todo_id: todo_id,
    }
    simplePostCall(ApiConfig.DELETE_TODO, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          toast.success(data.message)
          setTimeout(() => {
            window.location.replace('/todo')
          }, 1000)
        } else {
          setState({ ...state, isLoading: false })
          toast.error(data.message)
        }
      })
      .catch((error) => {
        setState({ ...state, isLoading: false })
        console.log('api response', error)
      })
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <>
      {state.isLoading == false ? (
        <div className="email-right-aside boohandlePrintkmark-tabcontent">
          <div className="card email-body radius-left">
            <div className="ps-0">
              <div className="tab-content">
                <div
                  className="fade tab-pane active show"
                  id="pills-tasks"
                  role="tabpanel"
                  aria-labelledby="pills-tasks-tab"
                >
                  <div className="card mb-0">
                    <div className="card-header d-flex">
                      <h6
                        style={{
                          cursor: 'pointer',
                        }}
                        className="mb-0"
                      >
                        {headerTitle}
                      </h6>
                      <a
                        data-tip="Print"
                        onClick={handlePrint}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="me-2"></i>
                        {t('Print')}
                      </a>
                    </div>
                    <div className="card-body p-0">
                      <div className="taskadd">
                        <div className="table-responsive">
                          <table
                            className="table"
                            ref={componentRef}
                            id="table-to-xls"
                          >
                            {taskList.length > 0 ? (
                              taskList.map((task, index) => {
                                console.log(task)
                                return (
                                  <tr>
                                    <td>
                                      <h6 className="task_title_0">
                                        {capitalizeFirstLetterOfEachWord(
                                          task.title,
                                        )}
                                      </h6>
                                      <p className="project_name_0">
                                        {task.date}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="task_desc_0">
                                        {task.note.charAt(0).toUpperCase() +
                                          task.note.slice(1)}
                                      </p>
                                      <small>
                                      {t('Reminder')} :{' '}
                                        {task.remainder == true ? 'Yes' : 'No'}
                                      </small>
                                      <br />
                                      <small>
                                      {t('Reminder Date')}: {task.remainder_date}
                                      </small>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="me-2"
                                        onClick={() =>
                                          props.openCloseTodoModel(task)
                                        }
                                      >
                                        <Edit3 />
                                      </a>
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        onClick={() => deleteTodo(task.id)}
                                      >
                                        <Trash2 />
                                      </a>
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <div className="details-bookmark text-center">
                                    <span>{t('No data found')}.</span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SpinnerCmp />
      )}
    </>
  )
}

export default TodoDetails
