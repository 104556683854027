import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

import book_1 from "../../assets/images/Feed/book_1.svg";
import book_2 from "../../assets/images/Feed/book_2.svg";
import book_3 from "../../assets/images/Feed/book_3.svg";
import book_4 from "../../assets/images/Feed/book_4.svg";
import book_5 from "../../assets/images/Feed/book_5.svg";
import book_6 from "../../assets/images/Feed/book_6.svg";
import book_7 from "../../assets/images/Feed/book_7.svg";
import book_8 from "../../assets/images/Feed/book_8.svg";
import book_9 from "../../assets/images/Feed/book_9.svg";
import search_icon from "../../assets/images/Feed/search_icon.svg";
import filter_icon from "../../assets/images/Fourms/filter_icon.svg";
import profile from "../../assets/images/Feed/part_1.svg";
import announcement_icon from "../../assets/images/Feed/announcement_icon.svg";
import dropdown_icons from "../../assets/images/Fourms/dropdown_icons.svg";
import edit_icon from "../../assets/images/Fourms/edit_icon.svg";
import delete_icon from "../../assets/images/Fourms/delete_icon.svg";
import dislike_btn from "../../assets/images/Fourms/dislike_btn.svg";
import com_icon from "../../assets/images/Fourms/com_icon.svg";
import like_btn from "../../assets/images/Fourms/like_btn.svg";
import Fedd from "./Feed.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Params from "../../config/Params";
import ApiConfig, { NODE_URL } from "../../api/ApiConfig";
import { FaPlus } from "react-icons/fa6";
import InfiniteScroll from "react-infinite-scroll-component";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

function Feed() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title"]);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [filterType, setFilterType] = useState("most_recent"); // Default filter
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [delId, setDelId] = useState("");
  const [show1, setShow1] = useState(false);
  // const [feedData, setFeedData] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [hasMore, setHasMore] = useState(true); // To track if more data is available
  const [feedData, setFeedData] = useState([]); // Array to hold feed items
  const [loading, setLoading] = useState(false); // To show a loading indicator
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navic = useLocation();
  const { t } = useTranslation();
  const tabs = ["All Feeds"];
  useEffect(() => {
    setFeedData([]); // Clear the current feed data
    setPage(1); // Reset to the first page
    feedListing(1, filterType); // Fetch filtered data
  }, [filterType]); // Run when filterType changes
  const endreached = () => {
    if (hasMore && !loading) {
      setPage((prev) => {
        const nextPage = prev + 1;
        feedListing(nextPage, filterType); // Pass current filterType
        return nextPage;
      });
    }
  };
  const handleFilterChange = (filter) => {
    setFilterType(filter);
    setFeedData([]); // Clear current data to avoid duplication
    setPage(1); // Reset the page
    feedListing(1, filter); // Fetch the first page of filtered data
    handleClose(); // Close the modal
  };

  const [activeTab, setActiveTab] = useState(tabs[0]);

  function FeedDescription({ description }) {
    const [isExpanded, setIsExpanded] = useState(false);
    // const [isExpandedd, setIsExpandedd] = useState(false);

    // Character limit
    const charLimit = 15;
    const char = 15;

    return (
      <>
        <p className="Feed-data" style={{ display: "flex" }}>
          {isExpanded
            ? description
            : description.slice(0, charLimit) +
              (description.length > charLimit ? "" : "")}
          {description.length > charLimit && (
            <p
              className="Feed-data"
              onClick={() => setIsExpanded(!isExpanded)}
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                cursor: "pointer",
              }}
            >
              {isExpanded ? "..." : "..."}
            </p>
          )}
        </p>
      </>
    );
  }
  const FeedTitle = ({ feedtitle }) => {
    const [isExpandedd, setIsExpandedd] = useState(false);
    const char = 15;
    return (
      <p className="Feed-title" style={{ display: "flex" }}>
        {isExpandedd
          ? feedtitle
          : feedtitle.slice(0, char) + (feedtitle.length > char ? "" : "")}
        {feedtitle.length > char && (
          <p
            className="Feed-title"
            onClick={() => setIsExpandedd(!isExpandedd)}
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              cursor: "pointer",
            }}
          >
            {isExpandedd ? "..." : "..."}
          </p>
        )}
      </p>
    );
  };
  const feedDelete = async () => {
    // console.log("delete");
    const requestBody = {
      teacher_id: Params.teacher_id,
      feed_id: delId,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    };
    // console.log(headers, requestBody, "reqbod");

    axios
      .post(ApiConfig.FEED_DELETE, requestBody, { headers })
      .then((response) => {
        // console.log("check", response.data.result);
        if (response.data.result) {
          // toast.show(response.data.message, Toast.SHORT);
          console.log("deleted");
          feedListing();
        }
        // setisLoading(false);
      })
      .catch((error) => {
        // setisLoading(false);
        console.log("home error4: ", error);
      });
  };
  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    feedListing();
  }, []);
  const customer_id = Params.customer_id;
  const userId = Params.teacher_id;
  const [socket, setSocket] = useState("");
  console.log(userId, "useruds");

  useEffect(() => {
    // const socketCall = () => {
    const CustomerId = Params.customer_id;
    const userId = Params.teacher_id;
    const socket = io(
      ApiConfig.NODE_URL + "?customerId=" + CustomerId + "&userId=" + userId,
    );
    setSocket(socket);
    socket.on("connect", () => {
      console.log("connected on feed");

      socket.emit("feedRoom", () => {
        console.log("connected on feed_room");
      });
      socket.on("feed_like_count", (data) => {
        console.log("Received event in data:", data);

        if (data.result == true) {
          // setFeedLike(data);

          updateTempArray(data);
        }
      });
      socket.on("newfeed", (data) => {
        console.log("newFeed received", data);
      });
    });
    socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
    });

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket manually disconnected");
      }
    };
  }, []);

  const addLikeEvent = (feedId) => {
    console.log(`Liked feed with ID: ${feedId}`);
    // Add your like logic here
    const eventData = {
      like_dislike: true,
      user_role: "teacher",
      feed_id: feedId,
    };

    socket.emit("feed_like", eventData, (data) => {
      console.log("socket emitted....", data);
    });
  };

  const disLikeEvent = (feedId) => {
    console.log(`Disliked feed with ID: ${feedId}`);
    // Add your dislike logic here
    const eventData = {
      like_dislike: false,
      user_role: "teacher",
      feed_id: feedId,
    };

    socket.emit("feed_like", eventData, (data) => {
      console.log("socket emitted....", data);
    });
  };

  const updateTempArray = (data) => {
    setFeedData((prev) => {
      const updateFeed = prev?.map((feed) => {
        if (feed.feed_id === data.feed_id) {
          return {
            ...feed,
            feed_like_count: data.feed_like_count,
            feed_dislike_count: data.feed_dislike_count,
            feed_liked: data.feed_liked,
            feed_disliked: data.feed_disliked,
          };
        }
        return feed;
      });
      const feedExists = updateFeed.some(
        (feed) => feed.feed_id === data.feed_id,
      );

      if (!feedExists) {
        return [
          ...updateFeed,
          {
            feed_title: "New Feed", // Add appropriate default or fetched values
            feed_id: data.feed_id,

            feed_date: new Date().toISOString().split("T")[0], // Set today's date
            feed_time: new Date().toISOString().split("T")[1].split(".")[0], // Set current time
            feed_like_count: data.feed_like_count,
            feed_dislike_count: data.feed_dislike_count,
            feed_liked: data.feed_liked,
            feed_disliked: data.feed_disliked,
          },
        ];
      }
      console.log(updateFeed);
      return updateFeed; // Return updated feed list
    });
  };
  // forum_id: data.forum_id,
  // forum_like_count: data.forum_like_count,
  // forum_dislike_count: data.forum_dislike_count,
  // existing_like: data.existing_like,
  // existing_dislike: data.existing_dislike,
  const feedListing = async (pageNo, filter) => {
    setLoading(true);
    axios(ApiConfig.FEED, {
      method: "GET",
      params: {
        most_liked: filter === "most_liked" ? 1 : 0,
        most_commented: filter === "most_commented" ? 1 : 0,
        teacher_id: Params.customer_id,
        page_limit: 20, // Number of items per page
        page_no: pageNo, // Pass the current page
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
        api_key: Params.api_key,
        customer_id: Params.customer_id,
        teacher_id: Params.customer_id,
      },
    })
      .then((response) => {
        if (response.data.result) {
          console.log(response.data, "response");
          const newItems = response.data.data; // Fetch new items
          setFeedData((prev) => [...prev, ...newItems]); // Append new items to the existing list
          setHasMore(newItems.length > 0); // If no new items, stop further requests
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data ", error);
      })
      .finally(() => {
        setLoading(false); // Hide loading indicator
      });
  };

  // console.log(feedData[0]?.feed_date, "feedData");
  // function search(books) {
  //   return books.filter((items) => {
  //     return searchParam.some((newItem) => {
  //       return (
  //         items[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
  //       );
  //     });
  //   });
  // }
  if (error) {
    return <p>{error.message}</p>;
  } else {
    return (
      <div>
        <div class="library card p-4">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div className="col-md-12">
                    <div
                      className="announcement-top d-flex align-items-center "
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4>{t("Feeds")}</h4>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <img src={filter_icon} alt="" onClick={handleShow} />
                        <div className="create">
                          <Link to="/feedCreate" style={{ display: "flex" }}>
                            <FaPlus className="plusIcone" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <InfiniteScroll
                    dataLength={feedData.length}
                    style={{ overflow: "none", paddingTop: "20px" }}
                    next={endreached}
                    hasMore={hasMore}
                    loader={<h4>{t("Loading")}...</h4>} // Optional loading indicator
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>{t("No more feeds to show")}</b>
                      </p>
                    }
                  >
                    <div className="row">
                      {feedData.map((item) => (
                        <>
                          {/* {console.log(item.feed_id, "feeedid")} */}
                          <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="cardFeed">
                              <div class="d-flex">
                                <img
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                  class="flex-grow-0"
                                  src={
                                    ApiConfig.NODE_URL +
                                    "/" +
                                    item.user_profile_pic
                                  }
                                />
                                <div className="flex-grow-1">
                                  <p className="card-title">{item.user_name}</p>
                                  <p className="card-date">
                                    {item.feed_date.slice(0, 10)}
                                  </p>
                                </div>

                                <Dropdown className="Common_dropDown">
                                  <Dropdown.Toggle
                                    className="dropdown-btn"
                                    id="dropdown-basic"
                                  >
                                    <img src={dropdown_icons} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                    // onClick={() => setModalShow1(true)}
                                    >
                                      <Link
                                        to={"/feedEdit"}
                                        state={item.feed_id}
                                        style={{ color: "black" }}
                                      >
                                        <img src={edit_icon} alt="" />
                                        {t("Edit")} 
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{ color: "black" }}
                                      onClick={() => {
                                        // console.log("Delete clicked");
                                        setShow1(true);
                                        setDelId(item.feed_id);
                                      }}
                                    >
                                      <img src={delete_icon} alt="" />
                                      {t("Delete")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <Link to="/feedView" state={item.feed_id}>
                                <p className="Feed-title">
                                  <FeedTitle feedtitle={item.feed_title} />
                                </p>

                                <p className="Feed-data">
                                  <FeedDescription
                                    description={item.feed_description}
                                  />
                                </p>
                              </Link>
                              <div
                                style={{
                                  height: "100px",
                                  paddingTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item.feed_attachment &&
                                item.feed_attachment.length > 0 ? (
                                  item.feed_attachment.map(
                                    (attachment, index) => {
                                      const file =
                                        attachment.attachment_feed_file;
                                      const fileUrl = `${ApiConfig.NODE_URL}/${file}`;
                                      const fileExtension = file
                                        .split(".")
                                        .pop()
                                        .toLowerCase();

                                      if (
                                        [
                                          "jpg",
                                          "jpeg",
                                          "png",
                                          "gif",
                                          "bmp",
                                        ].includes(fileExtension)
                                      ) {
                                        // Render image
                                        return (
                                          <img
                                            key={index}
                                            src={fileUrl}
                                            alt={`Attachment ${index}`}
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        );
                                      } else if (
                                        ["mp4", "webm", "ogg"].includes(
                                          fileExtension,
                                        )
                                      ) {
                                        // Render video
                                        return (
                                          <video
                                            key={index}
                                            src={fileUrl}
                                            controls
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        );
                                      } else if (
                                        ["mp3", "wav", "ogg"].includes(
                                          fileExtension,
                                        )
                                      ) {
                                        // Render audio
                                        return (
                                          <audio
                                            key={index}
                                            src={fileUrl}
                                            controls
                                            style={{
                                              maxWidth: "100%",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        );
                                      } else if (
                                        ["pdf", "html"].includes(fileExtension)
                                      ) {
                                        // Render PDF or HTML in iframe
                                        return (
                                          <iframe
                                            key={index}
                                            src={fileUrl}
                                            style={{
                                              width: "240px",
                                              height: "100%",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        );
                                      } else {
                                        // Unsupported file type
                                        return (
                                          <span
                                            key={index}
                                            style={{
                                              display: "block",
                                              marginBottom: "10px",
                                            }}
                                          >
                                           {t("Unsupported attachment type")} 
                                          </span>
                                        );
                                      }
                                    },
                                  )
                                ) : (
                                  <span>{t("No attachment available")}</span>
                                )}
                              </div>

                              <div className="d-flex align-items-center ml-3">
                                <div
                                  key={item.feed_id}
                                  className="feed-item"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    key={item.feed_id}
                                    className="feed-item"
                                    style={{ display: "flex" }}
                                  >
                                    <div className="like-btn align-items-center">
                                      <i
                                        className={
                                          item.feed_liked
                                            ? "bi bi-hand-thumbs-up-fill"
                                            : "bi bi-hand-thumbs-up"
                                        }
                                        onClick={() =>
                                          addLikeEvent(item.feed_id)
                                        }
                                        style={{
                                          color: "#78266a",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                      <span className="margin-left">
                                        {item.feed_like_count}
                                      </span>
                                    </div>
                                    <div className="dislike-btn align-items-center margin-left">
                                      <i
                                        className={
                                          item.feed_disliked
                                            ? "bi bi-hand-thumbs-down-fill"
                                            : "bi bi-hand-thumbs-down"
                                        }
                                        onClick={() =>
                                          disLikeEvent(item.feed_id)
                                        }
                                        style={{
                                          color: "#78266a",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                      <span className="margin-left">
                                        {item.feed_dislike_count}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="comments-btn  align-items-center margin-left">
                                  <img src={com_icon} alt="" />
                                  <span className="margin-left">
                                    {item.feed_comment_count}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </InfiniteScroll>

                  {/* <TabView>
                      <TabPanel header="All Feeds">
                     
                    </TabPanel>

                    <TabPanel header="My Feeds">
                   
                    </TabPanel>
                  </TabView> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          centered
          className="common-filter-modal"
        >
          <Modal.Header>
            <Modal.Title>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="title-wrapper">
                  <p>{t("Filter By")}</p>
                  <p
                    className="clears"
                    onClick={() => {
                      handleClose();
                      // handleClear();
                    }}
                  >
                    {t("Clear")}
                  </p>
                </div>
              </Modal.Title>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="select-wrapper">
              <ul>
                <li>
                  <input
                    type="radio"
                    id="f-option"
                    name="selector"
                    checked={filterType === "most_recent"}
                    onChange={() => handleFilterChange("most_recent")}
                  />
                  <label for="f-option">{t("Most Recent")}</label>
                  <div class="check">
                    <div class="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="s-option"
                    name="selector"
                    checked={filterType === "most_liked"}
                    onChange={() => handleFilterChange("most_liked")}
                  />
                  <label for="s-option">{t("Most Liked")}</label>
                  <div class="check">
                    <div class="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="t-option"
                    name="selector"
                    checked={filterType === "most_commented"}
                    onChange={() => handleFilterChange("most_commented")}
                  />
                  <label for="t-option">{t("Most Commented")}</label>
                  <div class="check">
                    <div class="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show1} // Bind show1 to the modal's visibility
          onHide={handleClose1} // Ensure handleClose1 sets show1 to false
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="common-filter-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="title-wrapper">
                <p>{t("Delete")}</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("Are you sure you want to delete this item")}?</p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose1} className="cancelFeed">
            {t("Cancel")}
            </button>
            <button
              className="deleteFeed"
              onClick={() => {
                feedDelete();
                handleClose1();
              }}
            >
             {t("Delete")} 
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Feed;
