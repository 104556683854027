import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import Params from "../../../config/Params";
import { simplePostCall } from "../../../api/ApiServices";
import SyllabusSkeleton from "./Skelton";
import { useTranslation } from "react-i18next";
// Import the Skeleton Loader

export const ViewSyllabus = () => {
  const { t } = useTranslation();

  const [syllabusdata, setSyllabusdata] = useState("");
  const [loading, setLoading] = useState(true); // Initialize loading state to true
  const location = useLocation();
  const getData = location.state;
  const navi = useNavigate();

  const syllabusView = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      curriculum_id: getData,
    });

    simplePostCall(ApiConfig.SYLLABUS_VIEW, RequestBody)
      .then((data) => {
        if (data.data) {
          console.log(data.data, "uvjvhvjhv");
          setSyllabusdata(data.data[0]);
          console.log(syllabusdata, "syllabus");
        } else {
          console.log("NO DATA in view");
        }
      })
      .catch((err) => {
        console.log("api res fail", err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data is received or error occurs
      });
  };

  useEffect(() => {
    syllabusView();
  }, []);

  const handleNavigation = (path) => {
    setLoading(true); // Show loading modal when navigating away
    navi(path, { state: syllabusdata.curriculum_id });
  };

  return (
    <div className="SyllabusCreate">
      {loading ? (
        <SyllabusSkeleton /> // Show skeleton loader when loading is true
      ) : (
        <div className="main-sectionn">
          <div className="row">
            <div className="col-md-12">
              <div className="announcementCreatee">
                <label className="labelCreate">{t("Syllabus")}</label>
                <div
                  className="createButt"
                  onClick={() => handleNavigation("/UpdateSyllabus")}
                >
                  <div className="CreateB">{t("Update")}</div>
                </div>
                {/* <div
                  className="createButt"
                  onClick={() => handleNavigation(-1)}
                ></div> */}
              </div>
            </div>
            <div className="tablesec">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">{t("Curriculum Name")}</th>
                    <td scope="col">{syllabusdata.curriculum_name}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="col">{t("Curriculum Language")}</th>
                    <td scope="col">{syllabusdata.curriculum_language}</td>
                  </tr>
                  <tr>
                    <th scope="col">{t("Curriculum Language")}</th>
                    <td scope="col">{syllabusdata.curriculum_status}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
