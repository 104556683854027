import React, { useEffect, useState } from "react";
import downICon from "../../../assets/images/svg/downicon.svg";
import { ChevronDown, CheckSquare, XSquare } from "react-feather";
import PresentTab from "./PresentTab";
import AbsentTab from "./AbsentTab";
import ApiConfig from "../../../api/ApiConfig";
import { simplePostCall } from "../../../api/ApiServices";
import Params from "../../../config/Params";
import SpinnerCmp from "../../../components/SpinnerCmp";
import "../../../assets/scss/slideanimate.scss";
import { useTranslation } from "react-i18next";
import TablesSkeleton from "../../../components/skeletons/TablesSkeleton";
import { useLocation } from "react-router-dom";
// import avatar from "../../assets/images/dashboard/1.png";
import avatar from "../../../assets/images/dashboard/1.png";

export default function NewAttendance(props) {
  const location = useLocation();
  const state = location;
  console.log(state, "state in array", props);
  const { t } = useTranslation();

  const [isAbsentTab, setIsAbsentTab] = useState(false);
  const [isPresentTab, setIsPresentTab] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [secId, setSecId] = useState("");
  const [clId, setClId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [periodList, setPeriodList] = useState([]);
  const [periodListData, setPeriodListData] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [selectedPeriodValue, setSelectedPeriodValue] = useState("");
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSecationId, setSelectedSecationId] = useState("");

  const [selectProidID, setSelectProidID] = useState("");
  const [classId, setClassId] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("");
  const [attendance_date, setAttendance_date] = useState({
    today_data: "",
  });
  const [dateData, setDataData] = useState("");

  useEffect(() => {
    const today = (function () {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      return `${now.getFullYear()}-${month}-${day}`;
    })();
    var result;
    if (attendance_date.today_data === "") {
      result = today;
    } else {
      result = attendance_date.today_data;
    }

    setDataData(result);
  }, [attendance_date]);

  useEffect(() => {
    if (dateData) {
      getTimeTable();
    }
  }, [dateData]);

  useEffect(() => {
    console.log("dtaa in locations");
    if (selectedClassId) {
      getAttendanceList();
    }
  }, [selectedClassId]);

  const openAbsentTab = () => {
    setIsAbsentTab({ ...isAbsentTab, isAbsentTab: true }, () => {});
  };
  const openPresentTab = () => {
    setIsPresentTab({ ...isPresentTab, isPresentTab: true }, () => {});
  };

  const getTimeTable = () => {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      date: dateData,
    };
    simplePostCall(ApiConfig.TIMETABLE, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data.result) {
          setPeriodList(data.timetable_details);
        } else setIsLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    let choiceArry = [];

    let PeriodData = periodList.map((cartData) => {
      return cartData;
    });
    let FilterData = PeriodData.map((value, index) => {
      console.log(value, "filterdata");
      choiceArry.push(value.period_no);
      console.log(choiceArry, "choicearry");
    });

    let subjectData = periodList.map((cartData) => {
      console.log(cartData, "cartdataa");
      return cartData.teacher_subject;
    });
    var CurrentSection = periodList.filter((item) => {
      const match = item.period_no.period_id === secId;
      if (match) {
        setSectionId(item.teacher_subject.section_id);
        setClId(item.teacher_subject.class_id);
        console.log("Additional Data:", item);
      }
      return match;
    });
    console.log(CurrentSection, "CurrentSection");
    //old code for some use
    // var CurrentData = choiceArry.filter(
    //   (item) => item.is_current_period === true,
    // );
    var CurrentData = choiceArry.filter((item) => item);
    setSelectProidID(CurrentData[0] && CurrentData[0].period_id);
    setSelectedSecationId(subjectData[0] && subjectData[0].section_id);
    setSelectedClassId(subjectData[0] && subjectData[0].class_id);
    setPeriodListData(CurrentData);
  }, [periodList, selectedPeriodValue]);

  const onInputChangeHandle = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setClassId(option);
    const { name, value } = e.target;
    setSelectedPeriodValue({
      selectedPeriodValue: e?.target?.value,
    });
    getAttendanceList(value);
    setSecId(JSON.parse(value));
  };

  const getAttendanceList = (value) => {
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: clId ? clId : selectedClassId,
      section_id: sectionId ? sectionId : selectedSecationId,
      period_id: value ? JSON.parse(value) : selectProidID,
      date: dateData,
    };
    console.log(
      "getAttendance List",
      newRequestBody,
      ApiConfig.ATTENDANCE_LIST,
    );
    simplePostCall(ApiConfig.ATTENDANCE_LIST, JSON.stringify(newRequestBody))
      .then((data) => {
        if (data) {
          console.log(data, "data in listing");
          setAttendanceList(data.list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const MarktakeAttendance = (status) => {
    setIsLoading(true);

    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      attendance_id:
        attendanceList &&
        attendanceList.map((value, index) => value.attendance_classroom_id),
      status: attendanceList && attendanceList.map((value, index) => status),
    };
    simplePostCall(ApiConfig.TAKE_ATTENDANCE, JSON.stringify(newRequestBody))
      .then((data) => {
        getAttendanceList();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setAttendanceList([]);
      });
  };

  const takeAttendance = (id, attendance) => {
    // setIsLoading(true);
    let newRequestBody = {
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      attendance_id: id,
      status: attendance,
    };
    simplePostCall(ApiConfig.TAKE_ATTENDANCE, JSON.stringify(newRequestBody))
      .then((data) => {
        getAttendanceList();
        // setIsLoading(false)
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <div class="">
      {/* <!-- Container-fluid starts--> */}
      <div class="row">
        <div class="col-sm-12">
          <div class="card br-15">
            <div class="card-header br-15 pb-0">
              <h5>{t("Attendance")}</h5>
            </div>
            <div class="card-body attend-sec ">
              <ul class="nav nav-tabs search-list" id="top-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link "
                    id="all-link"
                    onClick={openPresentTab}
                    data-bs-toggle="tab"
                    href="#all-links"
                    role="tab"
                    aria-selected="true"
                  >
                    <CheckSquare />
                    {t("Present")}
                  </a>
                  <div class="material-border"></div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link atnd active"
                    id="image-link"
                    data-bs-toggle="tab"
                    href="#image-links"
                    role="tab"
                    aria-selected="false"
                  >
                    <i class="icofont icofont-tasks"></i>
                    {t("Attendance")}
                  </a>
                  <div class="material-border"></div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="video-link"
                    onClick={openAbsentTab}
                    data-bs-toggle="tab"
                    href="#video-links"
                    role="tab"
                    aria-selected="false"
                  >
                    <XSquare />
                    {t("Absent")}
                  </a>
                  <div class="material-border"></div>
                </li>
              </ul>
              <div class="tab-content" id="top-tabContent">
                <div
                  class="search-links tab-pane fade"
                  id="all-links"
                  role="tabpanel"
                  aria-labelledby="all-link"
                >
                  {isPresentTab ? (
                    <PresentTab onClose={openPresentTab} />
                  ) : null}
                </div>
                <div
                  class="tab-pane fade show active"
                  id="image-links"
                  role="tabpanel"
                  aria-labelledby="image-link"
                >
                  {attendanceList && attendanceList.length > 0 && (
                    <div
                      class="mark-sec"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseicon"
                      aria-expanded="true"
                      aria-controls="collapse11"
                    >
                      <img src={downICon} />
                      <div>
                        <ChevronDown />{" "}
                      </div>
                    </div>
                  )}
                  <div
                    class="collapse"
                    id="collapseicon"
                    aria-labelledby="collapseicon"
                    data-bs-parent="#accordionoc"
                  >
                    <div class="mark-all-sec">
                      <div class="option-group">
                        <div class="option-container">
                          <input
                            class="option-input"
                            checked
                            id="option-1"
                            type="radio"
                            name="options"
                          />
                          <input
                            class="option-input"
                            id="option-2"
                            type="radio"
                            name="options"
                          />

                          <label
                            onClick={() => MarktakeAttendance("ABSENT")}
                            class="option"
                            for="option-1"
                          >
                            <span class="option__indicator"></span>
                            <span class="option__label">
                              <sub>{t("mark all")}</sub>
                              {t("Absent")}
                            </span>
                          </label>

                          <label
                            onClick={() => MarktakeAttendance("PRESENT")}
                            class="option"
                            for="option-2"
                          >
                            <span class="option__indicator"></span>
                            <span class="option__label">
                              <sub>{t("mark all")}</sub>
                              {t("Present")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row row">
                    <div class="form-group col-md-6">
                      <label for="task-title">{t("Select Date")}</label>
                      <input
                        class="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        name="submission_date"
                        // min={moment().format("YYYY-MM-DD")}
                        value={dateData}
                        onChange={(e) =>
                          setAttendance_date({
                            ...attendance_date,
                            today_data: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="task-title">{t("Select Period")}</label>
                      <select
                        //   className="js-example-basic-single form-select"
                        className="form-select"
                        onChange={onInputChangeHandle}
                        name="selectClassId"
                        disabled={
                          periodListData && periodListData.length > 0
                            ? false
                            : true
                        }
                      >
                        <option value="">{t("Please Select Period")}</option>
                        {periodListData.length > 0
                          ? periodListData.map((data, index) => {
                              return (
                                <>
                                  <option
                                    selected
                                    key={"data" + index}
                                    value={data.period_id}
                                    // id={selectedClassId}
                                  >
                                    {data.period_name}
                                  </option>
                                </>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                  <>
                    {isLoading ? (
                      <TablesSkeleton />
                    ) : (
                      // <div class="atnd-scroll">
                      <div class="std-container">
                        <ul class="responsive-table">
                          {attendanceList &&
                            attendanceList.map((item, index) => {
                              return (
                                <>
                                  <li class="table-row" key={"item" + index}>
                                    <div
                                      class="col col-1 avatar"
                                      data-label="Student Image"
                                    >
                                      <img
                                        src={
                                          item.student_profilepicture
                                            ? ApiConfig.BASE_URL +
                                              item.student_profilepicture
                                            : avatar
                                        }
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          marginRight: "10px",
                                        }}
                                        onError={(e) => {
                                          e.target.src = avatar;
                                        }}
                                      />
                                    </div>
                                    <div
                                      class="col col-9 std-name"
                                      data-label="Student Name"
                                    >
                                      {item.student_name}
                                    </div>
                                    <div class="switch-toggle switch-3 switch-candy">
                                      <div className="col d-flex justify-content-between">
                                        <button
                                          className={
                                            item.attendance_classroom_status ===
                                            "PRESENT"
                                              ? "present_btn  present_btn1 m-2"
                                              : "present_btn"
                                          }
                                          style={{
                                            color:
                                              item.attendance_classroom_status ===
                                              "PRESENT"
                                                ? "#fff"
                                                : "#747474",
                                          }}
                                          onClick={() =>
                                            setAttendanceStatus(
                                              {
                                                attendanceStatus: "PRESENT",
                                              },
                                              takeAttendance(
                                                item.attendance_classroom_id,
                                                "PRESENT",
                                              ),
                                            )
                                          }
                                        >
                                          {t("Present")}
                                        </button>
                                        <button
                                          className={
                                            item.attendance_classroom_status ===
                                            "ABSENT"
                                              ? "absent_btn  absent_btn1 m-2"
                                              : "absent_btn"
                                          }
                                          style={{
                                            color:
                                              item.attendance_classroom_status ===
                                              "ABSENT"
                                                ? "#fff"
                                                : "#747474",
                                          }}
                                          onClick={() =>
                                            setAttendanceStatus(
                                              {
                                                attendanceStatus: "ABSENT",
                                              },
                                              takeAttendance(
                                                item.attendance_classroom_id,
                                                "ABSENT",
                                              ),
                                            )
                                          }
                                        >
                                          {t("Absent")}
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </div>
                      // </div>
                    )}
                  </>
                </div>
                <div
                  class="tab-pane fade"
                  id="video-links"
                  role="tabpanel"
                  aria-labelledby="video-link"
                >
                  <div class="std-container">
                    {isAbsentTab ? <AbsentTab onClose={openAbsentTab} /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}
