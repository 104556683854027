import React, { Component } from "react";
import user from "../../assets/images/gif/user.gif";
// import ApproveLeaves from './ApproveLeaves'
import StudentLeaveModal from "./StudentLeaveModal";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import Select from "react-select";
import filter from "../../assets/images/filter.svg";
import { Table } from "react-bootstrap";
import TablesSkeleton from "../../components/skeletons/TablesSkeleton";
import { TabPanel, TabView } from "primereact/tabview";

export class StudentLeaves extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      isTodoModelShow: false,
      isTagModelShow: false,
      isloading: false,
      studentLeaves: [],
      studentLeaveData: [],
    };
  }
  //#endregion

  getStudentLeavesData() {
    this.setState({ ...this.state, isloading: true });
    const newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      teacher_id: Params.teacher_id,
      leave_status:
        this.state.status == 0
          ? "pending"
          : this.state.status == 1
          ? "cancel requested"
          : "history",
    });
    simplePostCall(ApiConfig.STUDENT_LEAVES_LIST, newRequestBody)
      .then((data) => {
        if (data.result) {
          console.log(
            data.student_leave_list[0]?.student_name,
            "data in student",
          );
          this.setState({
            ...this.state,
            isloading: false,
            studentLeaves: data.student_leave_list,
          });
        } else {
          this.setState({ ...this.state, isloading: false });
          toast.success(data.message);
        }
      })
      .catch((error) => {
        this.setState({ ...this.state, isloading: false });
        console.log("api response", error);
      });
  }

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "EDSYS | Student Leaves";
    this.getStudentLeavesData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.status !== prevState.status) {
      this.getStudentLeavesData();
      this.setState({ studentLeaves: [] });
    }
  }
  //#endregion

  openCloseModal = (leaveData) => {
    this.setState({
      ...this.state,
      isTodoModelShow: !this.state.isTodoModelShow,
      studentLeaveData: leaveData,
    });
  };
  category = [
    { value: "Planned", label: "Planned" },
    { value: "Unplanned", label: "Unplanned" },
  ];
  status = [
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <div class="Forum library">
          <div className="approve-leave">
            {/* <!-- Container-fluid starts--> */}
            <div className="row">
              <div className="col-12">
                <div className="card br-15">
                  <div className="card-body">
                    <div className=" mb-3 n-attend-total d-flex justify-content-between">
                      <div className=" n-attend-title">
                        <h3>{t("Student Leave Management")}</h3>
                      </div>
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      ></ul>
                      <div className="h-i-m-g">
                        <img src={user} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="books-main-wrapper">
                        <TabView
                          activeIndex={this.state.status}
                          onTabChange={(e) =>
                            this.setState({ status: e.index })
                          }
                        >
                          <TabPanel header={t("Pending Leaves")}>
                            {this.state.isloading ? (
                              <TablesSkeleton />
                            ) : (
                              <div className="my-books p-4">
                                <div className="row">
                                  {this.state.studentLeaves &&
                                  this.state.studentLeaves.length > 0 ? (
                                    this.state.studentLeaves.map(
                                      (leave, index) => (
                                        <div
                                          className="col-md-6 col-lg-4 col-sm-12"
                                          key={index}
                                          onClick={() =>
                                            this.openCloseModal(leave)
                                          }
                                        >
                                          <div
                                            className="book-card"
                                            style={{
                                              display: "flex",
                                              backgroundColor: "#772569",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              padding: "18px", // Added padding
                                              borderRadius: "8px", // Added border radius
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  leave.student_profilepicture
                                                    ? leave.student_profilepicture
                                                    : dashboardAvatar
                                                }
                                                alt="..."
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              {/* Reduced image size and added border radius */}
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  color: "white",
                                                  margin: "0",
                                                }}
                                              >
                                                {leave.student_name}
                                              </p>{" "}
                                              {/* Reduced font size */}
                                            </div>
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                color: "#72A44B",
                                                margin: "0",
                                              }}
                                            >
                                              {leave.leave_no_of_days}{" "}
                                              {leave.leave_no_of_days > 1
                                                ? "Days"
                                                : "Day"}
                                            </p>{" "}
                                            {/* Reduced font size */}
                                          </div>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      className="student-rank-list"
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          justifyContent: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        <p>{t("No records found")}..!</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel header={t("cancellation request")}>
                            {this.state.isloading ? (
                              <TablesSkeleton />
                            ) : (
                              <div className="my-books p-4">
                                <div className="row">
                                  {this.state.studentLeaves &&
                                  this.state.studentLeaves.length > 0 ? (
                                    this.state.studentLeaves.map(
                                      (leave, index) => (
                                        <div
                                          className="col-md-6 col-lg-4 col-sm-12"
                                          key={index}
                                          onClick={() =>
                                            this.openCloseModal(leave)
                                          }
                                        >
                                          <div
                                            className="book-card"
                                            style={{
                                              display: "flex",
                                              backgroundColor: "#772569",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              padding: "18px", // Added padding
                                              borderRadius: "8px", // Added border radius
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  leave.student_profilepicture
                                                    ? leave.student_profilepicture
                                                    : dashboardAvatar
                                                }
                                                alt="..."
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              {/* Reduced image size and added border radius */}
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  color: "white",
                                                  margin: "0",
                                                }}
                                              >
                                                {leave.student_name}
                                              </p>{" "}
                                              {/* Reduced font size */}
                                            </div>
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                color: "#72A44B",
                                                margin: "0",
                                              }}
                                            >
                                              {leave.leave_no_of_days}{" "}
                                              {leave.leave_no_of_days > 1
                                                ? "Days"
                                                : "Day"}
                                            </p>{" "}
                                            {/* Reduced font size */}
                                          </div>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      className="student-rank-list"
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          justifyContent: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        <p>{t("No records found")}..!</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel header={t("Leaves History")}>
                            {this.state.isloading ? (
                              <TablesSkeleton />
                            ) : (
                              <div className="my-books p-4">
                                <div className="row">
                                  {this.state.studentLeaves &&
                                  this.state.studentLeaves.length > 0 ? (
                                    this.state.studentLeaves.map(
                                      (leave, index) => (
                                        <div
                                          className="col-md-6 col-lg-4 col-sm-12"
                                          key={index}
                                          onClick={() =>
                                            this.openCloseModal(leave)
                                          }
                                        >
                                          <div
                                            className="book-card"
                                            style={{
                                              display: "flex",
                                              backgroundColor: "#772569",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              padding: "18px", // Added padding
                                              borderRadius: "8px", // Added border radius
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  leave.student_profilepicture
                                                    ? leave.student_profilepicture
                                                    : dashboardAvatar
                                                }
                                                alt="..."
                                                style={{
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  marginRight: "5px",
                                                }}
                                              />{" "}
                                              {/* Reduced image size and added border radius */}
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  color: "white",
                                                  margin: "0",
                                                }}
                                              >
                                                {leave.student_name}
                                              </p>{" "}
                                              {/* Reduced font size */}
                                            </div>
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                color: "#72A44B",
                                                margin: "0",
                                              }}
                                            >
                                              {leave.leave_no_of_days}{" "}
                                              {leave.leave_no_of_days > 1
                                                ? "Days"
                                                : "Day"}
                                            </p>{" "}
                                            {/* Reduced font size */}
                                          </div>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      className="student-rank-list"
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        style={{
                                          justifyContent: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        <p>{t("No records found")}..!</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </TabPanel>
                        </TabView>
                      </div>
                      {/* <div
                        className="card br-15 mt-4"
                        style={{ maxHeight: "80vh", overflowY: "auto" }}
                      > */}
                      {/* <div className="card-body p-0"> */}
                      {/* <Table responsive="sm">
                          <thead className="position-sticky top-0 start-50 bg-light br-15">
                            <tr>
                              <th>{t("S.No")}</th>
                              <th>{t("Profile Pic")}</th>

                              <th>{t("Student Name")}</th>
                              <th>{t("Leave Status")}</th>
                              <th className="text-center">
                                {t("Number of Day")}
                              </th>
                            </tr>
                          </thead> */}
                      {/* <tbody> */}
                      {/* <tr
                                className="position-sticky bg-light"
                                style={{ top: "35px" }}
                              >
                                <td>
                                  <img src={filter} alt="" />
                                </td>

                                <td></td>
                                <td>
                                  <Select
                                    class="js-example-basic-single form-select"
                                    // value={state.selectedCurriculum}
                                    // onChange={onCurriculumChange}
                                    // options={this.status}
                                  />
                                </td>
                                <td>
                                  <Select
                                    class="js-example-basic-single form-select"
                                    // value={state.selectedCurriculum}
                                    // onChange={onCurriculumChange}
                                    options={this.status}
                                  />
                                </td>
                                <td></td>
                              </tr> */}

                      {/* {this.state.studentLeaves.length > 0 ? (
                            this.state.studentLeaves.map(
                              (studentLeave, index) => (
                                <tr
                                  key={"studentLeave" + index}
                                  className="student-rank-list"
                                  onClick={() =>
                                    this.openCloseModal(studentLeave)
                                  }
                                >
                                  <td>{index + 1}</td>
                                  <td>
                                    <img
                                      src={studentLeave.student_profilepicture}
                                      alt="..."
                                    />
                                  </td>
                                  <td>{studentLeave.student_name}</td>
                                  <td
                                    style={{
                                      color:
                                        studentLeave.leave_status == "pending"
                                          ? "#8da323"
                                          : studentLeave.leave_status ==
                                            "rejected"
                                          ? "#6f2567"
                                          : "",
                                    }}
                                  >
                                    {studentLeave.leave_status
                                      ? studentLeave.leave_status
                                          .charAt(0)
                                          .toUpperCase() +
                                        studentLeave.leave_status.slice(1)
                                      : ""}
                                  </td>
                                  <td className="no-leave ms-auto text-center">
                                    {studentLeave.leave_no_of_days}{" "}
                                    {studentLeave.leave_no_of_days > 1
                                      ? "Days"
                                      : "Day"}
                                  </td>
                                </tr>
                              ),
                            )
                          ) : (
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              className="student-rank-list"
                            >
                              <div className="d-flex align-items-center">
                                <p>{t("No records found")}..!</p>
                              </div>
                            </div>
                          )} */}
                      {/* </tbody> */}
                      {/* </Table> */}
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        {/* {Modal Start} */}
                        {this.state.isTodoModelShow ? (
                          <StudentLeaveModal
                            onClose={this.openCloseModal}
                            studentLeaveData={this.state.studentLeaveData}
                            getStudentLeavesData={this.getStudentLeavesData}
                          />
                        ) : null}

                        {/* modal End */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(StudentLeaves);
