import React, { useContext, useEffect } from "react";
import b1 from "../../../assets/images/user/b1.png";
import b2 from "../../../assets/images/user/b2.png";
import b3 from "../../../assets/images/user/b3.png";
import b4 from "../../../assets/images/user/b4.png";
import b5 from "../../../assets/images/user/b5.png";
import b6 from "../../../assets/images/user/b6.png";
import b7 from "../../../assets/images/user/b7.png";
import b8 from "../../../assets/images/user/b8.png";

import layers from "../../../assets/images/assessments/layers.gif";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../api/ApiConfig";
import { NEW_BASE_URL } from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";

function No_Attends_List() {
  const { pandinge } = useContext(AppContext);
  const { t } = useTranslation();

  // const params = useLocation().state;
  // console.log("params",params)

  useEffect(() => {
    document.title = "Edsys | No Attend List";
  }, []);

  const mydata = [
    {
      id: 1,
      rank: 1,
      name: "Alexander Miller",
      icon_set: "../assets/images/user/b1.png",
    },
    {
      id: 2,
      rank: 2,
      name: "Lucy Tom",
      icon_set: "../assets/images/user/b2.png",
    },
    {
      id: 3,
      rank: 3,
      name: "Thomas Abraham",
      icon_set: "../assets/images/user/b3.png",
    },
    {
      id: 4,
      rank: 4,
      name: "Janet James",
      icon_set: "../assets/images/user/b4.png",
    },
    ,
    {
      id: 5,
      rank: 5,
      name: "Ganesh",
      icon_set: "../assets/images/user/b5.png",
    },
    ,
    {
      id: 6,
      rank: 6,
      name: "Linga Lopez",
      icon_set: "../assets/images/user/b6.png",
    },
    ,
    {
      id: 7,
      rank: 7,
      name: "Sam Joseph",
      icon_set: "../assets/images/user/b7.png",
    },
  ];

  return (
    <div className="row n-attend-list">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="n-attend-total d-flex">
              <div className="flex-grow-1 n-attend-title">
                <span className="total-no">{pandinge.length}</span>
                <span className="msg ">
                  {" "}
                  {t("Students not attended the exam")}
                </span>
              </div>
              <div className="h-i-m-g">
                <img src={layers} />
              </div>
            </div>
            {pandinge && pandinge.length > 0 ? (
              pandinge.map(
                (ele, index) => (
                  console.log(ele.student_profilepicture),
                  (
                    <div
                      onclick="location.href='#';"
                      className="student-rank-list"
                      key={index}
                    >
                      <div className="d-flex align-items-center">
                        <div className="ranks">{index + 1}</div>
                        <div className="p-image">
                          <img
                            src={BASE_URL + ele.student_profilepicture}
                            alt="..."
                          />
                        </div>
                        <div className="student-name">{ele.student_name}</div>
                      </div>
                    </div>
                  )
                )
              )
            ) : (
              <div onclick="location.href='#';" className="student-rank-list">
                <div className="d-flex align-items-center">
                  <span>{t("No records founds")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default No_Attends_List;
