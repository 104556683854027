import React, { useState, useEffect } from "react";
import { Clock, File } from "react-feather";
import b4 from "../../../../assets/images/user/b4.png";
import { Link, useParams } from "react-router-dom";
import Params from "../../../../config/Params";
import ApiConfig from "../../../../api/ApiConfig";
import { useTranslation } from "react-i18next";

const ViewAssignmnet = (props) => {
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [files, setFiles] = useState([]);
  useEffect(() => {
    document.title = "Edsys | Assignments Student Details ";
  }, []);
  const { idy, student_id } = useParams();
  console.log(idy, student_id, "paramssss");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  useEffect(() => {
    fetchAssignmentDetails();
  }, []);
  const fetchAssignmentDetails = async () => {
    try {
      const response = await fetch(ApiConfig.ASSIGNMENT_SUBMITVIEW, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_id: Params.customer_id, // Assuming Params.customer_id is a constant
          api_key: Params.api_key, // Assuming Params.api_key is a constant
          assignment_id: idy,
          student_id: student_id, // You may want to change this if it should be dynamic
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("submitted_files ", data.submitted_details.submitted_files);
        setAssignmentDetails(data.submitted_details);
        setFiles(data.submitted_details.submitted_files);
      } else {
        console.error("Failed to fetch assignment details");
      }
    } catch (error) {
      console.error("Error fetching assignment details:", error);
    }
  };
  const base_url = "https://app.edsys.in:3000/";
  const { t } = useTranslation();

  return (
    <div className="col-12">
      <div className="file-content">
        <div className="card br-15">
          <div className="card-body file-manager">
            <h4 class="mt-1 mb-3">Check Answers</h4>
            <ul className="files">
              {files.map((item) => (
                <li className="file-box" key={item}>
                  <div className="file-top">
                    {item.extension === "text" ? (
                      <div
                        className="fullscreen-iframe"
                        style={{ width: "100%", height: "100%" }}
                      >
                        {item?.submission_files_content}
                      </div>
                    ) : isFullScreen ? (
                      <div
                        className="fullscreen-iframe"
                        onClick={toggleFullScreen}
                      >
                        <iframe
                          src={base_url + item?.submission_files_content}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    ) : (
                      <iframe
                        src={base_url + item?.submission_files_content}
                        style={{ width: "250px", height: "100px" }}
                        onClick={toggleFullScreen}
                      />
                    )}
                  </div>
                  <div className="file-bottom">
                    <h6>{item.file_mode}</h6>
                    {/* <p className="mb-1">{item.size}</p> */}
                    <p>
                      {" "}
                      <b>{t("Extension")}: </b>
                      {item.extension}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="card-footer d-flex justify-content-end br-b-15">
            {assignmentDetails &&
              assignmentDetails.submission_status === "active" && (
                <Link to={`/ReviewAssignment/${idy}/${student_id}`}>
                  <button className="btn btn-primary">
                    {t("REVIEW SECTION")}
                  </button>
                </Link>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAssignmnet;
