import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import SubjectTimeTableGrid from "./subjecttimetable/SubjectTimeTableGrid";
import { toast } from "react-toastify";
import Params from "../../config/Params";
import ApiConfig from "../../api/ApiConfig";
import axios from "axios";
import { simplePostCall } from "../../api/ApiServices";

const startingState = {
  isloading: false,
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  contactNumber: "",
  email: "",
  nationality: "",
  userPassword: "",
  conformPassword: "",
  dateofbirth: "",
  gender: "",
  qualification: "",
  designation: "",
  joiningdate: "",
  staffid: "",
  uploadfile: "",
  reportingperson: "",
  userstaffmap: "",
  teachercirculum: "",
  firstName_error: "",
  contactNumber_error: "",
  email_error: "",
  userPassword_error: "",
  conformPassword_error: "",
  designation_error: "",
};

function CreateTeacher() {
  const [selectGender, setSelectGender] = useState({
    gender: [
      { value: "m", label: "Male" },
      { value: "f", label: "Female" },
      { value: "o", label: "Others" },
    ],
  });
  function onGenderChange(selectedOption) {
    setState({
      ...state,
      gender: selectedOption.value,
      selectGender: selectedOption,
      gender_error: setState(""),
    });
  }
  const [selectReport, setSelectReport] = useState([]);
  const [selectCurriculum, setSelectCurriculum] = useState([]);
  const [selectStaffMap, setSelectStaffMap] = useState([]);
  const [classTeacher, setClassTeacher] = useState([]);
  const [showSubject, setShowSubject] = useState([]);
  const [showDivisionId, setShowDivisionId] = useState();
  const [showIndexValue, setShowIndexValue] = useState();
  const [showAplha1Value, setShowAplha1Value] = useState("");
  const [showAplhaValue, setShowAplhaValue] = useState("");
  const [showReportingPersonValue, setShowReportingPersonValue] = useState("");
  const [showReportingPerson1Value, setShowReportingPerson1Value] =
    useState("");
  const [showIsSupervisor, setShowIsSupervisor] = useState(0);
  const [showSupervisorClasses, setShowSupervisorClasses] = useState([]);
  const [showIsClassTeacher, setShowIsClassTeacher] = useState(0);
  const [showTeacherClasses, setShowTeacherClasses] = useState([]);
  const [showIsParent, setShowIsParent] = useState(0);
  const [showParentId, setShowParentId] = useState();
  const [showSubjectGrade, setShowSubjectGrade] = useState([]);
  const [showSubSubjectGrade, setShowSubSubjectGrade] = useState([]);
  const [showSelected, setShowSelected] = useState({});

  {
    console.log(showSupervisorClasses, "anjuuuuuuuuuuuiii");
  }

  useEffect(() => {
    ReportingPerson();
    CurriculumTeacher();
    StaffMap();
    IsClassTeacherSelect();
  }, []);
  //ReportingPerson dropdown
  const ReportingPerson = () => {
    let Requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_REPORTINGPERSON, Requestbody)
      .then((data) => {
        const reportPerson = data.list.map((person) => ({
          id: person.user_id,
          value: person.user_name, // Using user_name as the value
          label: person.user_name, // Using user_name as the label
        }));
        setSelectReport(reportPerson);
        console.log(reportPerson, "ReportinPerson");
        setShowReportingPersonValue(data.list[5].user_id);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //TeacherCurriculum dropdown
  const CurriculumTeacher = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_CURRICULUM, RequestBody)
      .then((data) => {
        const teachercurriculum = data.list.map((curriculum) => ({
          id: curriculum.curriculum_id,
          value: curriculum.curriculum_name, // Using curriculum_name as the value
          label: curriculum.curriculum_name, // Using curriculum_name as the label
        }));
        setSelectCurriculum(teachercurriculum);
        // console.log(teachercurriculum, "teachercurriculum");
        console.log(data.list[0].curriculum_id, "vannunjnjnjnjnjnn");
        setShowAplhaValue(data.list[0].curriculum_id);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //UserStaffMap dropdown
  const StaffMap = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.TEACHER_STAFF_MAP, requestBody)
      .then((data) => {
        const staffmap = data.list.map((staff) => ({
          id: staff.user_id,
          value: staff.user_name, // Using user_name as the value
          label: staff.user_name, // Using user_name as the label
        }));
        setSelectStaffMap(staffmap);
        console.log(staffmap, "teachercurriculum");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  //IsClassTeacherList dropdown API same as Supervisor list and teaching grade division list
  const IsClassTeacherSelect = () => {
    let classrequestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.ISCLASSTEACHERLIST, classrequestbody)
      .then((data) => {
        setClassTeacher(data?.list);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(classTeacher, "Aanju");

  useEffect(() => {
    IsSubjectList();
  }, [showDivisionId]);

  //subjectlist Api
  const IsSubjectList = () => {
    let subjectrequestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      division_id: showDivisionId,
    });
    simplePostCall(ApiConfig.IsSubjectList, subjectrequestbody)
      .then((data) => {
        setShowSubject(data?.list);
        console.log(data, "response");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(showSubject, "subject");

  const HandleChange = (Sid, index) => {
    setShowDivisionId(Sid);
    setShowIndexValue(index);
  };
  console.log(showDivisionId, showIndexValue, "divisionid");

  const TeacherCreateApi = () => {
    const formData = new FormData();

    // 2. Append your form data (key-value pairs or files)
    // formData.append("api_key", Params.api_key); // key-value pair
    // formData.append("customer_id", Params.customer_id); // key-value pair
    // formData.append("user_id", Params.teacher_id); // file input from a form
    formData.append("user_name", state.firstName); // file input from a form
    formData.append("user_email", state.email); // file input from a form
    formData.append("user_last_name", state.lastName); // file input from a form
    formData.append("user_password", state.userPassword); // file input from a form
    formData.append("confirm_password", state.conformPassword); // file input from a form
    formData.append("user_dob", state.dateofbirth); // file input from a form
    formData.append("user_mobile", state.contactNumber);
    formData.append("is_supervisor", showIsSupervisor);
    formData.append("supervisor_classes", [showSupervisorClasses]);
    formData.append("user_gender", state.gender); // file input from a form
    formData.append("user_nationality", state.nationality);
    formData.append("is_class_teacher", showIsClassTeacher);
    formData.append("class_teacher_classes", [showTeacherClasses]);
    formData.append("is_user_parent", showIsParent);
    formData.append("user_staff_map", showParentId);
    formData.append("user_address", state.address); // file input from a form
    formData.append("user_city", state.city); // file input from a form
    formData.append("user_joining_date", state.joiningdate); // file input from a form
    formData.append(
      "teacher_reporting_person",
      showReportingPerson1Value || showReportingPersonValue
    ); // file input from a form
    formData.append("teacher_curriculum", showAplha1Value || showAplhaValue); // file input from a form
    formData.append("user_staff_map", state.staffid); // file input from a form
    formData.append("teacher_qualification", state.qualification); // file input from a form
    formData.append("teacher_designation", state.designation); // file input from a form
    formData.append("grade_subject", output); // file input from a form

    // console.log("Sending data to API:", data);
    // Log the data before sending it to API
    const api_key = Params.api_key;
    const cust_id = Params.customer_id;
    axios
      .post(
        ApiConfig.TEACHER_CREATE +
          "?api_key=" +
          api_key +
          "&customer_id=" +
          cust_id,

        formData
      )
      .then((response) => {
        console.log("API Response:", response);
      })
      .catch((error) => {
        // Log detailed error information
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("API Error - Response:", error.response.data);
          console.error("API Error - Status:", error.response.status);
          console.error("API Error - Headers:", error.response.headers);
        } else if (error.request) {
          // Request was made but no response was received
          console.error("API Error - No Response:", error.request);
        } else {
          // Something happened in setting up the request
          console.error("API Error - Setup:", error.message);
        }
      });
  };
  const [selected, setSelected] = useState("");
  const [isclassteacher, setIsClassTeacher] = useState(false);
  const [Issupervisor, setIsSupervisor] = useState(false);
  const Periods = [
    {
      id: 1,
      PeriodId: 1,
      startTime: "09:30 AM",
      endTime: "10:15 AM",
      isInterval: false,
    },
    {
      id: 2,
      PeriodId: 2,
      startTime: "10:15 AM",
      endTime: "11:00 AM",
      isInterval: false,
    },
    {
      id: 3,
      PeriodId: 0,
      startTime: "11:00 AM",
      endTime: "11:15 AM",
      isInterval: true,
    },
    {
      id: 4,
      PeriodId: 3,
      startTime: "11:15 AM",
      endTime: "12:00 PM",
      isInterval: false,
    },
    {
      id: 5,
      PeriodId: 4,
      startTime: "12:00 PM",
      endTime: "12:45 PM",
      isInterval: false,
    },
    {
      id: 6,
      PeriodId: 0,
      startTime: "12:45 PM",
      endTime: "01:45 PM",
      isInterval: true,
    },
    {
      id: 7,
      PeriodId: 5,
      startTime: "01:45 PM",
      endTime: "02:30 PM",
      isInterval: false,
    },
    {
      id: 8,
      PeriodId: 6,
      startTime: "02:30 PM",
      endTime: "03:15 PM",
      isInterval: false,
    },
    {
      id: 9,
      PeriodId: 0,
      startTime: "03:15 PM",
      endTime: "03:30 PM",
      isInterval: true,
    },
    {
      id: 10,
      PeriodId: 7,
      startTime: "03:30 PM",
      endTime: "04:15 PM",
      isInterval: false,
    },
  ];

  const lectures = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Ann",
      imgPath: "assets/images/masterdatapic/question.png",
    },
    {
      id: 2,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Ann",
    },
    {
      id: 3,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Ann",
    },
    {
      id: 4,
      Period: 4,
      subject: "Biology",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 5,
      Period: 5,
      subject: "History",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 6,
      Period: 6,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 7,
      Period: 7,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
  ];
  const WeeklyPeriods = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 2,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 3,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 4,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 5,
      Period: 1,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 6,
      Period: 1,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //2nd Period
    {
      id: 7,
      Period: 2,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 8,
      Period: 2,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 9,
      Period: 2,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 10,
      Period: 2,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 11,
      Period: 2,
      subject: "Physics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 12,
      Period: 2,
      subject: "Mathematics",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //3rd Period
    {
      id: 13,
      Period: 3,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 14,
      Period: 3,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 15,
      Period: 3,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 16,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 17,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 18,
      Period: 3,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //4th Period
    {
      id: 19,
      Period: 4,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 20,
      Period: 4,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 21,
      Period: 4,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 22,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 23,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 24,
      Period: 4,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //5th Period
    {
      id: 25,
      Period: 5,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 26,
      Period: 5,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 27,
      Period: 5,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 28,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //6th Period
    {
      id: 30,
      Period: 6,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 31,
      Period: 6,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 32,
      Period: 6,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 33,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 34,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 35,
      Period: 6,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //7th Period
    {
      id: 36,
      Period: 7,
      subject: "Chemistry",

      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 37,
      Period: 7,
      subject: "Biology",

      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 38,
      Period: 7,
      subject: "History",

      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 39,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 40,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 41,
      Period: 7,
      subject: "English",

      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
  ];

  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };

  const [state, setState] = useState(startingState || "");
  // console.log(state, "first");
  // console.log(showTeacherClasses, showSupervisorClasses, "Clickingg");

  function SaveandNext() {
    try {
      if (
        state.firstName === "" ||
        state.contactNumber === "" ||
        state.email === "" ||
        state.userPassword === "" ||
        state.conformPassword === "" ||
        state.designation === ""
      ) {
        toast.error("Please fill all mandatory fields");

        setState(() => ({
          firstName_error: state.firstName === "" ? "Enter First Name" : null,
          contactNumber_error:
            state.contactNumber === "" ? "Enter Contact Number" : null,
          userPassword_error:
            state.userPassword === "" ? "Enter Password" : null,
          conformPassword_error:
            state.conformPassword === "" ? "Re_enter Password" : null,
          designation_error:
            state.designation === "" ? "Enter Designation" : null,
          email_error: state.email === "" ? "Enter Email" : null,
        }));
      } else {
        // setState("");
        setState(state.contactNumber == "");
        TeacherCreateApi();
      }
    } catch (error) {
      console.error("Error in handleSaveAndNext:", error);
    }
  }

  const toggleMainCheckbox = (mainId) => {
    setShowSelected((prev) => {
      const updated = { ...prev };
      if (updated[mainId]) {
        delete updated[mainId]; // Uncheck main removes all sub-checkboxes
      } else {
        updated[mainId] = []; // Add main with empty sub-checkbox array
      }
      return updated;
    });
  };

  const toggleSubCheckbox = (mainId, subId) => {
    setShowSelected((prev) => {
      const updated = { ...prev };

      if (!updated[mainId]) {
        updated[mainId] = []; // Ensure main exists before adding sub
      }

      if (updated[mainId].includes(subId)) {
        updated[mainId] = updated[mainId].filter((id) => id !== subId); // Remove sub
        if (updated[mainId].length === 0) delete updated[mainId]; // Remove main if empty
      } else {
        updated[mainId] = [...updated[mainId], subId]; // Add sub
      }

      return updated;
    });
  };
  function transformData(input) {
    let result = [];

    Object.keys(input).forEach((key, index) => {
      let newKey = parseInt(key) + 6; // Convert to number and add 6
      let newValues = input[key].map((val) => Math.abs(val - 34)); // Transform values

      let newObj = {};
      newObj[newKey] = newValues;
      result.push(newObj);
    });

    return result;
  }
  let output = transformData(showSelected);
  console.log(output, "output");
  console.log(showSelected, "neww");

  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Create Teacher</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">First Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        value={state.firstName}
                        onChange={(e) =>
                          setState({
                            ...state,
                            firstName: e.target.value,
                            firstName_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.firstName_error ? "" : "none",
                        }}
                      >
                        {state.firstName_error}
                      </small>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Last Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Address</label>
                      <textarea
                        className="form-control"
                        style={{ height: "125px" }}
                        required=""
                        autocomplete="off"
                        onChange={(e) =>
                          setState({
                            ...state,
                            address: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-group col-md-12">
                        <label for="task-title">City</label>
                        <input
                          className="datepicker-here form-control digits"
                          type="text"
                          onChange={(e) =>
                            setState({
                              ...state,
                              city: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>Nationality </label>
                        <ReactFlagsSelect
                          className="flag"
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          onChange={(e) =>
                            setState({
                              ...state,
                              nationality: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Contact Number</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            contactNumber: e.target.value,
                            contactNumber_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.contactNumber_error ? "" : "none",
                        }}
                      >
                        {state.contactNumber_error}
                      </small>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Email</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            email: e.target.value,
                            email_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.email_error ? "" : "none",
                        }}
                      >
                        {state.email_error}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">User Password</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="password"
                        onChange={(e) =>
                          setState({
                            ...state,
                            userPassword: e.target.value,
                            userPassword_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.userPassword_error ? "" : "none",
                        }}
                      >
                        {state.userPassword_error}
                      </small>
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Confirm Password</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="password"
                        onChange={(e) =>
                          setState({
                            ...state,
                            conformPassword: e.target.value,
                            conformPassword_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.conformPassword_error ? "" : "none",
                        }}
                      >
                        {state.conformPassword_error}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Date of Birth</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        onChange={(e) =>
                          setState({
                            ...state,
                            dateofbirth: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Gender</label>
                      <Select
                        onChange={onGenderChange}
                        options={selectGender.gender}
                        placeholder={state.gender}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Qualification</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            qualification: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Designation</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            designation: e.target.value,
                            designation_error: setState(""),
                          })
                        }
                      />
                      <small
                        style={{
                          color: "red",
                          display: state.designation_error ? "" : "none",
                        }}
                      >
                        {state.designation_error}
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Joining Date</label>

                      <input
                        className="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        onChange={(e) =>
                          setState({
                            ...state,
                            joiningdate: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Staff ID</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        onChange={(e) =>
                          setState({
                            ...state,
                            staffid: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">Upload File</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="file"
                        onChange={(e) =>
                          setState({
                            ...state,
                            uploadfile: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label for="task-title">Reporting Person</label>
                      <Select
                        placeholder="Select Reporting Person"
                        options={selectReport}
                        defaultValue={selectReport[5]}
                        onChange={
                          (selectedOption) =>
                            setShowReportingPerson1Value(selectedOption.id)
                          //console.log(selectedOption.id, "curr")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="form-check"
                    onClick={() => setShowIsParent(1)}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                    />
                    <label htmlFor="IsClassTeacher">Is Parent</label>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label for="task-title">User Staff Map</label>
                      <Select
                        placeholder="Select ParentStaff"
                        options={selectStaffMap}
                        onChange={
                          (selectedOption) => setShowParentId(selectedOption.id)
                          // console.log(selectedOption.id, "curr")
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label for="task-title">Teacher Curriculum</label>
                      <Select
                        placeholder="Select Teacher Curriculum"
                        options={selectCurriculum}
                        defaultValue={selectCurriculum[0]}
                        onChange={
                          (selectedOption) =>
                            setShowAplha1Value(selectedOption.id)
                          // console.log(selectedOption.id, "curr")
                        }
                      />
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                      onClick={() => {
                        setIsClassTeacher(!isclassteacher);
                        setShowIsClassTeacher(1);
                      }}
                    />
                    <label htmlFor="IsClassTeacher">Is Class Teacher</label>
                  </div>
                  {isclassteacher ? (
                    <div className="row">
                      {classTeacher?.map((item, index) => (
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.class_division_id}
                              onChange={(e) => {
                                setShowTeacherClasses(
                                  (prev = []) =>
                                    prev.includes(e.target.id)
                                      ? prev.filter(
                                          (val) => val !== e.target.id
                                        ) // Remove if exists
                                      : [...prev, e.target.id] // Add if not exists
                                );
                              }}
                            />
                            <label htmlFor="GRADE9A">
                              {item.class_name}
                              {item.class_division_name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="IsClassTeacher"
                      onClick={() => {
                        setIsSupervisor(!Issupervisor);
                        setShowIsSupervisor(1);
                      }}
                    />
                    <label htmlFor="IsClassTeacher">
                      Is Supervisor/Head Of The Department
                    </label>
                  </div>
                  {Issupervisor ? (
                    <div className="row">
                      {classTeacher?.map((item, index) => (
                        <div className="col-md-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.class_division_id}
                              onChange={(e) => {
                                setShowSupervisorClasses(
                                  (prev = []) =>
                                    prev.includes(e.target.id)
                                      ? prev.filter(
                                          (val) => val !== e.target.id
                                        ) // Remove if exists
                                      : [...prev, e.target.id] // Add if not exists
                                );
                              }}
                            />
                            <label htmlFor="GRADE9A">
                              {item.class_name}
                              {item.class_division_name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="form-group col-md-6">
                    <label for="task-title">Teaching Grade - Divisions</label>
                    {classTeacher.map((item, index) => (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={item.class_division_id}
                          onClick={() =>
                            HandleChange(item.class_division_id, index)
                          }
                          onChange={(e) => {
                            console.log(e.target.id, "vava");
                            toggleMainCheckbox(item.class_division_id);

                            setShowSubjectGrade(
                              (prev = []) =>
                                prev.includes(e.target.id)
                                  ? prev.filter((val) => val !== e.target.id) // Remove if exists
                                  : [...prev, e.target.id] // Add if not exists
                            );
                          }}
                        />

                        <label htmlFor="IsClassTeacher">
                          {item.class_name}
                          {item.class_division_name}
                          <div>
                            <label htmlFor="IsClassTeacher">Subjects</label>

                            {showIndexValue == index
                              ? showSubject?.map((subitem) => (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={subitem.subject_id}
                                      onChange={(e) => {
                                        toggleSubCheckbox(
                                          showDivisionId,
                                          subitem.subject_id
                                        );
                                        setShowSubSubjectGrade(
                                          (prev = []) =>
                                            prev.includes(e.target.id)
                                              ? prev.filter(
                                                  (val) => val !== e.target.id
                                                ) // Remove if exists
                                              : [...prev, e.target.id] // Add if not exists
                                        );
                                      }}
                                    />
                                    <label htmlFor="IsClassTeacher">
                                      {subitem.subject_name}
                                    </label>
                                  </div>
                                ))
                              : null}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>

                  <div style={{ marginTop: "50px" }}>
                    <SubjectTimeTableGrid data={dataGrid} />
                  </div>
                  <div className="schedulebutton">
                    <div></div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          SaveandNext();
                        }}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTeacher;
