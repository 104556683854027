import React, { Component, useEffect, useState } from "react";
import ApiConfig from "../../../api/ApiConfig";
import Select from "react-select";
import { simplePostCall } from "../../../api/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Params from "../../../config/Params";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditSyllabusSkelton from "./EditSyllabusSkelton";
import CreateSyllabusmodelskelton from "./CreateSyllabusmodelskelton";

export default function CreateSyllabusModals() {
  const navic = useNavigate();
  const { t } = useTranslation();
  const [year, setYear] = useState("");
  const [selectedclass, setSelectedclass] = useState("");
  const [stSubject, setstSubject] = useState("");
  const [CurriculamsListss, setCurriculamsListss] = useState("");
  const [syllabusName, setSyllabusName] = useState("");
  const [classname, setClassname] = useState([]);
  const [AccdamicYear, setAccdamicYear] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [curriculumState, setCurriculumState] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const displayStyle = {
    display: "block",
  };
  const notify = () => toast.success("Succesfully Created!");

  useEffect(() => {
    classList();
    yearList();
    getCurriculumList();
  }, []);
  // ---------classList----------
  function onGradeChange(selected) {
    console.log(selected, "selected");
    let temp = selected.value;
    setSelectedclass(selected.class_id);
    subjectList(temp);
  }
  // ------------------------------
  // ---------AcadamicYear----------
  const onYearChange = (selecte) => {
    console.log(selecte, "yearss");
    setYear(selecte.value);
  };
  // ------------------------------
  // ---------Subject----------
  const onSubjectChange = (subjectList) => {
    setstSubject(subjectList.value);
    console.log(subjectList);
  };
  // ------------------------------
  // ---------Curriculam ----------
  const onCurriculamChange = (curriculums) => {
    setCurriculamsListss(curriculums.value);
  };
  // ------------------------------

  // <----------------Validation Functions------------------->
  const sumbitForm = () => {
    if (
      !syllabusName ||
      !selectedclass ||
      !stSubject ||
      !year ||
      !CurriculamsListss
    ) {
      setError(true);
      return; // Stop execution if any field is empty
    } else {
      createLessonPlan();
      //   props.onClose();
      // notify();
    }
  };

  // <--------------------------------------------------------------->

  // ---------classListApi----------
  function classList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });
    simplePostCall(ApiConfig.CLASSES_LIST_API, newRequestBody)
      .then((data) => {
        // setClassess(data.list);
        console.log(data, "data Lists in class");
        if (data.result) {
          var classlists = [];
          if (data.list?.length > 0) {
            setIsLoading(false);
            data.list.map((clases, index) => {
              classlists.push({
                label: clases.class_name,
                value: clases.class_division_id,
                class_id: clases.class_id,
              });
            });
          }
          setClassname({ ...classname, classlists: classlists });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("api response", error);
      });
  }
  // -----------------------------------
  // ---------AcadamicYear Api----------
  function yearList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.YEAR_LIST, newRequestBody)
      .then((data) => {
        console.log(data, "YEAR data Lists");
        if (data.result) {
          var yearlist = [];
          if (data.data?.length > 0) {
            data.data.map((clases, index) => {
              yearlist.push({
                label: clases.academic_year_name,
                value: clases.academic_year_id,
              });
            });
          }
          setAccdamicYear({ ...AccdamicYear, yearlist: yearlist });
          console.log(AccdamicYear, "AccYear");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ---------Subject Api----------
  function subjectList(class_id) {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
      class_id: class_id,
    });

    simplePostCall(ApiConfig.SUBJECT_LIST_API, newRequestBody)
      .then((data) => {
        console.log(data, "subjects");
        if (data.result) {
          var subjectlist = [];
          if (data.list?.length > 0) {
            data.list.map((clases, index) => {
              subjectlist.push({
                label: clases.subject_name,
                value: clases.subject_id,
              });
            });
          }
          setSubjects({ ...subjects, subjectlist: subjectlist });
          console.log(subjects, "subjects");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  // <-------------Syllabus/curriculum Api---------->

  function getCurriculumList() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      user_id: Params.teacher_id,
    });

    simplePostCall(ApiConfig.CURRICULUM_LIST_API, JSON.stringify(Params))
      .then((data) => {
        console.log(data, "YEAR data Lists");
        if (data.result) {
          var curriculums = [];
          if (data?.curriculum_list.length > 0) {
            data.curriculum_list.map((clases, index) => {
              console.log(clases, "curicullam");
              curriculums.push({
                label: clases.curriculum_name,
                value: clases.curriculum_id,
              });
            });
          }
          console.log(curriculums, "curriculums");
          setCurriculumState({ ...curriculumState, curriculums: curriculums });
          console.log(curriculumState, "AccYear");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // ----------------------------------------------

  function createLessonPlan() {
    let newRequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      // user_id: Params.teacher_id,
      syllabus_name: syllabusName,
      class_id: selectedclass,
      subject_id: stSubject,
      academic_year_id: year,
      curriculum_id: CurriculamsListss,
    });
    simplePostCall(ApiConfig.CREATE_SYLLABUS_DATA, newRequestBody)
      .then((data) => {
        // setClassess(data.list);
        console.log(data, "data Lists");
        if (data.result) {
          toast.success(data.message);
          navic("/NewSyllabus");
          // var classlists = [];
          // if (data.list?.length > 0) {
          //   data.list.map((clases, index) => {
          //     classlists.push({
          //       label: clases.class_namea,
          //       value: clases.class_division_id,
          //     });
          //   });
          // }
          // setClassname({ ...classname, classlists: classlists });
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      {isLoading ? (
        <CreateSyllabusmodelskelton />
      ) : (
        <div className="main-sectionn ">
          <div className="col-md-12">
            <div className="announcementCreatee">
              <label className="edit-head">{t("Create Syllabus")}</label>
            </div>
          </div>
          <form
            className="form-bookmark needs-validation"
            id="bookmark-form"
            novalidate=""
          >
            <div className="form-row">
              <div className="col-md-12 row">
                {/* syllabus Name */}
                <div className="form-group col-md-6">
                  <label for="task-title">{t("Syllabus Name")}</label>
                  <input
                    className="form-control "
                    id="task-title"
                    type="text"
                    required=""
                    autocomplete="off"
                    onChange={(e) => {
                      setSyllabusName(e.target.value);
                    }}
                  />
                  {error && !syllabusName ? (
                    <p className="val-form">
                      {t("Syllabus Name is required...")}
                    </p>
                  ) : null}
                </div>
                {/* Accadamic year */}
                <div className="form-group col-md-6">
                  <label for="task-title">{t("Academic Year")}</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={t("Select Year")}
                    value={AccdamicYear.label}
                    onChange={onYearChange}
                    options={AccdamicYear.yearlist}
                  />
                  {error && !year ? (
                    <p className="val-form">{t("Year is required...")}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 row">
                {/* Syllabus */}
                <div className="form-group col-md-6">
                  <label for="task-title">{t("Syllabus ")}</label>
                  {console.log(
                    curriculumState?.curriculums,
                    "curriculumState?.curriculums}"
                  )}
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={t("Syllabus")}
                    value={curriculumState.label}
                    onChange={onCurriculamChange}
                    options={curriculumState?.curriculums}
                  />
                  {error && !CurriculamsListss ? (
                    <p className="val-form">{t("Syllabus is required...")}</p>
                  ) : null}
                </div>
                {/* class */}
                <div className="form-group col-md-6">
                  <label for="task-title">{t("Select Class")}</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={t("select")}
                    value={classname.label}
                    onChange={onGradeChange}
                    options={classname.classlists}
                  />
                  {error && !selectedclass ? (
                    <p className="val-form">{t("Class is required...")}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 row">
                {/* Subject */}
                <div className="form-group col-md-6">
                  <label for="task-title">{t("Select Subject")}</label>
                  <Select
                    class="js-example-basic-single form-select"
                    placeholder={t("Select Year")}
                    value={subjects.label}
                    onChange={onSubjectChange}
                    options={subjects.subjectlist}
                  />
                  {error && !stSubject ? (
                    <p className="val-form">{t("Subject is required...")}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <div className="create-but-1">
            <button
              className="btn btn-primary"
              //   onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              onClick={() => navic("/NewSyllabus")}
            >
              {t("Cancel")}
            </button>
            <div
              className="btn btn-secondary"
              id="Bookmark"
              onClick={() => sumbitForm()} // createLessonPlan()
              type="submit"
            >
              {t("Create")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
