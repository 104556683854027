import React, { useEffect } from "react";
import { useState } from "react";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

function CreateGradeDivision() {
  const [checkboxes, setCheckboxes] = useState({
    selectAll: false,
    class_id: false,
  });
  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxes = Object.keys(checkboxes).reduce((result, key) => {
      result[key] = isChecked;
      return result;
    }, {});
    setCheckboxes(updatedCheckboxes);
  };

  const [showData, setShowData] = useState([]);
  const [showClassDivision, setShowClassDivision] = useState();
  const [showDivisonName, setShowDivisionName] = useState([]);

  useEffect(() => {
    gradelist();
  }, []);
  const gradelist = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade: "",
      limit: "",
      page: "",
    });
    simplePostCall(ApiConfig.CLASSLISTCREATE, requestBody)
      .then((data) => {
        setShowData(data?.data);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const CreateMode = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade_id: showClassDivision,
      division_name: showDivisonName,
    });
    simplePostCall(ApiConfig.CLASS_DIVISION_CREATE, RequestBody)
      .then((data) => {
        console.log(data, "created");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(showClassDivision, "divi");
  return (
    <>
      <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Create Grade Division</h5>
          </div>
          <div className="card">
            <div className="card-header">
              <form
                className="form-bookmark needs-validation"
                id="bookmark-form"
                noValidate
              >
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="division-name">Division Name</label>
                      <input
                        className="datepicker-here form-control digits"
                        type="text"
                        id="division-name"
                        value={showDivisonName}
                        onChange={(e) => setShowDivisionName(e.target.value)}
                      />
                    </div>
                    <label htmlFor="grades">Grades</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="selectAll"
                        checked={checkboxes.selectAll}
                        onChange={handleSelectAllChange}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>
                    {showData.map((item, index) => (
                      <div className="col-md-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={item.class_id}
                            value={item.class_id} // Ensure value is set
                            onChange={(e) => {
                              setShowClassDivision(
                                (prev = []) =>
                                  prev.includes(e.target.id)
                                    ? prev.filter((val) => val !== e.target.id) // Remove if exists
                                    : [...prev, e.target.id] // Add if not exists
                              );
                            }}
                          />

                          <label htmlFor="class12">{item.class_name}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </form>
              <div className="schedulebutton">
                <div></div>
                <div className="">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      CreateMode();
                    }}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateGradeDivision;
