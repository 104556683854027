import React, { useEffect, useState } from "react";
import Select from "react-select";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UpdateGrade() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const idedit = params.get("message");
  console.log(idedit, "id vannu");

  const [classListapi, setClassListapi] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    ClassesList();
  }, []);
  const ClassesList = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
    });
    simplePostCall(ApiConfig.CLASS_SHIFT_LIST, requestbody)
      .then((data) => {
        const classeslist = data.list.map((classdata) => ({
          id: classdata.shift_id,
          value: classdata.shift_name, // Using shift_name as the value
          label: classdata.shift_name, // Using shift_name as the label
        }));
        setClassListapi(classeslist);
        console.log(classeslist, "classslist");
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const [viewData, setViewData] = useState();
  const [showGradeCode, setShowGradeCode] = useState("");
  const [showGradeName, setShowGradeName] = useState("");
  const [showShiftType, setShowShiftType] = useState("");
  const [showShiftId, setShowShiftId] = useState("");

  useEffect(() => {
    classViewMode();
  }, []);
  const classViewMode = () => {
    let requestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      class_id: idedit,
    });
    simplePostCall(ApiConfig.CLASS_VIEW, requestBody)
      .then((data) => {
        setViewData(data?.data[0]);
        setShowGradeCode(data?.data[0]?.class_id);
        setShowGradeName(data?.data[0]?.class_name);
        setShowShiftType(data?.data[0]?.shift_name);
        setShowShiftId(data?.data[0]?.class_shift_id);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const classUpdateMode = () => {
    let RequestBody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      class_id: idedit,
      school_hour: showShiftId,
      class_name: showGradeName,
      class_code: showGradeCode,
    });
    simplePostCall(ApiConfig.CLASS_UPDATE, RequestBody)
      .then((data) => {})
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  console.log(viewData, "viewdataclass");
  return (
    <div className="row edsys-table">
      <div className="col-sm-12">
        <div className="d-flex">
          <h5 className="mb-2 flex-grow-1">{t("Update Grade")}</h5>
        </div>
        <div className="card">
          <div className="card-header">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Grade Code")}</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="text"
                      value={showGradeCode}
                      onChange={(e) => {
                        setShowGradeCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("Grade Name")}</label>
                    <input
                      className="datepicker-here form-control digits"
                      type="text"
                      value={showGradeName}
                      onChange={(e) => {
                        setShowGradeName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="task-title">{t("School Hour")}</label>

                    <Select
                      placeholder={showShiftType}
                      options={classListapi}
                      onChange={(e) => setShowShiftId(e.id)}
                    />
                  </div>
                </div>

                <div className="schedulebutton">
                  <div></div>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => {
                        classUpdateMode();
                      }}
                    >
                     {t("Update")} 
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateGrade;
