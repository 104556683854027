import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import { HiMagnifyingGlassPlus } from "react-icons/hi2";
import { CiImport } from "react-icons/ci";
import { MdArrowDropDown } from "react-icons/md";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useTranslation } from "react-i18next";

function Classes() {
  const [expandedGrades, setExpandedGrades] = useState({}); // To track which grade is expanded
  const [expandedDivisions, setExpandedDivisions] = useState({}); // To track which division is expanded
  const [classListApi, setClassListApi] = useState([]);
  const [divisionListApi, setDivisionListApi] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    gradelist();
  }, []);
  const gradelist = () => {
    let requestbody = JSON.stringify({
      api_key: Params.api_key,
      customer_id: Params.customer_id,
      grade: "",
    });
    simplePostCall(ApiConfig.CLASSLIST, requestbody)
      .then((data) => {
        setClassListApi(data?.classobj);
        setDivisionListApi(data?.divobj);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  // Toggle for grade
  const toggleGrade = (index) => {
    setExpandedGrades((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific grade's visibility
    }));
  };

  // Toggle for divisions
  const toggleDivision = (index) => {
    setExpandedDivisions((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific division's visibility
    }));
  };

  return (
    <>
      <div className="row edsys-table">
        <div className="col-lg-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">{t("Grade - Division")}</h5>
          </div>
          <div className="row card-div">
            <div className="card col-md-5">
              <div className="card-header"> </div>
              <div className="">
                <div className="padding">
                  <div className="tablebuttonview">
                    <a
                      href="/CreateGrade"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <PlusCircle className="me-2" width={15} height={15} />
                      <span>{t("Add")}</span>
                    </a>
                    <a
                      href="/ViewGrades"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <HiMagnifyingGlassPlus className="image" />
                      <span>{t("View")}</span>
                    </a>
                    <a
                      href="/ImportSubjects"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CiImport className="image" />
                      <span>{t("Import")}</span>
                    </a>
                  </div>
                  <div className="details">
                    <label className="classes_text">{t("Grades")}</label>
                    <h6 className="classes_subtext">{t("Grades are shown below")}</h6>
                  </div>
                  <table>
                    <tbody>
                      {classListApi.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="p-2"
                            onClick={() => toggleGrade(index)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <MdArrowDropDown />
                                <label>{item.class_name}</label>
                              </div>
                              <div className="count_div">{item.div.length}</div>
                            </div>
                            {expandedGrades[index] && item.div.length > 0 && (
                              <div>
                                <table>
                                  <thead>
                                    <tr style={{ marginLeft: "20px" }}>
                                      <th className="Slno"></th>
                                      <th className="Division"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.div.map((items, index1) => (
                                      <tr>
                                        <td>{index1 + 1}</td>
                                        <td>{items.class_division_name}</td>
                                      </tr>
                                    ))}

                                    {/* {item.slno1 && (
                                      <tr>
                                        <td>{item.slno1}</td>
                                        <td>{item.division1}</td>
                                      </tr>
                                    )} */}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card col-md-5">
              <div className="card-header"></div>
              <div className="">
                <div className="padding">
                  <div className="tablebuttonview">
                    <a
                      href="/CreateGradeDivision"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <PlusCircle className="me-2" width={15} height={15} />
                      <span>{t("Add")}</span>
                    </a>
                    <a
                      href="/ViewGradeDivision"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <HiMagnifyingGlassPlus className="image" />
                      <span>{t("View")}</span>
                    </a>
                    <a
                      href="/ImportSubjects"
                      type="button"
                      className="btn timetablebutton"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CiImport className="image" /> <span>{t("Import")}</span>
                    </a>
                  </div>
                  <div className="details">
                    <label className="classes_text">{t("Grade Divisions")}</label>
                    <h6 className="classes_subtext">
                    {t("Grade and Division are shown below")}
                    </h6>
                  </div>
                  <table>
                    <tbody>
                      {divisionListApi.map((data, index) => (
                        <tr key={index}>
                          <td
                            className="p-2"
                            onClick={() => toggleDivision(index)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <MdArrowDropDown />
                                <label>
                                  {data.class_name}
                                  {data.class_division_name}
                                </label>
                              </div>
                              <div className="count_div">
                                {data.student.length}
                              </div>
                            </div>
                            {expandedDivisions[index] &&
                              data.student.length > 0 && (
                                <div>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>{t("Serial No")}.</th>
                                        <th>{t("Student Name")}</th>
                                        <th>{t("Admission No")}.</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.student.map((datas, index11) => (
                                        <tr>
                                          <td>{index11 + 1}</td>
                                          <td>{datas.student_name}</td>
                                          <td>{datas.student_admission_no}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Classes;
